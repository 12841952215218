import React from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const EprescribeConfirmationModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  staffName, 
  primaryRole,
  secondaryRole,
  isLoading 
}) => {
  
  const getPriceByRole = (roleId) => {
    if (roleId === 1) {
      return 75;
    } 
    
    else if (roleId  === 5) { 
      return 30 ;
    } 

    return 0;
  };
  const price = getPriceByRole(primaryRole?.id);

  return (
    <>
      {isOpen && (
        <Dialog
          title="Confirm eRx Access"
          onClose={onClose}
          className="dialog-modal blue_theme"
          width={700}
        >
          <div className="confirmation-content p-3">
            <p className="confirmation-title mb-3 text-center fw-bold">
              Confirm eRx Access for <span className="staff-name">{staffName}</span>
            </p>
            
            <div className="confirmation-details mb-4">
              <p className="mb-3">
                You are about to add <strong>{staffName}</strong> as a <strong>{primaryRole?.name}</strong> to your clinic's eRx subscription.
              </p>
              
              <div className="pricing-details p-3 my-3 border rounded">
                <div className="d-flex justify-content-between fw-bold mb-2">
                  <span>Monthly Cost:</span>
                  <span className={price > 0 ? "text-danger" : "text-success"}>
                    {price > 0 ? `$${price}/month` : "Free"}
                  </span>
                </div>
                <hr className="my-2" />
                <ul className="pricing-info list-unstyled mb-0">
                  <li className="mb-1">• This staff member will have access to eRx features based on their assigned role.</li>
                  {price > 0 && (
                    <li className="mb-1">• By confirming, the monthly charge will be reflected in your next billing cycle.</li>
                  )}
                  {/* {selectedRoles.some(role => role.id === 1) && (
                    <li className="mb-1">• EPCS (Electronic Prescribing of Controlled Substances) is INCLUDED.</li>
                  )} */}
                </ul>
              </div>
              
              {/* <div className="pricing-reference small mt-3">
                <div className="fw-bold mb-1">Reference Pricing:</div>
                <ul className="list-unstyled ms-2 mb-0">
                  <li>• Prescribing Clinician: $75/month</li>
                  <li>• Prescribing Agent: $30/month</li>
                  <li>• Proxy User: Free</li>
                  <li>• Reporting Clinician: Free</li>
                  <li>• EPCS: INCLUDED</li>
                </ul>
              </div> */}
            </div>
            
            <p className="confirmation-question fw-bold text-center mb-3">
              Would you like to proceed?
            </p>
          </div>
          
          <DialogActionsBar>
            <div className="d-flex justify-content-center w-100 gap-3">
              <button
                className="submitButon"
                onClick={onConfirm}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>
                    <i className="fa fa-spinner fa-spin me-2"></i>
                    Processing...
                  </span>
                ) : (
                  "Confirm & Add Staff"
                )}
              </button>
              <button
                className="cancelBtn"
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default EprescribeConfirmationModal;