
import { saveAs } from "@progress/kendo-file-saver";
import { customPuppeteerAxios } from "./useExPressPuppeteerApi";

enum DocumentType {
  CLIENT_DOCUMENT = "document",
  STAFF_DOCUMENT = "staff-document",
  SOCIAL_DETERMINANTS = "social-determinants",
  CLIENT_DASHBOARD = "client-dashboard",
  CLIENT_DISCHARGE = "client-discharge",
  QUESTIONNAIRE = "questionnaire",
  TREATMENT_PLAN = "treatmentplan-document",
}

const generatePdf = async (documentType: DocumentType,formattedElements: any,fileName: string,versionNumber?:string | null | undefined) => {
  try {
    const url = `/generate-pdf/${documentType}`;
    const compressedBody = formattedElements;

    let retry = 1;
    const maxRetry = 3;

    while (retry <= maxRetry) {
      const response = await customPuppeteerAxios.post(url, compressedBody);
      if (response.status >= 200 && response.status < 300) {
        const blob = response.data;
        if(documentType === DocumentType.TREATMENT_PLAN && versionNumber)
        {
          saveAs(blob, `${fileName} v${versionNumber}.pdf`);
        }
        else{
          saveAs(blob, `${fileName}.pdf`);
        }
       
        if (documentType === DocumentType.QUESTIONNAIRE) {
          localStorage.removeItem("print-que-state");
        }

        window.close();
        return true;
      } else if (retry === maxRetry) {
        return `Error: ${response.statusText || response.status}`;
      }

      retry++;
    }
  } catch (error) {
    return `Error: ${error}`;
  }
};

export const puppeteerService = {
  generateClientDocumentPdf: (elements: any) => generatePdf(DocumentType.CLIENT_DOCUMENT, elements, "Document"),
  generateStaffDocumentPdf: (elements: any) => generatePdf(DocumentType.STAFF_DOCUMENT, elements, "StaffPlanDocument"),
  generateSocialDeterminantsPdf: (elements: any) => generatePdf(DocumentType.SOCIAL_DETERMINANTS,elements,"SocialDeterminants"),
  generateClientDashBoardPdf: (elements: any) => generatePdf(DocumentType.CLIENT_DASHBOARD, elements, "ClientDashboard"),
  generateClientDischargePdf: (elements: any) => generatePdf(DocumentType.CLIENT_DISCHARGE, elements, "Discharge"),
  generateQuestionnairePdf: (elements: any) => generatePdf(DocumentType.QUESTIONNAIRE, elements, "Questionnaire"),
  generateTreatmentPlanPdf: (elements: any,versionNumber:string | null | undefined) => generatePdf(DocumentType.TREATMENT_PLAN, elements, "TreatmentPlan",versionNumber),
};
