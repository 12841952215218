import React, { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { pieChartModel } from "src/dataModels/docListModel";
import { totalHoursAndMinutes } from "src/util/utility";
import { userIsSuperAdmin, userIsAdmin } from "src/helper/permission-helper";
import { ListView, ListViewHeader } from "@progress/kendo-react-listview";
import CustomSkeleton from "src/control-components/skeleton/skeleton";
import CommonListFilterItem from "./common-list-filter-item";
import { TableRowsLoader } from "src/control-components/custom-skelton";

export const BillingDocumentsPieChart = () => {
  const state: ComponentState = useSelector((states) => {
    return states;
  });
  const isBillingManager = state.getRolePermission.isBillingManager;
  const isHumanResourcesManager = useSelector(
    (state: any) => state.getRolePermission?.isHumanResourcesManager
  );
  const staffLoginInfo = useSelector(
    (state: ComponentState) => state.getStaffReducer
  );
  const userIsSA = userIsSuperAdmin(staffLoginInfo?.roleId);
  const userIsA = userIsAdmin(staffLoginInfo?.roleId);
  const showBillingAmounts = state.getRolePermission.showBillingAmounts;
  const canShowBillData = isBillingManager || userIsSA || showBillingAmounts;
  const canShowTime =
    isBillingManager ||
    userIsSA ||
    showBillingAmounts ||
    isHumanResourcesManager ||
    userIsA;
  const [billingData, setBillingData] = useState<pieChartModel[]>([]);
  const [isShowItems, setShowItems] = useState(true);
  const { documentBillingChartListResp, loadingObject } = state["DocumentReducer"];
  useEffect(() => {
    setBillingData([]);
    if (
      documentBillingChartListResp &&
      documentBillingChartListResp?.resultData
    ) {
      documentBillingChartListResp?.resultData?.map((element, index) => {
        const minutes = totalHoursAndMinutes(element?.totalMinutes);
        const totalBillValue = Number(element?.totalBilled).toFixed(2);
        const formattedTotalBill = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(totalBillValue));
        const label = `Docs:${element?.totDocs} ,${canShowTime ? "Time:" + " " + minutes : ""
          } , ${canShowBillData ? "Billed Amt:" + formattedTotalBill : ""}`;
        var obj: pieChartModel = {
          id: element?.billingStatusId,
          label: `${element?.billingStatus}`,
          value: element?.totalBilled,
          color: element?.color,
          info: label,
          totalBilled: canShowBillData ? formattedTotalBill : "",
          count: element?.totDocs,
        };
        setBillingData((oldArray) => [...oldArray, obj]);
      });
    }
  }, [documentBillingChartListResp]);

  const mostDocumentsBilling = billingData[0];
  const totalValue = billingData.reduce((sum, item) => sum + item.value, 0);

  const MyHeader = () => {
    return (
      <ListViewHeader
        style={{
          color: "#000000",
          fontSize: 30,
        }}
        className="px-3 py-2 "
      >
        <div className="row border-bottom align-middle">
          <div className="col-4">
            <h2 className="f-14">Status</h2>
          </div>
          <div className="col-4">
            <h2 className="f-14"># of Docs</h2>
          </div>
          <div className="col-4">
            <h2 className="f-14 ">Billing Charges</h2>
          </div>
        </div>
      </ListViewHeader>
    );
  };
  const MyItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div
        className="row py-2 border-bottom align-middle"
        style={{ margin: 0 }}
      >
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <span className='hover_underline'><CommonListFilterItem item={item} type={"billStatus"} /> </span>
          )}
        </div>
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.count}</div>
          )}
        </div>
        <div className="col-4">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div>{item?.totalBilled}</div>
          )}
        </div>
      </div>
    );
  };

  return (
    <>{
      <div className="grid-table filter-grid dash-filter-grid my-3 caseload-custom gridheight casload boxHeight traningList topFixHeader">
        <h5>
          {" "}
          Billing Metrics
        </h5>
        {(loadingObject?.includes(3))? <TableRowsLoader rowsNum={12} /> :
          documentBillingChartListResp?.resultData?.length != 0 ? (
            <ListView
              className="traningList"
              data={billingData}
              item={MyItemRender}
              style={{
                width: "100%",
                display: "block",
              }}
              header={MyHeader}
            />

          ) : (
            <p>No Record found</p>
          )
        }

      </div>
    }

    </>



  );
};

export default BillingDocumentsPieChart;
