import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const SubscribeConfirmationModal = ({ 
  isOpen, 
  onClose, 
  onConfirm, 
  isLoading 
}) => {
 

  return (
    <>
      {isOpen && (
        <Dialog
          title="Confirm eRx Subscription"
          onClose={onClose}
          className="dialog-modal blue_theme"
          width={500}
        >
          <div className="confirmation-content p-3">
            
            <div className="confirmation-details mb-4">
              <p className="mb-3">
              You are about to enroll your clinic in electronic prescribing. This includes a <b>one-time setup fee of $99</b> to enable eRx for your clinic.
              </p>
              
              
            </div>
            
            <p className="confirmation-question fw-bold text-center mb-3">
            Would you like to proceed with your eRx subscription?
            </p>
          </div>
          
          <DialogActionsBar>
            <div className="d-flex justify-content-center w-100 gap-3">
              <button
                className="submitButon"
                onClick={onConfirm}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span>
                    <i className="fa fa-spinner fa-spin me-2"></i>
                    Processing...
                  </span>
                ) : (
                  "Confirm & Add Staff"
                )}
              </button>
              <button
                className="cancelBtn"
                onClick={onClose}
                disabled={isLoading}
              >
                Cancel
              </button>
            </div>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};

export default SubscribeConfirmationModal;