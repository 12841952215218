import moment from "moment";
import pako from "pako";
import { ComponentState, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddDocumentFileTemplate } from "src/app-modules/documents/add-document-file-template";
import { getStaffDocAttachments, getStaffDocDetailById, getStaffDocSignature, } from "src/redux/actions";
import { totHoursAndMinutes } from "src/app-modules/documents/document-utility";
import { saveAs } from "@progress/kendo-file-saver";
import { styles } from "../../documents/pdf-generator/style";
import DateTimeHelper from "src/helper/date-time-helper";
import ProgressLoading from "src/control-components/loader/loader_with_text";
import { contactTypeArr } from "./addStaffDocComponents/contact-type";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { puppeteerService } from "src/services/puppeteerPdfService";

const StaffDocPdf = () => {

  const _elementRef = useRef<HTMLDivElement | null>(null);
  const state: ComponentState = useSelector((states) => {
    return states;
  });
  var stateData: any = localStorage.getItem("staffPlanDocPrint");
  var stateJsonData: any = JSON.parse(stateData);
  const dispatch = useDispatch();
  const [documentId, setDocumentId] = useState(stateJsonData?.docId);
  const { documentDetailedData, docAttachments, docSignature } = state["StaffDocReducer"];
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isDocumentRendered, setIsDocumentRendered] = useState(false);
  const classRow = "row col-lg-11 ml-1 mt-3";
  const clinicLogo = useSelector((states: any) => states.logoByte);
  const [metaField, setMetaField] = useState({});
  const [errorMessage, setErrorMessage] = useState<string | undefined>("")
  const [documentFileComponentLoadCount, setDocumentFileComponentLoadCount] = useState(0);
  const [isDocumentFileComponentLoaded, setIsDocumentFileComponentLoaded] = useState(false);

  useEffect(() => {
    if (documentFileComponentLoadCount === 1 /* Check pdf-doc-temp if multiple print required. */) {
      setIsDocumentFileComponentLoaded(true)
    }
  }, [documentFileComponentLoadCount]);

  useEffect(() => {
  }, [documentFileComponentLoadCount])

  useEffect(() => {
    if (!isDataLoaded) {
      setIsDataLoaded(true);
      dispatch(getStaffDocDetailById(documentId));
      dispatch(getStaffDocAttachments(documentId));
      dispatch(getStaffDocSignature(documentId));
    }
  }, [isDataLoaded]);

  useEffect(() => {
    if (documentDetailedData && documentDetailedData.id) {

      setMetaField({
        staffName: documentDetailedData?.staffName,
        documentId: documentDetailedData?.id,
        createdBy: docSignature?.map((item: any) => item.staffName).join(", "),
        templateName: documentDetailedData?.documentTemplateName
      });
    }
  }, [documentDetailedData, docSignature]);

  useEffect(() => {
    if (isDocumentFileComponentLoaded && _elementRef && _elementRef.current && isDocumentRendered && metaField && Object.keys(metaField).length > 0) {
      setTimeout(() => {
        handleSelect();
      }, 2000);
    }
  }, [_elementRef, isDocumentRendered, metaField, isDocumentFileComponentLoaded]);

  const removeClassFromHtmlString = (html: any, className: any) => {
    const regex = new RegExp(`\\b${className}\\b`, 'g');
    return html.replace(regex, '').replace(/\s{2,}/g, ' ').trim();
  };

  function onComponentLoaded() {
    setDocumentFileComponentLoadCount(x => x + 1);
  }

  const handleSelect = async () => {
    try {
      const styles = Array.from(document.styleSheets)
        .map((styleSheet) => {
          try {
            return Array.from(styleSheet.cssRules)
              .map((rule) => rule.cssText)
              .join("");
          } catch (e) {
      
          }
          return "";
        })
        .join("\n");
      var notNullableItems = _element1.filter(
        (item) => item !== null && item !== undefined
      );
      const formattedElements = notNullableItems.map((optionItem, index) => {
        const updatedHtmlString = removeClassFromHtmlString(optionItem.outerHTML, "w-750")
        return {
          //   id: cDocId,y
          html: updatedHtmlString,
          title: "Staff Document Print",
          author: "Notenetic Team",
          styles,
          index,
          metaData: metaField
        };
      });
      const body = JSON.stringify({ elements: formattedElements });
      const compressedBody = pako.gzip(body);
       const result = await puppeteerService.generateStaffDocumentPdf(compressedBody);
        if(result !== true)
        {
          setErrorMessage(result);
        }
    }
    catch (error) {
      setErrorMessage( `Error: ${error}`)
    }
      
  };

  function renderStaffSignature() {
    return docSignature.length < 1
      ? renderContent("---")
      : docSignature.map((obj, index) => (
        <div
          key={index}
          className="col-12 border p-2 mb-3 mt-4 keep-together"
        >
          <div className="labelFont">
            <div className="">
              <p className="mb-0 printableContent">
                Signed by: <span className="fw-500">{obj.staffName}</span>
                {obj?.staffCredentials && (
                  <span className="staff-credentials">
                    ({obj.staffCredentials})
                  </span>
                )}
              </p>
              <p className="mb-0 printableContent">
                Date:{" "}
                <span className="fw-500">
                  {moment(obj.dateSig).format("M/D/YYYY")}
                </span>
              </p>
            </div>
            <img
              className="signImage"
              alt="demo"
              src={"data:image/png;base64," + obj.signature}
            />
            <div>
              <p className="f-10 mb-0">
                Electronically signed:{" "}
                <span className="fw-300">
                  {moment
                    .utc(obj.utcDateCreated)
                    .local()
                    .format("M/D/YYYY hh:mm A")}
                </span>
              </p>
            </div>
          </div>
        </div>
      ));
  }


  function renderHeader(headerText) {
    return (
      <p className="col-12 mb-0 mt-0 px-0 printableHeader">{headerText}</p>
    );
  }
  function renderContent(contentText) {
    return (
      <p className="col-12 ml-2 mb-0 mt-0 px-0 printableContent">
        {contentText}
      </p>
    );
  }
  function renderVerticalPair(headerText, contentText) {
    return (
      <div className="col-4 mb-1 mt-1" style={{ marginRight: "-20px" }}>
        <p className="col-11 mb-0 mt-0 printableHeader">{headerText}</p>
        <p className="col-11 mb-0 mt-0 printableContent">
          {contentText}
        </p>
      </div>
    );
  }

  function renderTemplateName(name) {
    return (
      <p className="printableContent ml-3">
        {name}
      </p>
    );
  }

  
  function renderGoals() {
    return <div>
      {documentDetailedData?.staffPlanDocGoals?.map((goalData: any, index) => {
        return (
          <p className="col-11 mb-0 mt-0 printableContent">
            {goalData.goalName}{goalData.goalDescription && <p className="printableContent">{` (${goalData.goalDescription})`}</p>}
          </p>)
      })}
    </div>
  }

  let _element1: HTMLElement[] = [];
  return (
    <>
      {errorMessage ? (
        <ProgressLoading message={"Something went wrong, please try again later! " + errorMessage} />
      ) : (
        <ProgressLoading message={"Please wait while we are collecting information for document."} />
      )}
      <div
        ref={(div: any) => {
          _element1.push(div);
          _elementRef.current = div;
          setIsDocumentRendered(true);
        }}
      >
        <div>
          <style> {styles}</style>
          <div className="row">
            <div className="col-md-12">
              <div
                className="document-user-cover"
                style={{ background: "#FFFFFF" }}
              >
                <ul className="d-flex align-items-center list-unstyled ml-3 gap-2">
                  <li>
                    <p className="document-pdf-img">
                      <img
                        src={"data:image/png;base64," + clinicLogo}
                        className="user-pdf"
                        alt=""
                      />
                    </p>
                  </li>
                  <li>
                    <h5 className="printableHeading " style={{fontSize:"16px"}}>Notenetic Clinic</h5>
                    <h5 className="printableHeading d-block" style={{fontSize:"16px !important"}}>  {renderTemplateName(documentDetailedData.documentTemplateName)} </h5>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={classRow} style={{ width: "125%", marginLeft: "3px" }}>
            {renderVerticalPair("Staff", documentDetailedData?.staffName)}
            {renderVerticalPair(
              "Date Implementation",
              DateTimeHelper.formatDatePickerString(
                documentDetailedData?.dateImplement
              )
            )}
            {renderVerticalPair(
              "Duration",
              documentDetailedData?.totalMinutes !== null &&
                documentDetailedData?.totalMinutes !== undefined
                ? `${documentDetailedData.totalMinutes} mins` +
                (totHoursAndMinutes(documentDetailedData.totalMinutes)
                  ? ` (${totHoursAndMinutes(
                    documentDetailedData.totalMinutes
                  )})`
                  : "")
                : null
            )}
            {renderVerticalPair(
              "Contact Type",
              documentDetailedData?.contactType
                ? contactTypeArr.find((arrItem) => arrItem.value === documentDetailedData?.contactType)?.label
                : "N/A"
            )}
          </div>

          <div className={classRow} style={{ marginLeft: "13px" }}>
            {renderHeader("Clinic Skills")}
            {renderContent((documentDetailedData?.staffPlanClinicialSkills && documentDetailedData?.staffPlanClinicialSkills.length > 0) ?
              documentDetailedData?.staffPlanClinicialSkills.map((skillItem) => skillItem?.skillName) : "N/A"

            )}
          </div>

          <div className={classRow} style={{ marginLeft: "13px" }}>
            {renderHeader("Additional Clinical Skill")}
            {renderContent(documentDetailedData?.additionalSkills)}
          </div>

          <div className={classRow} style={{ marginLeft: "13px" }}>
            {renderHeader("Staff Service Review")}
            {renderContent(
              documentDetailedData?.reviewOfServices
                ? documentDetailedData?.reviewOfServices
                : "N/A"
            )}
          </div>

          <div className={classRow} style={{ marginLeft: "13px" }}>
            {renderHeader("Goals")}
            {renderContent(documentDetailedData?.staffPlanDocGoals?.length > 0?(renderGoals() ) : "N/A" )}
          </div>

          <div className="form-group  mb-3 col-md-12 printableContent">
            {documentDetailedData?.htmlFileName && <AddDocumentFileTemplate
              onComponentLoaded={onComponentLoaded}
              controlErrors={null}
              showValidationError={null}
              focusEnable={null}
              name={documentDetailedData.htmlFileName}
              selectedPatientList={[]}
              isEdit={false}
              onGoalsDataStateChange={() => { }}
              isViewDoc={true}
              preselctedGoalEdit={[]}
              dxPreselectedData={[]}
              onDxStateChange={() => { }}
              tpId={0}
              isDraft={false}
              draftCommonControlls={[]}
              isViewMode={true}
              docFieldMappings={documentDetailedData?.documentDeserializedData?.fieldsMappings}
              documentId={documentId}
            />}
          </div>

          {/* Signature */}
          <div className="dmr">
            <div className={classRow} style={{ marginLeft: "13px" }}>
              {renderHeader("Signature")}
              {renderStaffSignature()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StaffDocPdf;
