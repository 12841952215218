import React, { useEffect, useState } from "react";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import moment from "moment";
import {
  getTelehealthLogs,
  persistTelehealthLogsFilters,
} from "src/redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import APP_ROUTES from "src/helper/app-routes";
import { TableRowsLoader } from "src/control-components/custom-skelton";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "src/control-components/date-picker/date-picker";
import calender from "../../assets/images/calendar-03.png";
import { orderBy } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";

const idGetter = getter("id");
const SELECTED_FIELD = "selected";
function TelehealthLogs() {
  const state = useSelector((states) => {
    return states;
  });
  const { telehealthLogsResp, telehealthLogsFilters } = state["ReportReducer"];
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [sort, setSort] = useState([]);
  const [selectedState, setSelectedState] = React.useState({});
  const [logsData, setLogsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [requestObj, setRequestObj] = useState({
    startDate: moment().subtract(6, "months").toDate(),
    endDate:  moment().toDate(),
  });

  useEffect(() => {
    const dateLedgerStart = moment(requestObj.startDate)
      .startOf("day")
      .format("YYYY-MM-DD");
    const dateLedgerEnd = moment(requestObj.endDate)
      .startOf("day")
      .format("YYYY-MM-DD");
    const data = {
      startDate: dateLedgerStart,
      endDate: dateLedgerEnd,
    };
    setLoading(true);
    dispatch(getTelehealthLogs(data));
  }, [requestObj]);

  useEffect(() => {
    if (telehealthLogsResp && telehealthLogsResp?.status && telehealthLogsResp?.resultData) {
      setLogsData(telehealthLogsResp?.resultData);
      setLoading(false);
    }
    else {
      setLogsData([]);
      setLoading(false);
    }
  }, [telehealthLogsResp]);

  const pageChange = (event) => {
    let skip = event.page.skip;
    let take = event.page.take;
    setPage(skip);
    setPageSize(take);
    let newValue = skip / take;
    let finalValue = newValue + 1;
    window.scrollTo(0, 0);
  };

  function handleRoomIdClick(roomId) {
    navigate(APP_ROUTES.TELEHEALTH_LOGS_DETAILS, { state: { roomId } });
  }

  const handleHoldDate = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    setRequestObj({
      ...requestObj,
      [name]: value,
    });
    setPage(0);
    dispatch(
      persistTelehealthLogsFilters({ ...telehealthLogsFilters, [name]: value })
    );
  };

  return (
    <>
      <div>
        <div className="col-lg-12 col-md-5 col-sm-12">
          <h4 className="address-title text-grey  text-left">
            <span className="f-24">Telehealth Logs</span>
          </h4>
        </div>
        <div className="filterRow d-flex align-items-end justify-content-between">
          <form className="blueTheme row">
            <div className="col-md-5 col-12">
              <label>From</label>
              <div className="position-relative">
                <DatePicker
                  calendar={CustomCalendar}
                  format={"M/d/yyyy"}
                  name="startDate"
                  size={"medium"}
                  value={requestObj.startDate}
                  onChange={handleHoldDate}
                  autoFocus={false}
                  className="form-control"
                />
                <img src={calender} alt="" className="leftIcon" />
              </div>
            </div>
            <div className="col-md-5 col-12">
              <label>To</label>
              <div className="position-relative">
                <DatePicker
                  calendar={CustomCalendar}
                  format={"M/d/yyyy"}
                  name="endDate"
                  onChange={handleHoldDate}
                  size={"medium"}
                  value={requestObj.endDate}
                  autoFocus={false}
                  className="form-control"
                />
                <img src={calender} alt="" className="leftIcon" />
              </div>
            </div>
          </form>
        </div>
        {loading ? (
          <TableRowsLoader rowsNum={12} />
        ) : (
          <div className="grid-table blueThemeTable  filter-grid ml-2 cursor-default">
            <Grid
              data={orderBy(logsData.slice(page, page + pageSize), sort).map(
                (item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                })
              )}
              style={{
                height: logsData.length > 0 ? "100%" : "250px",
              }}
              skip={page}
              take={pageSize}
              total={logsData?.length}
              onPageChange={pageChange}
              className="pagination-row-cus"
              selectedField={"selected"}
              pageable={{
                pageSizes: [10, 20, 30, 50, 100, 500],
              }}
              dataItemKey={"id"}
              sortable={true}
              sort={sort}
              onSortChange={(e) => {
                setSort(e.sort);
              }}
            >
              <GridColumn
                title="Room Name and SID"
                field="timeStamp"
                className="cursor-default"
                cell={(props) => {
                  let roomId = props.dataItem.roomId;
                  let roomName = props.dataItem.roomName;
                  return (
                    <td className="ancher-pointer">
                      <span className="cursor-default">{roomName}</span>
                      <br></br>
                      <span
                        onClick={() => handleRoomIdClick(roomId)}
                        style={{ textDecoration: "underLine" }}
                        className="ancher-pointer text-theme"
                      >
                        {roomId}
                      </span>
                    </td>
                  );
                }}
              />
              <GridColumn
                title="Time Stamp"
                field="timeStamp"
                className="cursor-default"
                cell={(props) => {
                  let field = props.dataItem.timeStamp;
                  return (
                    <td className="cursor-default">
                      {moment.utc(field).local().format("M/D/YYYY")} at{" "}
                      {moment.utc(field).local().format("hh:mm A")}
                    </td>
                  );
                }}
              />

              <GridColumn
                title="Duration"
                field="duration"
                className="cursor-default"
              />
              <GridColumn
                title="# Participants"
                field="noOfParticipants"
                className="cursor-default"
              />
            </Grid>
          </div>
        )}
      </div>
    </>
  );
}

export default TelehealthLogs;
