
import ApiHelper from "../helper/api-helper";
import { API_ENDPOINTS } from "./api-endpoints";
import { customAxios } from "./useExPressApi";





const getTelehealthToken = (eventId, userName, userId, isHost, isClient) => {
  var data = {
    "eventId": eventId,
    "userName": userName,
    "userId": userId,
    // "isHost": isHost,
    "isClient": isClient
  }

  return ApiHelper.telehealthPostRequest(API_ENDPOINTS.GET_TELEHEALTH_TOKEN, data,);
};

const getClientTelehealthToken = (userId, isClient, roomId, userName) => {
  var data = {
    "userId": userId,
    "isClient": isClient,
    "roomId": roomId,
    "userName": userName
  }
  return ApiHelper.telehealthPostRequest(API_ENDPOINTS.GET_TELEHEALTH_ACCESS_TOKEN, data);
};


const closeTelehealthSession = (roomId) => {
  return customAxios.get(API_ENDPOINTS.CLOSE_TELEHEALTH_SESSION + roomId);

};

const sendLinkService = (roomName, clientDetails) => {
  const data = {
    clientId: clientDetails?.id,
    roomId: roomName
  }
  return customAxios.post(API_ENDPOINTS.SEND_TELEHEALTH_LINK_TO_CLIENT, data);

};

const sendLinkServiceByEventId = (roomName, eventId) => {
  const data = {
    eventId:eventId,
    roomId: roomName
  }
  return customAxios.post(API_ENDPOINTS.SEND_TELEHEALTH_LINK_TO_CLIENT_BY_EVENT_ID, data);

};




export const VideoService = {
  getTelehealthToken,
  closeTelehealthSession,
  getClientTelehealthToken,
  sendLinkService,
  sendLinkServiceByEventId
};