import React, { ComponentElement, ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { FormControlLabel, Radio } from "@mui/material";

export const BdssQueSet = ({
    listItems,
    setListItems,

    subTitle
}) => {

    const [opList, setOpList] = useState(listItems)
    const [totalSum, setTotalSum] = useState<any>();

    useEffect(() => {
        if (opList && opList.length > 0) {

            setListItems(opList);
            const opSum = opList?.reduce((sum, item) => sum + (Number(item.value) || 0), 0);
            if (opSum > 0) {
                setTotalSum(opSum)
            }
            else {
                setTotalSum(null);
            }
        }
    }, [opList])



    const handleRadioChange = (itemId, value) => {
        // Find the item in the items array

        setOpList(list => list.map((item, i) =>
            item.id === itemId
                ? {
                    ...item,
                    value: value
                }
                : item
        ));


    };


    return (
        <div className="ACE_main_table mt-4  printCheckboxSpace">
            <div>
                {/* <div className="head-3">{title}</div> */}
                <label className="pb-3 mt-2 head-6">
                    {subTitle}
                </label>
            </div>
            <div className="row border-bottom mb-3">
                <div className="col-12">
                    <label className="fw-400">
                        <b>NOTE:</b> &nbsp;
                        N/A = Not assessed, &nbsp;
                        1 = Not present; &nbsp;
                        2 = Very mild; &nbsp;
                        3 = Mild; &nbsp;
                        4 = Moderate; &nbsp;
                        5 = Moderately Severe;  &nbsp;
                        6 = Severe; &nbsp;
                        7 = Extremely severe;
                    </label>
                </div>

            </div>
            {opList?.map((item) => (
                <div className="row mb-3 pb-2 border-bottom">
                    <div className="col-12">
                        <div key={item.id}>
                            {item.parentHeader &&
                                <div className=" row col-12 head-6 majorLabel">  
                                      <span> {item.parentHeader} </span> 
                                </div>
                            }
                            <label>
                                {item.id}. {item.name}
                            </label>
                        </div>
                    </div>
                    <div className="col-12  select_table_phq cus-select-table-drops">
                        {['N/A', 1, 2, 3, 4, 5, 6, 7].map((value) => (
                            <label key={value} className="cats_label">
                                <input
                                    type="radio"
                                    value={value}
                                    checked={item.value == value}
                                    onChange={() => handleRadioChange(item.id, value)}
                                    className="mr-2 mb-0 ml-2"
                                />
                                <span>{value}</span></label>
                        ))}
                    </div>
                </div>
            ))}
            <div className="text-start justify-content-start total_phq col-12">
                <p><b>Scale Total:</b> </p>
                <p className="submitButon mb-0"> {totalSum}</p>

            </div>
        </div>
    );
};

