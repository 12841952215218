export enum DashboardItems {
    BILLING_INFORMATION = "BILLING_INFORMATION",
    TODAYS_APPTS = "TODAYS_APPTS",
    DAYS_SINCE_CLIENT_LAST_SEEN = "DAYS_SINCE_CLIENT_LAST_SEEN",
    CLIENTS_BY_PAYER = "CLIENTS_BY_PAYER",
    LOGGED_IN_STAFF = "LOGGED_IN_STAFF",
    UPCOMING_COMPLIANCE = "UPCOMING_COMPLIANCE",
    CLIENTS_BY_SITE = "CLIENTS_BY_SITE",
    DOCUMENTS_PENDING_REVIEW = "DOCUMENTS_PENDING_REVIEW",
    CLIENTS_BY_STATUS = "CLIENTS_BY_STATUS",
    MY_CASELOAD_CLIENTS = "MY_CASELOAD_CLIENTS",
    CLIENT_AUTHORIZATIONS = "CLIENT_AUTHORIZATIONS",
    CURRENT_TASKS = "CURRENT_TASKS",
    STAFF_NOT_LOGGED_IN = "STAFF_NOT_LOGGED_IN",
    CURRENT_BULLETINS = "CURRENT_BULLETINS",
    SERVICE_RULE_USAGE = "SERVICE_RULE_USAGE",
    TRAININGS = "TRAININGS",
    ANNOUNCEMENTS="ANNOUNCEMENTS",
    CLINIC_INFO="CLINIC_INFO"
  }

export const DefaultDashboardData = [
    {
        "title": "Billing Information",
        "dashboard_id": "BILLING_INFORMATION",
        "dashboard_position": 0,
        "is_showing": true,
    },
    {
        "title": "Today's Appointments",
        "dashboard_id": "TODAYS_APPTS",
        "dashboard_position": 1,
        "is_showing": true
    },
    {
        "title": "Days Since Client last seen",
        "dashboard_id": "DAYS_SINCE_CLIENT_LAST_SEEN",
        "dashboard_position": 2,
        "is_showing": true
    },
    {
        "title": "Clients by Payer",
        "dashboard_id": "CLIENTS_BY_PAYER",
        "dashboard_position": 3,
        "is_showing": true
    },
    {
        "title": "Logged In Staff",
        "dashboard_id": "LOGGED_IN_STAFF",
        "dashboard_position": 4,
        "is_showing": true
    },
    {
        "title": "Upcoming Compliance",
        "dashboard_id": "UPCOMING_COMPLIANCE",
        "dashboard_position": 5,
        "is_showing": true
    },
    {
        "title": "Clients by Site",
        "dashboard_id": "CLIENTS_BY_SITE",
        "dashboard_position": 6,
        "is_showing": true
    },
    {
        "title": "Documents Pending Review",
        "dashboard_id": "DOCUMENTS_PENDING_REVIEW",
        "dashboard_position": 7,
        "is_showing": true
    },
    {
        "title": "Clients by Status",
        "dashboard_id": "CLIENTS_BY_STATUS",
        "dashboard_position": 8,
        "is_showing": true
    },
    {
        "title": "My Caseload Clients",
        "dashboard_id": "MY_CASELOAD_CLIENTS",
        "dashboard_position": 9,
        "is_showing": true
    },
    {
        "title": "Client Authorizations",
        "dashboard_id": "CLIENT_AUTHORIZATIONS",
        "dashboard_position": 10,
        "is_showing": true
    },
    {
        "title": "Current Tasks",
        "dashboard_id": "CURRENT_TASKS",
        "dashboard_position": 11,
        "is_showing": true
    },
    {
        "title": "Staff Not Logged In",
        "dashboard_id": "STAFF_NOT_LOGGED_IN",
        "dashboard_position": 12,
        "is_showing": true
    },
    {
        "title": "Current Bulletins",
        "dashboard_id": "CURRENT_BULLETINS",
        "dashboard_position": 13,
        "is_showing": true
    },
    {
        "title": "Announcements",
        "dashboard_id": "ANNOUNCEMENTS",
        "dashboard_position": 14,
        "is_showing": true
    },
    {
        "title": "Trainings",
        "dashboard_id": "TRAININGS",
        "dashboard_position": 15,
        "is_showing": true
    },
    {
        "title": "Clinic Info",
        "dashboard_id": "CLINIC_INFO",
        "dashboard_position": 16,
        "is_showing": true
    }
]

