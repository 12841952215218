import React, {useState } from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import { ErrorBoundary } from "react-error-boundary";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useNavigate } from "react-router";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import {
  default as AddAuthorization,
  default as EditAuthorization,
} from "../src/app-modules/client/client-profile/authorization/add-authorization";
import "../src/custom-css/nn-kendo.scss";
import "./App.css";
import {
  default as AddMultipleClientAuth,
  default as EditMultipleClientAuth,
} from "./app-modules/clinic/service-auth/add-multiple-client-auth";
import Protected from "./container/protected";
import ErrorFallback from "./control-components/error-boundry/error-boundry";
import Layout from "./control-components/layout/layout";
import Telehealth from "./control-components/telehealth/telehealth";
import AppRoutes from "./helper/app-routes";
import DEVELOPMENT_CONFIG from "./helper/config";
import { permissionEnum, userIsAdmin, userIsSuperAdmin, userIsQA } from "./helper/permission-helper";
import APP_ROUTES from "./helper/app-routes";
import { useEffect } from "react";
import { ViewDocumentTemplate } from "./app-modules/documents/template/view-document-template";
import Loader from "./control-components/loader/loader";
import withClearCache from './ClearCache';
import { customAxios } from './services/useExPressApi';
import { API_ENDPOINTS } from './services/api-endpoints';
import { CallByClient } from './app-modules/callClient/callByClient';
import ClientComplianceReport from './app-modules/client/client-profile/compliance/compliance-report';
import ClinicAuthSets from './app-modules/clinic/auth_sets';
import moment from 'moment';
import { renderErrors } from "src/helper/error-message-helper";
import ApiHelper from "./helper/api-helper";
import { changeAppRoute, loginUser, roleModeAction, saveEncryptedRedirectDocId, setAppImpersonateFlag, staffSettingsAction } from './redux/actions';
import EPrescription from './app-modules/clinic/prescriptions';
import AddNewClinician from './app-modules/clinic/prescriptions/add_new_clinician';
import { telehealthDecrption } from './app-modules/encrption';
import { TimeClock } from './app-modules/time-clock';
import { KnowledgeBase } from './app-modules/knowledge-base';
import PrintDocument from './app-modules/documents/pdf-generator/print-doc';
import LatestDocumentId from './app-modules/documents/latest-document-id';
import PrintQuestionnaire from './app-modules/client/client-profile/questionnaire/print-questionnaire';
import GenerateTimeClockReport from "./app-modules/time-clock/generate-report.tsx"
import StaffDocumentTemplateList from './app-modules/staff/documents/template';
import { AdminViewDocumentFileTemplate } from "./app-modules/documents/template/admin-view-document-file-template";
import StaffDocPdf from './app-modules/staff/documents/staff-doc-pdf';
import MinServiceReport from './app-modules/client/client-profile/min-service-report';
import AssignStaffReportHeader from './app-modules/staff/assign-staff-report/Header';
import ReportManager from './app-modules/report-manager';
import MapHeader from './app-modules/staff/map';
import UpdateClientQuestionnaire from './app-modules/callClient/client-portal-questionnaire';
import TelehealthLogs from './app-modules/audit/telehealth-logs';
import TelehealthLogsDetails from './app-modules/audit/telehealth-logs-room';

// -------------------------------------------Lazy Loading------------------------------------------------------------------
const ListAuthorization = React.lazy(() => import("../src/app-modules/client/client-profile/authorization/list-authorization"));
const TreatmentPlan = React.lazy(() => import("../src/app-modules/client/client-profile/treatment-plan/list-treatment-plan"));
const ClientAudit = React.lazy(() => import("./app-modules/audit/client-audit"));
const ClinicAudit = React.lazy(() => import("./app-modules/audit/clinic-audit"));
const StaffAudit = React.lazy(() => import("./app-modules/audit/staff-audit"));
const AddClient = React.lazy(() => import("./app-modules/client/add-client"));
const AssignServiceToClient = React.lazy(() => import("./app-modules/client/client-profile/assign-services/header"));
const AssignStaffToClient = React.lazy(() => import("./app-modules/client/client-profile/assign-staff/header"));
const ClientDashboard = React.lazy(() => import("./app-modules/client/client-profile/client-dashboard/client-dashboard"));
const AddClientSignature = React.lazy(() => import("./app-modules/client/client-profile/client-signature/add-client-signature"));
const AddParentSignature = React.lazy(() => import("./app-modules/client/client-profile/client-signature/add-parent-signature"));
const ClientSignature = React.lazy(() => import("./app-modules/client/client-profile/client-signature/client-signature"));
const AddStoredDocuments = React.lazy(() => import("./app-modules/client/client-profile/client-files/add-client-files"));
const StoredDocuments = React.lazy(() => import("./app-modules/client/client-profile/client-files/client-files"));
const EditStoredDocuments = React.lazy(() => import("./app-modules/client/client-profile/client-files/edit-client-files"));
const ContactNotesList = React.lazy(() => import("./app-modules/client/client-profile/contact-notes/list-contact-notes"));
const AddCPInsurance = React.lazy(() => import("./app-modules/client/client-profile/cp-insurance/add-insurance"));
const EditInsurance = React.lazy(() => import("./app-modules/client/client-profile/cp-insurance/edit-insurance"));
const Insurance = React.lazy(() => import("./app-modules/client/client-profile/cp-insurance/list-insurance"));
const Diagnosis = React.lazy(() => import("./app-modules/client/client-profile/diagnosis/diagnosis"));
const Discharge = React.lazy(() => import("./app-modules/client/client-profile/discharge/discharge"));
const BackgroundList = React.lazy(() => import("./app-modules/client/client-profile/background/list-background"));
const AddGuardian = React.lazy(() => import("./app-modules/client/client-profile/guardian-parent/add-guardian"));
const GuardianParent = React.lazy(() => import("./app-modules/client/client-profile/guardian-parent/list-guardian"));
const AddImmunization = React.lazy(() => import("./app-modules/client/client-profile/immunization/add-immunization"));
const ImmunizationDetails = React.lazy(() => import("./app-modules/client/client-profile/immunization/immunization-details"));
const ImmunizationList = React.lazy(() => import("./app-modules/client/client-profile/immunization/list-immunization"));
const EditImmunization = React.lazy(() => import("./app-modules/client/client-profile/immunization/update-immunization"));
const AddInsurance = React.lazy(() => import("./app-modules/client/client-profile/insurance/add-insurance"));
const InsuranceList = React.lazy(() => import("./app-modules/client/client-profile/insurance/list-insurance"));
const CreateQuestionnaire = React.lazy(() => import("./app-modules/client/client-profile/questionnaire/create-questionnaire"));
const Questionnaire = React.lazy(() => import("./app-modules/client/client-profile/questionnaire/questionnaire"));
const AddVital = React.lazy(() => import("./app-modules/client/client-profile/vitals/add-vital"));
const ClientVital = React.lazy(() => import("./app-modules/client/client-profile/vitals/list-vital"));
const ListClient = React.lazy(() => import("./app-modules/client/list-client"));
const AddPhysician = React.lazy(() => import("./app-modules/client/physician/add-physician"));
const Physician = React.lazy(() => import("./app-modules/client/physician/list-physician"));
const Eligibility = React.lazy(() => import("./app-modules/client/client-profile/eligibility/eligibility-grid-list"));
const ClientPrescription = React.lazy(() => import("./app-modules/client/ePrescription/clientEPrescription"));
const AddClinic = React.lazy(() => import("./app-modules/clinic/add-clinic"));
const CertificationSettings = React.lazy(() => import("./app-modules/clinic/certification-settings/certification-settings"));
const clientStatus = React.lazy(() => import("./app-modules/clinic/client-status/client-status-list"));
const ClinicFlags = React.lazy(() => import("./app-modules/clinic/clinic-flags/clinic-flags"));
const ClinicPayersHeader = React.lazy(() => import("./app-modules/clinic/clinic-payer/header"));
const ClinicTags = React.lazy(() => import("./app-modules/clinic/clinic-tags/clinic-tags"));
const DocumentSettings = React.lazy(() => import("./app-modules/clinic/document-settings/documents-settings"));
const ListClinic = React.lazy(() => import("./app-modules/clinic/list-clinic"));
const ReferringProvider = React.lazy(() => import("./app-modules/clinic/referring-provider/referring-list"));
const ReferringSource = React.lazy(() => import("./app-modules/clinic/referring-source/referring-source-list"));
const Roles = React.lazy(() => import("./app-modules/clinic/roles/index"));
const ClientCompliance = React.lazy(() => import("./app-modules/clinic/client-compliance/index"))
const MedicationList = React.lazy(() => import("./app-modules/client/client-profile/client-medication/list-medication"));
const ServiceAuthrization = React.lazy(() => import("./app-modules/clinic/service-auth/service-auth-listing"));
const AddSite = React.lazy(() => import("./app-modules/clinic/site/add-site"));
const ListSite = React.lazy(() => import("./app-modules/clinic/site/list-site"));
const ClinicInfo = React.lazy(() => import("./app-modules/clinic/upload-logo/upload-logo"));
const Dashboard = React.lazy(() => import("./app-modules/dashboard/dashboard"));
const AddDocument = React.lazy(() => import("./app-modules/documents/add-document"));
const EditDocument = React.lazy(() => import("./app-modules/documents/edit-document"));
const ListDocuments = React.lazy(() => import("./app-modules/documents/list-document"));
const ListDocumentDrafts = React.lazy(() => import("./app-modules/documents/list-document-draft"));
const DocumentHistory = React.lazy(() => import("./app-modules/documents/list-document-history"));
const AddDocumentTemplate = React.lazy(() => import("./app-modules/documents/template/add-document-template"));
const DocumentTemplateList = React.lazy(() => import("./app-modules/documents/template/list-document-template"));
const ListDocumentTemplateDrafts = React.lazy(() => import("./app-modules/documents/template/list-document-template-draft"));
const ViewDocument = React.lazy(() => import("./app-modules/documents/view-document"));
const EditedHistory = React.lazy(() => import("./app-modules/documents/view-edited-history"));
const ViewMultipleDocument = React.lazy(() => import("./app-modules/documents/view-multi-document"));
const LogIn = React.lazy(() => import("./app-modules/logIn/logIn"));
const Message = React.lazy(() => import("./app-modules/messages/messages"));
const NotFound = React.lazy(() => import("./app-modules/notFound/notFound"));
const NoteneticScheduler = React.lazy(() => import("./app-modules/scheduler/scheduler"));
const InternalServerError = React.lazy(() => import("./app-modules/server-error-page/server-error-page"));
const AddServices = React.lazy(() => import("./app-modules/service/add-service"));
const ServiceDetail = React.lazy(() => import("./app-modules/service/detail-service"));
const ServicesList = React.lazy(() => import("./app-modules/service/list-service"));
const AddStaff = React.lazy(() => import("./app-modules/staff/add-staff"));
const AssignServiceToStaff = React.lazy(() => import("./app-modules/staff/assign-services/header"));
const CaseloadHeader = React.lazy(() => import("./app-modules/staff/caseload/Header"));
const CertificateHeader = React.lazy(() => import("./app-modules/staff/certificate/certificateHeader"));
const DocumentTemplateHeader = React.lazy(() => import("./app-modules/staff/document-template/Header"));
const AssignDocumentTemplate = React.lazy(() => import("./app-modules/staff/document-template/assign-document-template"));
const ListStaff = React.lazy(() => import("./app-modules/staff/list-staff"));
const StaffPositionAndCredentials = React.lazy(() => import("./app-modules/staff/position-credential/index"));
const SettingHeader = React.lazy(() => import("./app-modules/staff/setting/settingHeader"));
const StaffSignature = React.lazy(() => import("./app-modules/staff/signature/staff-signature"));
const SitesHeader = React.lazy(() => import("./app-modules/staff/sites/Header"));
const EditStaff = React.lazy(() => import("./app-modules/staff/staff-profile/edit-staff"));
const StaffProfile = React.lazy(() => import("./app-modules/staff/staff-profile/staff-profile"));
const AddStaffStoredDocuments = React.lazy(() => import("./app-modules/staff/staff-files/add-staff-files"));
const EditStaffStoredDocuments = React.lazy(() => import("./app-modules/staff/staff-files/edit-staff-file"));
const StaffStoredDocuments = React.lazy(() => import("./app-modules/staff/staff-files/staff-files"));
const StaffTeamHeader = React.lazy(() => import("./app-modules/staff/staff-team/header"));
const TrackStaffTime = React.lazy(() => import("./app-modules/staff/track-staff/track-staff-time"));
const Task = React.lazy(() => import("./app-modules/taskManager/task"));
const TaskDiscussion = React.lazy(() => import("./app-modules/taskManager/task-discussion"));
const NewBilling = React.lazy(() => import("./app-modules/billing/new-billing-submission/list-billing-document"));
const BillingTransaction = React.lazy(() => import("./app-modules/billing/transaction/transaction-list"));
const BillingRemittance = React.lazy(() => import("./app-modules/billing/remittance/remittance-list"));
const BillingProfile = React.lazy(() => import("./app-modules/billing/billing-profile/billing-list"));
const BillingProfileAdd = React.lazy(() => import("./app-modules/billing/billing-profile/add-billing"));
const ImportClinic = React.lazy(() => import("./app-modules/clinic/import-clinic/import-clinic"))
// const MyForm = React.lazy(() => import("./app-modules/mui/mui-form"));
const ClaimPage = React.lazy(() => import("./app-modules/client/client-profile/billing-claims"));
const ClientDocuments = React.lazy(() => import("./app-modules/client/client-profile/documents/list-document"));
const AccessDenied = React.lazy(() => import("./app-modules/access/access-denied"));
const Compliance = React.lazy(() => import("./app-modules/client/client-profile/compliance/compliance-list"))
const DashBoardChartMainPage = React.lazy(() => import("./app-modules/dashboard/dashboardChart/charts/index.tsx"))
const DefaultGoalsMain = React.lazy(() => import("./app-modules/clinic/default-goals"))

const AddNewDefaultGoals = React.lazy(() => import("./app-modules/clinic/default-goals/add-new-goals"))
const ViewGoalPage = React.lazy(() => import("./app-modules/clinic/default-goals/goal-view-page"))
const ServiceSetsMain = React.lazy(() => import("./app-modules/clinic/service-sets"))
const TreatmentPlanTemplates = React.lazy(() => import('./app-modules/clinic/default-goals/view-treatment-plan-templates'))
const ClinicFile = React.lazy(() => import("./app-modules/clinic/clinic-file"))
const AddClinicDocuments = React.lazy(() => import("./app-modules/clinic/clinic-file/clinic-add-file"))
const EditClinicStoredDocuments = React.lazy(() => import("./app-modules/clinic/clinic-file/edit-clinic-file"))
const PayRatesHeader = React.lazy(() => import('./app-modules/staff/pay-rates/payratesheader'));
const GoalsHeader = React.lazy(() => import('./app-modules/staff/goals/goalsHeader'));
// const NotificationSettingHeader = React.lazy(() => import('./app-modules/staff/staff-notification/notification-header'));
const ClinicNotification = React.lazy(() => import('./app-modules/clinic/clinic-notification'));
const TreatmentPlanHeader = React.lazy(() => import('../src/app-modules/clinic/treatmentPlan-setting/header.tsx'))
const ClinicSkills = React.lazy(() => import('./app-modules/clinic/clinic-skills/clinic-skills'));
const StaffDocuments = React.lazy(() => import('./app-modules/staff/documents'));
const AddStaffDocuments = React.lazy(() => import('./app-modules/staff/documents/create-staff-doc.tsx'));
const ViewStaffDocuments = React.lazy(() => import('./app-modules/staff/documents/view-document.tsx'));
const calendarSettings = React.lazy(() => import("./app-modules/clinic/calendar-setting/calendar-settings"));
const ServiceRules = React.lazy(() => import("./app-modules/service/service-rules/service-rule-index"));
const SuperBill = React.lazy(() => import("./app-modules/client/client-profile/documents/super-bill/index"));
const AppointmentReminders = React.lazy(() => import("./app-modules/clinic/text-reminders/index"));
const ClientLedger = React.lazy(() => import("./app-modules/client/client-profile/client-ledger"))
const AddClientLedgerRecord = React.lazy(() => import("./app-modules/client/client-profile/client-ledger/add"))
const EditClientLedgerRecord = React.lazy(() => import("./app-modules/client/client-profile/client-ledger/edit"))
const ClientPortalAccountAccess = React.lazy(() => import("./app-modules/client-portal-account-access/index"));
const AddNewAccountAccess = React.lazy(() => import("./app-modules/client-portal-account-access/add-edit-account"));
const SocialDeterminantsHistory = React.lazy(() => import("./app-modules/client/client-profile/background/social determinants/social-determinants-history"));
const AddCollateralContact = React.lazy(() => import("./app-modules/client/client-profile/guardian-parent/add-collateral-contact"));
const SubsCriptionsManager = React.lazy(() => import("./app-modules/subscription-manager/index"))
const SubscriptionByPass = React.lazy(() => import("./app-modules/subscription-manager/subscriptions-pay"));
const StaffServiceReport = React.lazy(() => import("./app-modules/staff/staff-service-report/staff-service-report"));
const TreatmentPlanAuditLog = React.lazy(() => import("../src/app-modules/client/client-profile/treatment-plan/treatment-plan-auditLog"));


const PrivateRoutes = ({ token }) => {
  const userAccessPermission = useSelector((state) => state.userAccessPermission);
  const staffLoginInfo = useSelector((state) => state.getStaffReducer);
  const isBillingManager = useSelector((state) => state.getRolePermission.isBillingManager);
  const state = useSelector(states => {
    return states;
  });
  const { loadingState, loadingStateDocList } = state['LoaderReducer'];
  const canManageReports = state.getRolePermission.canManageReports

  return (
    <div>
      {token && (
        <Layout >

          {(staffLoginInfo && staffLoginInfo.id) ?
            <Routes>
              {/* <Route path="*" element={<h1 > 404 no url found  </h1>} /> */}

              {/* --------------------------- Add Clinic ------------------------------- */}


              <Route
                path={AppRoutes.DASHBOARD_CHART}
                element={<Protected Component={DashBoardChartMainPage} />}
              />
              {
                (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                <Route path={AppRoutes.BILLING_PROFILE}
                  element={<Protected Component={BillingProfile} />}
                />
              }
              {
                (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                <Route path={AppRoutes.BILLING_PROFILE_ADD}
                  element={<Protected Component={BillingProfileAdd} />}
                />
              }  {
                (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                <Route path={AppRoutes.BILLING_PROFILE_EDIT}
                  element={<Protected Component={BillingProfileAdd} />}
                />
              }

              {
                (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                <Route
                  path={AppRoutes.NEW_BILLING}
                  element={<Protected Component={NewBilling} />}
                />
              }

              {
                (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                <Route
                  path={AppRoutes.BILLING_TRANSACTION}
                  element={<Protected Component={BillingTransaction} />}
                />}

              {
                (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) &&
                <Route
                  path={AppRoutes.BILLING_REMITTANCE}
                  element={<Protected Component={BillingRemittance} />}
                />
              }

              {/* ---------------------Claim page  */}

              <Route
                path={AppRoutes.BILLING_CLAIMS}
                element={<Protected Component={ClaimPage} />}
              />


              <Route
                path={AppRoutes.ADD_CLINIC}
                element={<Protected Component={AddClinic} />}
              />

              <Route
                path={AppRoutes.TIMECLOCK}
                element={<Protected Component={TimeClock} />}
              />

              <Route
                path={AppRoutes.KNOWLEDGE_BASE}
                element={<Protected Component={KnowledgeBase} />}
              />

              <Route
                path={AppRoutes.GENERATE_TIMECLOCK_REPORT}
                element={<Protected Component={GenerateTimeClockReport} />}
              />

              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.REF_PROVIDER_SETTINGS}
                  element={<Protected Component={ReferringProvider} />}
                />
              )}

              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.CLINIC_INFO}
                  element={<Protected Component={ClinicInfo} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.DOCUMENT_SETTINGS}
                  element={<Protected Component={DocumentSettings} />}
                />
              )}





              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.CALENDAR_SETTINGS}
                  element={<Protected Component={calendarSettings} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.CERTIFICATION_SETTINGS}
                  element={<Protected Component={CertificationSettings} />}
                />
              )}
              {
                <Route
                  path={AppRoutes.MEDICATION}
                  element={<Protected Component={MedicationList} />}
                />
              }
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.CLINIC_FLAGS}
                  element={<Protected Component={ClinicFlags} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.CLINIC_TAGS}
                  element={<Protected Component={ClinicTags} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.REF_SOURCE_SETTINGS}
                  element={<Protected Component={ReferringSource} />}
                />
              )}
              {/* <Route
                path={AppRoutes.MUI_FORM}
                element={<Protected Component={MyForm} />}
              /> */}
              <Route
                path={AppRoutes.ADD_TREATMENT_PLAN}
                element={<Protected Component={TreatmentPlan} />}
              />
              <Route
                path={AppRoutes.TASK_DISCUSSION}
                element={<Protected Component={TaskDiscussion} />}
              />

              {userAccessPermission[permissionEnum.MANAGE_CLIENT_SERVICES] && (
                <Route
                  path={AppRoutes.ASSIGN_SERVICE_TO_CLIENT}
                  element={<Protected Component={AssignServiceToClient} />}
                />
              )}

              <Route
                path={AppRoutes.UPDATE_CLINIC}
                element={<Protected Component={AddClinic} />}
              />
              <Route
                path={AppRoutes.GET_CLINIC}
                element={<Protected Component={ListClinic} />}
              />

              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.ADD_CLINIC_SITE}
                  element={<Protected Component={AddSite} />}
                />
              )}

              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.GET_CLINIC_SITE}
                  element={<Protected Component={ListSite} />}
                />
              )}
              <Route
                path={AppRoutes.UPDATE_SITE}
                element={<Protected Component={AddSite} />}
              />

              {userAccessPermission[permissionEnum.EDIT_CLIENT_PROFILE] && (
                <Route
                  path={AppRoutes.ADD_CLIENT_VITAL}
                  element={<Protected Component={AddVital} />}
                />
              )}

              <Route
                path={AppRoutes.GET_CLIENT_VITAL}
                element={<Protected Component={ClientVital} />}
              />

              <Route
                path={AppRoutes.ASSIGN_STAFF_TO_CLIENT}
                element={<Protected Component={AssignStaffToClient} />}
              />


              {userAccessPermission[permissionEnum.MANAGE_AUTHORIZATIONS] && (
                <Route
                  path={AppRoutes.MULTIPLE_CLIENT_SERVICE_AUTH}
                  element={<Protected Component={ServiceAuthrization} />}
                />
              )}

              {userAccessPermission[permissionEnum.MANAGE_AUTHORIZATIONS] && (
                <Route
                  path={AppRoutes.ADD_MULTIPLE_CLIENT_AUTH}
                  element={<Protected Component={AddMultipleClientAuth} />}
                />
              )}

              {userAccessPermission[permissionEnum.MANAGE_AUTHORIZATIONS] && (
                <Route
                  path={AppRoutes.EDIT_MULTIPLE_CLIENT_AUTH}
                  element={<Protected Component={EditMultipleClientAuth} />}
                />
              )}
              {userAccessPermission[permissionEnum.MANAGE_AUTHORIZATIONS] && (
                <Route
                  path={AppRoutes.CLINIC_NOTIFICATION}
                  element={<Protected Component={ClinicNotification} />}
                />
              )}

              {/* -------------------------------Task manager---------------------------*/}
              <Route
                path={AppRoutes.TASK_MANAGER}
                element={<Protected Component={Task} />}
              />

              {/* -------------------------------Report manager---------------------------*/}
              <Route
                path={AppRoutes.REPORT_MANAGER}
                element={<Protected Component={ReportManager} />}
              />

              {/* --------------------------- Add Staff ------------------------------- */}

              {userAccessPermission[permissionEnum.ADD_STAFF] && (
                <Route
                  path={AppRoutes.ADD_STAFF}
                  element={<Protected Component={AddStaff} />}
                />
              )}

              <Route
                path={AppRoutes.GET_STAFF}
                element={<Protected Component={ListStaff} />}
              />

              <Route
                path={AppRoutes.TRACK_STAFF_TIME}
                element={<Protected Component={TrackStaffTime} />}
              />

              <Route
                path={AppRoutes.EDIT_STAFF_BY_ID}
                element={<Protected Component={AddStaff} />}
              />

              <Route
                path={AppRoutes.STAFF_PROFILE}
                element={<Protected Component={StaffProfile} />}
              />
              <Route
                path={AppRoutes.STAFF_SIGNATURE}
                element={<Protected Component={StaffSignature} />}
              />

              {((userAccessPermission[permissionEnum.EDIT_STAFF_PROFILE]) || (userAccessPermission[permissionEnum.EDIT_DEMOGRAPHICS])) && (
                <Route
                  path={AppRoutes.EDIT_STAFF}
                  element={<Protected Component={EditStaff} />}
                />
              )}

              <Route
                path={AppRoutes.STAFF_POSITION_CREDENTIALS}
                element={<Protected Component={StaffPositionAndCredentials} />}
              />
              <Route
                path={AppRoutes.STAFF_CASELOAD}
                element={<Protected Component={CaseloadHeader} />}
              />
              <Route
                path={AppRoutes.DOCUMENT_TEMPLATE_STAFF}
                element={<Protected Component={DocumentTemplateHeader} />}
              />
              <Route
                path={AppRoutes.ASSIGN_SERVICE_TO_STAFF}
                element={<Protected Component={AssignServiceToStaff} />}
              />
              <Route
                path={AppRoutes.STAFF_SITES}
                element={<Protected Component={SitesHeader} />}
              />
              <Route
                path={AppRoutes.STAFF_CERTIFICATE}
                element={<Protected Component={CertificateHeader} />}
              />
              <Route
                path={AppRoutes.STAFF_PAY}
                element={<Protected Component={PayRatesHeader} />}
              />
              <Route
                path={AppRoutes.STAFF_GOAL}
                element={<Protected Component={GoalsHeader} />}
              />
              <Route
                path={AppRoutes.STAFF_STORED_DOCUMENTS}
                element={<Protected Component={StaffStoredDocuments} />}
              />
              {userAccessPermission[permissionEnum.MANAGE_STAFF_FILES] && (
                <Route
                  path={AppRoutes.STAFF_ADD_STORED_DOCUMENT}
                  element={<Protected Component={AddStaffStoredDocuments} />}
                />
              )}

              {userAccessPermission[permissionEnum.MANAGE_STAFF_FILES] && (
                <Route
                  path={AppRoutes.STAFF_EDIT_STORED_DOCUMENT}
                  element={<Protected Component={EditStaffStoredDocuments} />}
                />
              )}
              {canManageReports && (
                <Route
                  path={AppRoutes.ASSIGN_STAFF_REPORT}
                  element={<Protected Component={AssignStaffReportHeader} />}
                />
              )}
              <Route
                path={AppRoutes.MAP}
                element={<Protected Component={MapHeader} />}
              />
              {(userIsSuperAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.SHOW_CLINIC_FILES]) && (
                <Route
                  path={AppRoutes.CLINIC_EDIT_STORED_DOCUMENT}
                  element={<Protected Component={EditClinicStoredDocuments} />}
                />
              )}

              <Route
                path={AppRoutes.STAFF_SETTING}
                element={<Protected Component={SettingHeader} />}
              />

              <Route
                path={AppRoutes.DOCUMENT_TEMPLATE_STAFF}
                element={<Protected Component={AssignDocumentTemplate} />}
              />
              <Route
                path={AppRoutes.STAFF_TEAM}
                element={<Protected Component={StaffTeamHeader} />}
              />
              {/* <Route
                path={AppRoutes.STAFF_NOTIFICATION_SETTING}
                element={<Protected Component={NotificationSettingHeader} />}
              /> */}

              {/* --------------------------- Dashbaord ------------------------------- */}
              <Route
                path={AppRoutes.DASHBOARD}
                element={<Protected Component={Dashboard} />}
              />

              {/* --------------------------- Add Client ------------------------------- */}

              {userAccessPermission[permissionEnum.ADD_CLIENTS] && (
                <Route
                  path={AppRoutes.ADD_CLIENT}
                  element={<Protected Component={AddClient} />}
                />
              )}

              <Route
                path={AppRoutes.GET_CLIENT}
                element={<Protected Component={ListClient} />}
              />

              <Route
                path={AppRoutes.CLIENT_DASHBOARD}
                element={<Protected Component={ClientDashboard} />}
              />

              <Route
                path={AppRoutes.CLIENT_SIGNATURE}
                element={<Protected Component={ClientSignature} />}
              />
              {userAccessPermission[permissionEnum.EDIT_CLIENT_PROFILE] && (
                <Route
                  path={AppRoutes.ADD_CLIENT_SIGNATURE}
                  element={<Protected Component={AddClientSignature} />}
                />
              )}

              {userAccessPermission[permissionEnum.EDIT_CLIENT_PROFILE] && (
                <Route
                  path={AppRoutes.ADD_PARENT_SIGNATURE}
                  element={<Protected Component={AddParentSignature} />}
                />
              )}

              <Route
                path={AppRoutes.AUTHORIZATION_LIST}
                element={<Protected Component={ListAuthorization} />}
              />

              {userAccessPermission[permissionEnum.MANAGE_AUTHORIZATIONS] && (
                <Route
                  path={AppRoutes.AUTHORIZATION_ADD}
                  element={<Protected Component={AddAuthorization} />}
                />
              )}

              {userAccessPermission[permissionEnum.MANAGE_AUTHORIZATIONS] && (
                <Route
                  path={AppRoutes.AUTHORIZATION_EDIT}
                  element={<Protected Component={EditAuthorization} />}
                />
              )}

              <Route
                path={AppRoutes.DIAGNOSIS}
                element={<Protected Component={Diagnosis} />}
              />
              <Route
                path={AppRoutes.INSURANCE}
                element={<Protected Component={Insurance} />}
              />

              {userAccessPermission[permissionEnum.MANAGE_CLIENT_FILES] && (
                <Route
                  path={AppRoutes.ADD_STORED_DOCUMENTS}
                  element={<Protected Component={AddStoredDocuments} />}
                />
              )}

              <Route
                path={AppRoutes.STORED_DOCUMENTS}
                element={<Protected Component={StoredDocuments} />}
              />

              {userAccessPermission[permissionEnum.MANAGE_CLIENT_FILES] && (
                <Route
                  path={AppRoutes.EDIT_STRORED_DOCUMENT}
                  element={<Protected Component={EditStoredDocuments} />}
                />
              )}

              <Route
                path={AppRoutes.CLIENT_CONTACT_NOTES}
                element={<Protected Component={ContactNotesList} />}
              />
              <Route
                path={AppRoutes.CLIENT_BACKGROUND}
                element={<Protected Component={BackgroundList} />}
              />
              <Route
                path={AppRoutes.CLIENT_GUARDIAN_LIST}
                element={<Protected Component={GuardianParent} />}
              />
              <Route
                path={AppRoutes.CLIENT_PHYSICIAN_LIST}
                element={<Protected Component={Physician} />}
              />

              <Route
                path={AppRoutes.CLIENT_ELIGIBILITY}
                element={<Protected Component={Eligibility} />}
              />
              <Route
                path={AppRoutes.CLIENT_E_PRESCRIPTION}
                element={<Protected Component={ClientPrescription} />}
              />

              {userAccessPermission[permissionEnum.EDIT_CLIENT_PROFILE] && (
                <Route
                  path={AppRoutes.CLIENT_PHYSICIAN_ADD}
                  element={<Protected Component={AddPhysician} />}
                />
              )}

              <Route
                path={AppRoutes.CLIENT_IMMUNIZATION}
                element={<Protected Component={ImmunizationList} />}
              />

              {userAccessPermission[permissionEnum.EDIT_CLIENT_PROFILE] && (
                <Route
                  path={AppRoutes.ADD_IMMUNIZATION}
                  element={<Protected Component={AddImmunization} />}
                />
              )}

              {userAccessPermission[permissionEnum.EDIT_CLIENT_PROFILE] && (
                <Route
                  path={AppRoutes.EDIT_IMMUNIZATION}
                  element={<Protected Component={EditImmunization} />}
                />
              )}

              <Route
                path={AppRoutes.DETAIL_IMMUNIZATION}
                element={<Protected Component={ImmunizationDetails} />}
              />

              {userAccessPermission[permissionEnum.EDIT_CLIENT_PROFILE] && (
                <Route
                  path={AppRoutes.CLIENT_GUARDIAN_ADD}
                  element={<Protected Component={AddGuardian} />}
                />
              )}

              {userAccessPermission[
                permissionEnum.DISCHARGE_REACTIVATE_CLIENT
              ] && (
                  <Route
                    path={AppRoutes.CLIENT_DISCHARGE}
                    element={<Protected Component={Discharge} />}
                  />
                )}
              <Route
                path={AppRoutes.QUESTIONNAIRE}
                element={<Protected Component={Questionnaire} />}
              />
              <Route
                path={AppRoutes.CREATE_QUESTIONNAIRE}
                element={<Protected Component={CreateQuestionnaire} />}
              />
              <Route
                path={AppRoutes.PRINT_QUESTIONNAIRE}
                element={<Protected Component={PrintQuestionnaire} />}
              />
              <Route
                path={AppRoutes.COMPLIANCE}
                element={<Protected Component={Compliance} />}
              />
              {userAccessPermission[permissionEnum.MANAGE_CLIENT_SERVICES] && (
                <Route
                  path={AppRoutes.MIN_SERVICE_REPORT}
                  element={<Protected Component={MinServiceReport} />}
                />
              )}

              {(userIsSuperAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.SHOW_CLINIC_FILES]) && (
                <Route
                  path={AppRoutes.CLINIC_FILE}
                  element={<Protected Component={ClinicFile} />}
                />
              )}

              {(userIsSuperAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.SHOW_CLINIC_FILES]) && (
                <Route
                  path={AppRoutes.ADD_CLINIC_FILE}
                  element={<Protected Component={AddClinicDocuments} />}
                />
              )}
              {/* --------------------------- Scheduler ------------------------------- */}
              <Route
                path={AppRoutes.SCHEDULER}
                element={<Protected Component={NoteneticScheduler} />}
              />

              {/* --------------------------- Add Insurance ------------------------------- */}
              <Route
                path={AppRoutes.ADD_INSURANCE}
                element={<Protected Component={AddInsurance} />}
              />
              <Route
                path={AppRoutes.GET_INSURANCE_LIST}
                element={<Protected Component={InsuranceList} />}
              />
              <Route
                path={AppRoutes.EDIT_INSURANCE_BY_ID}
                element={<Protected Component={AddStaff} />}
              />
              {(userIsAdmin(staffLoginInfo?.roleId) || userIsQA(staffLoginInfo?.roleId)) && (
                <Route
                  path={AppRoutes.ADD_CLIENT_INSURANCE}
                  element={<Protected Component={AddCPInsurance} />}
                />
              )}

              {(userIsAdmin(staffLoginInfo?.roleId) || userIsQA(staffLoginInfo?.roleId)) && (
                <Route
                  path={AppRoutes.EDIT_CLIENT_INSURANCE}
                  element={<Protected Component={EditInsurance} />}
                />
              )}

              {/* --------------------------- Add Service Manager ------------------------------- */}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.ADD_Services}
                  element={<Protected Component={AddServices} />}
                />
              )}

              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.GET_SERVICE_BY_CLINICID}
                  element={<Protected Component={ServicesList} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.EDIT_Services_BY_ID}
                  element={<Protected Component={AddServices} />}
                />
              )}

              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.GET_Services_BY_ID}
                  element={<Protected Component={ServiceDetail} />}
                />
              )}

              <Route
                path={AppRoutes.UPDATE_INSURANCE}
                element={<Protected Component={AddStaff} />}
              />

              {/* <Route
                     path={AppRoutes.ADD_CLIENT_INSURANCE}
                     element={<Protected Component={AddClientInsurance} />}
                 /> */}
              <Route
                path={AppRoutes.GET_INSURANCE_LIST}
                element={<Protected Component={InsuranceList} />}
              />
              <Route
                path={AppRoutes.UPDATE_INSURANCE}
                element={<Protected Component={AddStaff} />}
              />

              {/*-------------------MESSAGE ----------------*/}
              <Route
                path={AppRoutes.MESSAGE}
                element={<Protected Component={Message} />}
              />

              {/* --------------------------- Roles ------------------------------- */}
              <Route
                path={AppRoutes.ROLES}
                element={<Protected Component={Roles} />}
              />
              {/* --------------------------- Roles ------------------------------- */}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.PRESCRIPTIONS}
                  element={<Protected Component={EPrescription} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.ADD_PRESCRIPTIONS}
                  element={<Protected Component={AddNewClinician} />}
                />
              )}

              {/*------------------- Document Templates ----------------*/}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.DOCUMENT_TEMPLATE_LIST}
                  element={<Protected Component={DocumentTemplateList} />}
                />
              )}

              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.DOCUMENT_TEMPLATE_VIEW}
                  element={<Protected Component={ViewDocumentTemplate} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.DOCUMENT_TEMPLATE_ADD}
                  element={<Protected Component={AddDocumentTemplate} />}
                />
              )}

              {/*------------------- Document Template Drafts ----------------*/}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.DOCUMENT_TEMPLATE_DRAFT_LIST}
                  element={<Protected Component={ListDocumentTemplateDrafts} />}
                />
              )}

              {/*------------------- Documents ----------------*/}
              <Route
                path={AppRoutes.DOCUMENT_LIST}
                element={<Protected Component={ListDocuments} />}
              />
              <Route
                path={AppRoutes.CLIENT_DOCUMENTS}
                element={<Protected Component={ClientDocuments} />}
              />
              <Route
                path={AppRoutes.DOCUMENT_HISTORY}
                element={<Protected Component={DocumentHistory} />}
              />
              <Route
                path={AppRoutes.VIEW_DOCUMENT_EDIT_HISTORY}
                element={<Protected Component={EditedHistory} />}
              />
              <Route
                path={AppRoutes.DOCUMENT_VIEW}
                element={<Protected Component={ViewDocument} />}
              />
              <Route
                path={AppRoutes.DOCUMENT_PRINT}
                element={<Protected Component={PrintDocument} />}
              />


              <Route
                path={AppRoutes.DOCUMENT_VIEW_PARAM}
                element={<Protected Component={ViewDocument} />}
              />
              <Route
                path={AppRoutes.DOCUMENT_MULTI_VIEW}
                element={<Protected Component={ViewMultipleDocument} />}
              />
              <Route
                path={AppRoutes.DOCUMENT_ADD}
                element={<Protected Component={AddDocument} />}
              />
              <Route
                path={AppRoutes.DOCUMENT_EDIT}
                element={<Protected Component={EditDocument} />}
              />
              <Route
                path={AppRoutes.DOCUMENT_DRAFT_LIST}
                element={<Protected Component={ListDocumentDrafts} />}
              />
              {/* -------------------------Clinic || Audit--------------------- */}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.STAFF_AUDIT}
                  element={<Protected Component={StaffAudit} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.CLIENT_AUDIT}
                  element={<Protected Component={ClientAudit} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.TELEHEALTH_LOGS}
                  element={<Protected Component={TelehealthLogs} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.TELEHEALTH_LOGS_DETAILS}
                  element={<Protected Component={TelehealthLogsDetails} />}
                />
              )}
              <Route
                path={AppRoutes.CLINIC_AUDIT}
                element={<Protected Component={ClinicAudit} />}
              />
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.CLIENT_STATUS}
                  element={<Protected Component={clientStatus} />}
                />
              )}
              {userIsAdmin(staffLoginInfo?.roleId) && (

                <Route
                  path={AppRoutes.TREATMENT_PLAN_SETTING}
                  element={<Protected Component={TreatmentPlanHeader} />}
                />
              )}

              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.CLINIC_PAYERS}
                  element={<Protected Component={ClinicPayersHeader} />}
                />
              )}


              {(userIsSuperAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.MANAGE_DEFAULT_GOALS]) && (
                <Route
                  path={AppRoutes.DEFAULT_GOLAS}
                  element={<Protected Component={DefaultGoalsMain} />}
                />
              )}

              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.SERVICE_SETS}
                  element={<Protected Component={ServiceSetsMain} />}
                />
              )}

              <Route
                path={AppRoutes.ADD_NEW_DEFAULT_GOALS}
                element={<Protected Component={AddNewDefaultGoals} />}
              />
              <Route
                path={AppRoutes.VIEW_GOAL_PAGE}
                element={<Protected Component={ViewGoalPage} />}
              />
              {userIsAdmin(staffLoginInfo?.roleId) && (
                <Route
                  path={AppRoutes.IMPORT_CLINIC}
                  element={<Protected Component={ImportClinic} />}
                />
              )}
              {(userIsAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.MANAGE_CLIENT_COMPLIANCE]) && (
                <Route
                  path={AppRoutes.CLIENT_COMPLIANCE}
                  element={<Protected Component={ClientCompliance} />}
                />
              )}
              {(userIsAdmin(staffLoginInfo?.roleId) || userAccessPermission[permissionEnum.MANAGE_CLIENT_COMPLIANCE]) && (
                <Route
                  path={AppRoutes.COMPLIANCE_REPORT}
                  element={<Protected Component={ClientComplianceReport} />}
                />
              )}
              <Route
                path={AppRoutes.VIEW_TREATMENT_PLAN_TEMPLATES}
                element={<Protected Component={TreatmentPlanTemplates} />}
              />
              <Route
                path={AppRoutes.CLINIC_SKILLS}
                element={<Protected Component={ClinicSkills} />}
              />


              {/*------------------- Not Found ----------------*/}

              <Route path="*" element={<Protected Component={AccessDenied} />} />

              <Route
                path={AppRoutes.INTERNAL_SERVER_ERROR}
                element={<Protected Component={InternalServerError} />}
              />
              <Route
                path={AppRoutes.AUTHORIZATION_SETS}
                element={<Protected Component={ClinicAuthSets} />}
              />
              <Route
                path={AppRoutes.LAST_DOC_VIEW}
                element={<Protected Component={LatestDocumentId} />}
              />

              <Route
                path={AppRoutes.STAFF_DOCUMENTS}
                element={<Protected Component={StaffDocuments} />}
              />

              <Route
                path={AppRoutes.CREATE_STAFF_DOCUMENTS}
                element={<Protected Component={AddStaffDocuments} />}
              />

              <Route
                path={AppRoutes.VIEW_STAFF_DOCUMENTS}
                element={<Protected Component={ViewStaffDocuments} />}
              />

              <Route
                path={AppRoutes.STAFF_DOCUMENT_TEMPLATE}
                element={<Protected Component={StaffDocumentTemplateList} />}
              />

              <Route
                path={AppRoutes.SERVICE_RULES}
                element={<Protected Component={ServiceRules} />}
              />

              <Route
                path={AppRoutes.SUPER_BILL}
                element={<Protected Component={SuperBill} />}
              />

              <Route
                path={AppRoutes.CLIENT_LEDGER}
                element={<Protected Component={ClientLedger} />}
              />

              <Route
                path={AppRoutes.ADD_CLIENT_LEDGER}
                element={<Protected Component={AddClientLedgerRecord} />}
              />

              <Route
                path={AppRoutes.EDIT_CLIENT_LEDGER}
                element={<Protected Component={EditClientLedgerRecord} />}
              />
              <Route
                path={AppRoutes.APPOINTMENT_REMINDERS}
                element={<Protected Component={AppointmentReminders} />}
              />

              <Route
                path={AppRoutes.CLIENT_PORTAL_ACCESS}
                element={<Protected Component={ClientPortalAccountAccess} />}
              />

              <Route
                path={AppRoutes.ADD_CLIENT_PORTAL_ACCESS}
                element={<Protected Component={AddNewAccountAccess} />}
              />

              <Route
                path={AppRoutes.STAFF_DOCUMENT_PRINT}
                element={<Protected Component={StaffDocPdf} />}
              />

              <Route
                path={AppRoutes.VIEW_SOCIAL_DETERMINANTS_HISTORY}
                element={<Protected Component={SocialDeterminantsHistory} />}
              />

              <Route
                path={AppRoutes.CLIENT_COLLATERALCONTACT_ADD}
                element={<Protected Component={AddCollateralContact} />}
              />

              {(userIsSuperAdmin(staffLoginInfo?.roleId)) && (
                <Route
                  path={AppRoutes.SUBSCRIPTION_MANAGER}
                  element={<Protected Component={SubsCriptionsManager} />}
                />
              )}
              <Route
                path={AppRoutes.STAFF_SERVICE_REPORT}
                element={<Protected Component={StaffServiceReport} />}
              />
               <Route
                path={AppRoutes.TREATMENT_AUDIT_LOG}
                element={<Protected Component={TreatmentPlanAuditLog} />}
              />

            </Routes> : <></>
          }
          {(loadingState === true) && <Loader />}
        </Layout>
      )}
    </div>
  );
};
const MainAppContent = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const ClearCacheComponent = withClearCache(MainApp);
  const [isSeesionChecked, setIsSessionChecked] = useState(false)
  const [isfromClientApp, setIsFromClientApp] = useState(location.pathname === "/impersonate")
  const [searchParams, setSearchParams] = useSearchParams();
  let token = localStorage.getItem(DEVELOPMENT_CONFIG.TOKEN);
  localStorage.setItem(DEVELOPMENT_CONFIG.IS_IMPERSONATE, "false");
  const dispatch = useDispatch();
  const state = useSelector(states => {
    return states;
  });
  const { redirectedDocID, newAppRoute } = state['LoggedInReducer'];
  const isAdminViewDocFileTemplateRoute = matchPath(
    { path: AppRoutes.ADMIN_VIEW_DOCUMENT_FILE_TEMPLATE, end: true },
    location.pathname
  );

  useEffect(() => {
    if (token && newAppRoute && newAppRoute != null) {
      navigate(newAppRoute)
      dispatch(changeAppRoute())
    }
  }, [newAppRoute])

  useEffect(() => {
    if (window.location.href.includes('document/view')) {
      var res = window.location.href.split("/");
      var pos = res.indexOf('view');
      var result = res[pos + 1];
      const actualDocId = telehealthDecrption(result)
      if (actualDocId && actualDocId != null && actualDocId > 0) {
        dispatch(saveEncryptedRedirectDocId(actualDocId))
      }
    }

    if ((location.pathname === "/impersonate")) {
      localStorage.clear()
      localStorage.setItem(DEVELOPMENT_CONFIG.IS_IMPERSONATE, "true");
      dispatch(setAppImpersonateFlag(true))
    } else {
      dispatch(setAppImpersonateFlag(false))
    }

    if (location.pathname === "/login") {
      navigate("/login")
    }
    else if (!localStorage.getItem(DEVELOPMENT_CONFIG.TOKEN) && location.pathname !== AppRoutes.TELEHEALTH
      && !isAdminViewDocFileTemplateRoute) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (!isSeesionChecked) {
      if (isAdminViewDocFileTemplateRoute) {
        checkUserSessionFromAdmin();
      }
      else if (isfromClientApp) {
        checkUserSessionFromClient()
      }
      else if (token && token.length > 0) {
        checkUserSession()
      } else {
        setIsSessionChecked(true)
      }
    }
  }, [isSeesionChecked])

  useEffect(() => {
    if (isSeesionChecked && redirectedDocID > 0 && token && token.length) {
      navigate(APP_ROUTES.DOCUMENT_VIEW, {
        state: {
          id: redirectedDocID,
          backRoute: APP_ROUTES.DOCUMENT_LIST,
          isActiveCheck: false,
          // showClinicLogo:showClinicLogo
        },
      });
      dispatch(saveEncryptedRedirectDocId(0))
    }
  }, [redirectedDocID, isSeesionChecked, token])

  function searchToObject() {
    var pairs = location.search.substring(1).split("&"),
      obj = {},
      pair,
      i;

    for (i in pairs) {
      if (pairs[i] === "") continue;

      pair = pairs[i].split("=");
      obj[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    }

    return obj;
  }

  function checkUserSessionFromAdmin() {
    if (searchParams.has("templateName")) {
      const obj = searchToObject();
      const state = { state: { templateName: obj.templateName } };
      navigate(AppRoutes.ADMIN_VIEW_DOCUMENT_FILE_TEMPLATE, state)
      setIsSessionChecked(true)
    }
  }

  async function checkUserSessionFromClient() {
    localStorage.setItem(DEVELOPMENT_CONFIG.IS_IMPERSONATE, "false");
    if ((searchParams.has("docId") && searchParams.has("token")) || (searchParams.has("token") && searchParams.has("SessionOrigin"))) {
      var obj = searchToObject();
      let sessionResp = null;
      await ApiHelper.getRequest(`${API_ENDPOINTS.GET_BYPASS_TOKEN}?token=${encodeURIComponent(obj.token)}&sessionOrigin=${searchParams.get("SessionOrigin")}`)
        .then((response) => {
          sessionResp = response.resultData;
          if (!sessionResp || !response || !response.status) {
            token = undefined
            localStorage.clear();
            window.location.href = "/login";
          } else {
            if (searchParams.get("SessionOrigin") === "ClientPortal") {
              if(searchParams.get('queId') !== "null" && searchParams.get('queId') !== "undefined" && searchParams.get('queId') !== '') {
                token = sessionResp.token
                navigate(AppRoutes.UPDATE_QUESTIONNAIRE_FROM_PORTAL, { state: {  field: {
                  id: obj.id,
                  enumId: obj.enumId,
                  questionaireId: obj.questionnaireId,
                  questionnaire: obj.questionnaire,
                  token: sessionResp.token,
                  clientId: sessionResp.clientGuid,
                  isFromCP: obj.isUpdateQue === "updateQue"
                } }                             
              })                
              }
              else {
                token = sessionResp.token
                navigate(AppRoutes.CALL_BY_CLIENT, { state: { docId: obj.docId, token: token, isUpdate: obj.isUpdate } })
              }
            } else {
              //STAFF IMPERSONATE
              dispatch(loginUser(sessionResp))
              dispatch(roleModeAction(sessionResp.roleId));
              dispatch(staffSettingsAction(sessionResp.staffId));
              dispatch(setAppImpersonateFlag(true))
              localStorage.setItem(DEVELOPMENT_CONFIG.IS_IMPERSONATE, "true");
              localStorage.setItem(DEVELOPMENT_CONFIG.TOKEN, sessionResp.token);
              localStorage.setItem(DEVELOPMENT_CONFIG.REFRESH_TOKEN, sessionResp.refreshToken);
              localStorage.setItem(DEVELOPMENT_CONFIG.LOGIN_TIME, moment().format());
              localStorage.setItem(DEVELOPMENT_CONFIG.TOKEN_EXPIRETIME, moment().add(sessionResp.tokenExpireIn, "seconds").format());
              localStorage.setItem(DEVELOPMENT_CONFIG.REFRESHTOKEN_EXPIRETIME, moment().add(sessionResp.refreshTokenExpireIn, "seconds").format());
              <Navigate to={APP_ROUTES.DASHBOARD} />
            }
          }
        })
        .catch((error) => {
          renderErrors(error)
        });

      // const sessionResp = await customAxios.
      //   get(`${API_ENDPOINTS.GET_BYPASS_TOKEN}?token=${encodeURIComponent(obj.token)}&sessionOrigin=${searchParams.get("SessionOrigin")}`)
      //   .then(function (response) {
      //     debugger;
      //   }).catch(function (error) {
      //     debugger;
      //   });
      // debugger;

    }
    setIsSessionChecked(true)
  }

  if (location.pathname === "/") {

    if (token === null || "") {
      return <Navigate to={"/logIn"} />;
    }
    if (token !== null || "") {
      return <Navigate to={APP_ROUTES.DASHBOARD} />;
    }
  }

  async function checkUserSession() {
    const sessionResp = await customAxios.get(`${API_ENDPOINTS.GET_LOGIN_STAFF}`);
    setIsSessionChecked(true)
    if (!sessionResp?.status) {
      token = undefined
      localStorage.clear();
      window.location.href = "/login";
    }
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      {isSeesionChecked ?
        <div>
          <Routes>
            <Route path="/logIn" element={<LogIn />} />
            <Route path={AppRoutes.TELEHEALTH} element={<Telehealth />} />
            <Route path={AppRoutes.CALL_BY_CLIENT} element={<CallByClient />} />
            <Route path={AppRoutes.UPDATE_QUESTIONNAIRE_FROM_PORTAL} element={<UpdateClientQuestionnaire />} />
            <Route path={AppRoutes.ADMIN_VIEW_DOCUMENT_FILE_TEMPLATE} element={<AdminViewDocumentFileTemplate />} />
            <Route path={AppRoutes.PAY_SUBSCRIPTION} element={<SubscriptionByPass/>}
              />
          </Routes>

          {location.pathname !== AppRoutes.TELEHEALTH && !isAdminViewDocFileTemplateRoute && (
            <PrivateRoutes token={token} />
          )}
        </div> : <Loader></Loader>}
    </ErrorBoundary>
  );
};

const ClearCacheComponent = withClearCache(MainAppContent);

const MainApp = () => {
  return <ClearCacheComponent />;
}


export default MainApp;
