import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import moment from "moment";
import React, {ComponentState} from "react";
import * as ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";
import { userIsSuperAdmin } from "src/helper/permission-helper";

const exportElement = (element, options) => {
  drawDOM(element, options)
    .then((group) => {
      return exportPDF(group);
    })
    .then((dataUri) => {
      saveAs(dataUri, "staff_report.pdf");
    });
};
const DocumentStaffPdf = ({
  reportData,
  isPrintPDF,
  setIsPrintPDF,
  startDate,
  endDate,
  utcDateCreated
}) => {
  const { summaryStaffReport } = reportData;
  const staffLoginInfo = useSelector((state: any) => state?.getStaffReducer);
  let _element: HTMLDivElement;
  React.useEffect(() => {
    if (isPrintPDF) {
      handleSelect();
    }
  }, []);
  const isBillingManager = useSelector(
    (state: any) => state.getRolePermission.isBillingManager
  );

  const rolePermisson = useSelector((state: ComponentState) => state.getRolePermission);


  const myTemplate = (args) => {
    return ReactDOMServer.renderToString(

      <div style={{ position: 'absolute', float: 'right', bottom: "3px", width: '95%', display: "flex", justifyContent: 'flex-end', backgroundColor: "#fff", padding: "1px 1px" }}>
        <span className='mb-0 f-14' style={{ color: "#000",  fontSize: "12px", marginLeft: "5px", fontWeight: "600", textAlign: "right" }}>
          PAGE:<span className='f-14' style={{ color: "#4a4a4b",fontSize: "12px" }}>&nbsp; {args.pageNum} of {args.totalPages}
          </span></span>
      </div>
    );
  };

  const handleSelect = () => {
    exportElement(_element, {
      paperSize: "A3",
      margin: {
        left: 0,
        top: 15,
        right: 0,
        bottom: 15,
      },
      fileName: "Staff Service Report",
      author: "Notenetic Team",
      template: myTemplate,
      keepTogether: ".keep-together",
      multiPage: true,
    });
    setTimeout(() => {
      setIsPrintPDF(false);
    });
  };

  function toHoursAndMinutes(totalMinutes) {
    var hours = Math.floor(totalMinutes / 60);
    var minutes = totalMinutes % 60;
    var hoursStr = hours > 0 ? `${hours} hr ` : "";
    var minutesStr = minutes > 0 ? `${minutes} mins` : "";
    const formatedTime = `${hoursStr}${minutesStr}`;
    return formatedTime;
  }

  function formatTime(startTime, endTime) {
    const format = "h:mm A";
    if (startTime && endTime) {
      return `${moment(startTime).format(format)} - ${moment(
        endTime
      ).format(format)}`;
    }
    else
      return "-";
  }
  function currencyFormat(num) {
    return '$' + num?.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }
  var totMins = 0;
  var totUnits = 0;
  var totBill = 0;
  var grandTotalOfStaffPayRates = 0;
  const fromToDate = `Report from ${moment(startDate).format("M/D/YYYY")} to ${moment(endDate).format("M/D/YYYY")}`;
  const createdBy = `Created on: ${moment(utcDateCreated).format("M/D/YYYY h:mm A")}`;



  return (
    <div style={{ fontFamily: "Roboto, Helvetica, Arial, sans-serif" }}>
      <div>
        <div
          ref={(div: HTMLDivElement) => {
            _element = div;
          }}
          style={{ fontSize: "11px" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <label className="text-left ml-2" style={{ fontSize: "12px" }}>Staff Service Report</label>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
              <div className="f-12 fw-800">{fromToDate}</div>
              <div className="f-12 fw-800">{createdBy}</div>
            </div>
          </div>
          <hr></hr>
          {summaryStaffReport?.map((data) => {
            { var count = 0; }
            return (
              <>
                <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center", }}>
                  <label className="text-left ml-2" style={{ fontSize: "15px" }}>{data.staffName}</label>
                </div>
                <div>
                  <table className="" style={{ borderCollapse: "collapse", width: "100%", border: "0.5px solid #dee2e6" }}>
                    <thead>
                      <tr style={{ backgroundColor: "#f8f9fa" }}>
                        <th style={{ border: "0.5px solid #dee2e6", padding: "2px", textAlign: "center", width: '25px' }}>#</th>
                        <th style={{ border: "0.5px solid #dee2e6", padding: "2px", textAlign: "center" }}>DOS</th>
                        <th style={{ border: "0.5px solid #dee2e6", padding: "2px", textAlign: "center" }}>Client</th>
                        <th style={{ border: "0.5px solid #dee2e6", padding: "2px", textAlign: "center", width: "100px" }}>Code</th>
                        <th style={{ border: "0.5px solid #dee2e6", padding: "2px", textAlign: "center" }}>Duration</th>
                        <th style={{ border: "0.5px solid #dee2e6", padding: "2px", textAlign: "center" }} >Time</th>
                        <th style={{ border: "0.5px solid #dee2e6", padding: "2px", textAlign: "center", width: '30px' }}>Units</th>
                        {(isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) ? <th style={{ border: "0.5px solid #dee2e6", padding: "2px", textAlign: "center" }}>Bill Amt</th> : ""}
                        {
                          (userIsSuperAdmin(staffLoginInfo?.roleId) || staffLoginInfo?.isHumanResourcesManager || staffLoginInfo?.id === data?.staffId)
                            ? <th style={{ border: "0.5px solid #dee2e6", padding: "2px", textAlign: "center" }}>Pay</th> : null
                        }
                        <th style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>Doc Status</th>
                        {(isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId) || rolePermisson.canModifyBillingStatus) ? <th style={{ border: "0.5px solid #dee2e6", padding: "2px", textAlign: "center",width:"100px" }}>Bill Status</th> : ""}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.documents?.map((tableData) => {
                        return (
                          <>
                            <tr>
                              <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>{count = count + 1}</td>
                              <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>{moment(tableData?.serviceDate).format("M/D/YYYY")}</td>
                              <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>{`${tableData?.clientLName}, ${tableData?.clientFName}`}</td>
                              <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center", width: "80px", wordBreak: "break-word" }}>{tableData?.service}</td>
                              <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>{formatTime(tableData?.startTime, tableData?.endTime)}</td>
                              <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>{toHoursAndMinutes(tableData?.totalMinutes)}</td>

                              <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>{tableData?.numUnits}</td>
                              {(isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) ? <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>{currencyFormat(tableData?.amtBilled)}</td> : "" }
                              {
                                (userIsSuperAdmin(staffLoginInfo?.roleId) || staffLoginInfo?.isHumanResourcesManager || staffLoginInfo?.id === data?.staffId)
                                  ? <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>{currencyFormat(tableData?.staffPayRates)}</td> : null
                              }
                              <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>{tableData?.docStatus}</td>
                              {(isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId) || rolePermisson.canModifyBillingStatus) ? <td style={{ border: "0.5px solid #dee2e6", fontSize: "12px", padding: "2px", textAlign: "center" }}>{tableData?.billingStatusName}</td> : ""}
                              </tr>
                            <div style={{ display: "none" }}>
                              {totMins = totMins + tableData?.totalMinutes}
                              {totUnits = totUnits + tableData.numUnits}
                              {
                                (isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId))
                                ?
                                totBill = totBill + tableData?.amtBilled
                                : ""
                              }
                              {
                                (userIsSuperAdmin(staffLoginInfo?.roleId) || staffLoginInfo?.isHumanResourcesManager || staffLoginInfo?.id === data?.staffId)
                                  ?
                                  grandTotalOfStaffPayRates = grandTotalOfStaffPayRates + tableData?.staffPayRates
                                  : ""
                              }
                            </div>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <br></br>
                <div className="row keep-together">
                  <div className="col">
                    <div className="f-12 fw-800 text-center" >Clients Served </div>
                    <div className="f-12 fw-700 text-center">{data.totClientsServed}</div>
                  </div>
                  <div className="col">
                    <div className="f-12 fw-800 text-center">Total Units </div>
                    <div className="f-12 text-center">{data.totUnits}</div>
                  </div>
                  <div className="col">
                    <div className="f-12 fw-800 text-center">Total Time </div>
                    <div className="f-12 text-center">{toHoursAndMinutes(data?.totMinutes)}</div>
                  </div>

                  {(isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId))
                      ?
                      <div className="col">
                        <div className="f-12 fw-800 text-center">Total Billed</div>
                        <div className="f-12 text-center">{currencyFormat(data?.totAmtBilled)}</div>
                      </div>
                      : null
                  }

                  {
                    (userIsSuperAdmin(staffLoginInfo?.roleId) || staffLoginInfo?.isHumanResourcesManager || staffLoginInfo?.id === data?.staffId)
                      ?
                      <div className="col">
                        <div className="f-12 fw-800 text-center">Gross Pay</div>
                        <div className="f-12 text-center">{currencyFormat(data?.grandTotalOfStaffPayRates)}</div>
                      </div>
                      : null
                  }
                </div>
                <span></span>
                <br></br><br></br>
              </>
            );
          })}
          <br></br>
          <div className="keep-together mt-2" style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.04)", borderTop: "1px solid rgba(0, 0, 0, 0.04)" }}>
            <div className="row">
              <div className="col">
                <label className="fw-800" style={{ fontSize: "12px" }}>Grand Total Time for all staff:</label>
              </div>
              <div className="mx-2 col">
            <div className="d-flex flex-column">
              <label className="fw-700 mb-1" style={{ fontSize: "12px", minWidth: "120px", textAlign: "left" }}>Time: {toHoursAndMinutes(totMins)}</label>
              <label className="fw-700 mb-1" style={{ fontSize: "12px", minWidth: "120px", textAlign: "left" }}>Service Units: {totUnits} Total Units</label>
              {(userIsSuperAdmin(staffLoginInfo?.roleId) || staffLoginInfo?.isHumanResourcesManager) && 
                <label className="fw-700 mb-1" style={{ fontSize: "12px", minWidth: "120px", textAlign: "left" }}>Gross Pay: {currencyFormat(grandTotalOfStaffPayRates)}</label>
              }
              {(isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) && 
                <label className="fw-700 mb-1" style={{ fontSize: "12px", minWidth: "120px", textAlign: "left" }}>Total Billed: {currencyFormat(totBill)}</label>
              }
            </div>
          </div>
          </div>
        </div>
        </div>
      </div>
    </div >
  );
};

export default DocumentStaffPdf;
