import React, { ComponentState, useEffect, useState } from "react";
import Loader from "../../../control-components/loader/loader";
import DrawerContainer from "../../../control-components/custom-drawer/custom-drawer";
import addIcon from "../../../assets/images/add.png";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { orderBy } from "@progress/kendo-data-query";
import { getter } from "@progress/kendo-react-common";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getClinicianList } from "src/redux/actions";
import { ClinicianListModel } from "src/dataModels/clinicResponseModels";
import AddNewClinician from "./add_new_clinician";
import { NotificationManager } from "react-notifications";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import apiHelper from "src/helper/api-helper";
import { renderErrors } from "src/helper/error-message-helper";
import useScrollBlock from "src/cutomHooks/scroll";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import SubscribeConfirmationModal from "./confirm-subscribe-modal";
import editPencil from "../../../assets/images/pencil-edit-02.png";
import trashIcon from "../../../assets/images/trash2.png";
import { customAxios } from "src/services/useExPressApi";

const DATA_ITEM_KEY = "id";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const EPrescription = () => {
    const [loading, setLoading] = useState(false);
    const [sort, setSort] = useState([]);
    const [clinicianData, setClinicianData] = useState<ClinicianListModel[]>([]);
    const [isApiCalled, setInitialApiCalled] = useState(false);
    const [doseSpotClinicId, setDoseSpotClinicId] = useState(0);
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const [selectedState, setSelectedState] = React.useState({});
    const [showAddPrescriptions, setShowAddPrescriptions] = React.useState(false);
    const navigate = useNavigate();
    const [isDeleteConfirm, setIsDeleteConfirm] = useState(false);
    const [selectedStaff, setSelectedStaff] = useState({});
    const [blockScroll, allowScroll] = useScrollBlock()
    const [selectedDosespotClinician, setSelectedDosespotClinician] = useState({});
    const state: ComponentState = useSelector(states => { return states; });
    const { listClinician } = state['ClinicsReducer'];
    const [isSubscribeConfirmationOpen, setIsSubscribeConfirmationOpen] = useState(false);
    useEffect(() => {
        if (!isApiCalled) {
            getClinicDosespotId();
            dispatch(getClinicianList());
            setInitialApiCalled(true)
        }
    }, [isApiCalled]);
    useEffect(() => {
        if (listClinician) {
            setClinicianData(listClinician)
        }
    }, [listClinician]);
    const pageChange = (event) => {
        let skip = event.page.skip;
        let take = event.page.take;
        setPage(skip);
        setPageSize(take);
    };
    const getClinicDosespotId = () => {
        setLoading(true);
        customAxios.get(`${API_ENDPOINTS.GET_CLINIC_DOSESPOT_ID}`).then((result: any) => {
            if (result.statusCode == 200) {
                setLoading(false);
                setDoseSpotClinicId(result?.resultData?.doseSpotClinicId)
            }
            else setDoseSpotClinicId(0);
        }).catch((err) => {
            setLoading(false);
        }).finally(() => {
            setLoading(false);
        });
    }

    const handleSubscribeConfirmation = () => {
        setIsSubscribeConfirmationOpen(true);
    }

    const subscribeEPrescription = async () => {
        setLoading(true);
        customAxios.get(`${API_ENDPOINTS.ADD_ERX_CLINIC}`).then((result: any) => {
            if (result.statusCode == 200) {
                setLoading(false);
                NotificationManager.success("Success!");
                getClinicDosespotId();
                dispatch(getClinicianList());
            }
        }).catch((err) => {
            setLoading(false);
            setIsSubscribeConfirmationOpen(false);
        }).finally(() => {
            setLoading(false);
            setIsSubscribeConfirmationOpen(false);
        });
    }
    const addNewPrescriptions = () => {
        setSelectedDosespotClinician({});
        setShowAddPrescriptions(true);
        blockScroll()
    }
    const handleCloseAddPrescription = () => {
        allowScroll()
        setShowAddPrescriptions(false)
        dispatch(getClinicianList());
    }
    const removeErxClinician = async (field) => {
        blockScroll()
        setSelectedStaff(field);
        setIsDeleteConfirm(true);
    }
    const removeClinician = async (field) => {
        setLoading(true);
        var clinicianId = field.doseSpotClinicianId;
        customAxios.delete(`${API_ENDPOINTS.REMOVE_CLINICIAN}${clinicianId}`).then((result: any) => {
            if (result.statusCode == 200) {
                setLoading(false);
                allowScroll()
                setIsDeleteConfirm(false);
                NotificationManager.success("Clinician Removed!");
                dispatch(getClinicianList());
            }
        }).catch((err) => {
            setLoading(false);
            renderErrors(err);
        }).finally(() => {
            setLoading(false);
        });
    }
    const handleCloseDialogDeleteErxStaff = () => {
        allowScroll()
        setIsDeleteConfirm(false);
    }
    const DialogDeleteErxStaff = ({
        onClose,
        erxStaff
    }) => {
        return (
            <Dialog
                onClose={onClose}
                title={"Remove eRx Staff"}
                className="xs-modal">
                <p style={{ margin: "25px", }}>Are you sure you want to remove selected eRx clinician?</p>
                <DialogActionsBar>
                    <button
                        style={{ backgroundColor: "#ea4d36" }}
                        className="btn submitButon text-white"
                        onClick={() => removeClinician(selectedStaff)}>
                        Yes
                    </button>
                    <button className="btn cancelBtn text-white" onClick={handleCloseDialogDeleteErxStaff}>
                        No
                    </button>
                </DialogActionsBar>
            </Dialog>
        );
    };
    const editClinician = async (field) => {
        setSelectedDosespotClinician(field);
        setShowAddPrescriptions(true);
    }
    return (
        <>  
        <div className="d-flex flex-wrap">
            {loading === true && <Loader />}
            <div className="inner-dt col-md-3 col-lg-2">
                <DrawerContainer />
            </div>
            <div className="col-md-9 col-lg-10 ">
                <div className="px-2 mt-4">
                    {
                        doseSpotClinicId > 0 ?
                            <div>
                                <div className="d-flex flex-wrap justify-content-between align-items-center mb-4 gap-2">
                                    <h4 className="address-title text-grey">
                                        <span className="f-24">eRx Subscription</span>
                                    </h4>
                                    <button
                                        type="button" data-toggle="modal" data-target="#editform"
                                        onClick={addNewPrescriptions}
                                        className="submitButon mr-3">
                                        <img src={addIcon} alt="" className="mr-2 add-img" />
                                        Add New eRx Staff
                                    </button>
                                </div>
                                <div className="grid-table blueThemeTable table-heading-auth mt-2">
                                    <Grid
                                        data={orderBy(clinicianData, sort).map((item) => ({ ...item, [SELECTED_FIELD]: selectedState[idGetter(item)], })).slice(page, pageSize + page)}
                                        total={
                                            clinicianData && clinicianData.length > 0
                                                ? clinicianData.length
                                                : 0
                                        }
                                        style={{
                                            height: clinicianData.length > 0 ? "100%" : "200px",
                                        }}
                                        skip={page}
                                        take={pageSize}
                                        onPageChange={pageChange}
                                        pageable={{
                                            pageSizes: [10, 25, 50, 100, 250],
                                        }}
                                        className="pagination-row-cus"
                                    >
                                        <GridNoRecords >
                                        </GridNoRecords>
                                        <GridColumn
                                            className="cursor-default"
                                            field="staffId"
                                            title="Staff ID" />
                                        <GridColumn
                                            className="cursor-default"
                                            field="firstName"
                                            title="First Name"
                                        />
                                        <GridColumn
                                            className="cursor-default"
                                            field="lastName"
                                            title="Last Name"
                                        />
                                        <GridColumn
                                            field="clinicianRoles"
                                            className="cursor-default"
                                            title="Role"
                                            cell={(props) => {
                                                let roleItem: any[] = props.dataItem.clinicianRoles
                                                return (
                                                    <td  className="cursor-default">
                                                        {
                                                            roleItem.map((selectedItem) => {
                                                                return <li  className="cursor-default">{selectedItem.dosespotRoleName} </li>
                                                            })
                                                        }

                                                    </td>

                                                );
                                            }}
                                        />
                                        <GridColumn
                                            title="Action"
                                            filterable={false}
                                            cell={(props) => {
                                                let field = props.dataItem;
                                                return (
                                                    <td>
                                                        <div className="row-3">
                                                            <div
                                                                className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base" style={{background:"#e8f5e9"}}
                                                                onClick={() => editClinician(field)}>
                                                                <div className="k-chip-content">
                                                                    <Tooltip anchorElement="target" position="top">
                                                                        {/* <i
                                                                            className={"fa fa-edit"}
                                                                            aria-hidden="true"
                                                                            title={"Edit"}
                                                                        ></i> */}
                                                                        <img src={editPencil}  alt=" edit "  title="Edit"  aria-hidden="true" style={{ maxWidth: "18px" }} className="cursor-pointer" />
                                                                        
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                            &nbsp;&nbsp;
                                                            <div
                                                                className="k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base" style={{background:"#ffebee"}}
                                                                onClick={() => removeErxClinician(field)}>
                                                                <div className="k-chip-content">
                                                                    <Tooltip anchorElement="target" position="top">
                                                                        {/* <i
                                                                            className={"fa fa-trash"}
                                                                            aria-hidden="true"
                                                                            title={"Delete"}
                                                                        ></i> */}
                                                                          <img src={trashIcon} alt="trash icon"   aria-hidden="true" className="cursor-pointer"
                                          title="Delete" />
                                                                    </Tooltip>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </td>
                                                );
                                            }}
                                        />
                                    </Grid>
                                </div>

                            </div>
                            :
                            <div className="col-md-9 col-lg-10 ">
                                <button
                                    type="button" data-toggle="modal" data-target="#editform"
                                    onClick={handleSubscribeConfirmation}
                                    className="submitButon d-flex align-items-center mr-5">
                                    <img src={addIcon} alt="" className="mr-2 add-img" />
                                    Subscribe E-Prescription
                                </button>
                            </div>
                    }

                </div>
            </div>
            {showAddPrescriptions && (
                <AddNewClinician onClose={handleCloseAddPrescription} selectedDosespotClinician={selectedDosespotClinician} />
            )}
            {isDeleteConfirm && (
                <DialogDeleteErxStaff
                    onClose={handleCloseDialogDeleteErxStaff}
                    erxStaff={selectedStaff}
                />
            )}
            </div>
            <SubscribeConfirmationModal
                isOpen={isSubscribeConfirmationOpen}
                onClose={() => setIsSubscribeConfirmationOpen(false)}
                onConfirm={subscribeEPrescription}
                isLoading={loading}
            />
        </>
    );
};

export default EPrescription;