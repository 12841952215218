import { takeLatest, put } from 'redux-saga/effects';
import { saveCalendarEventTitle, getCalendarEventTitle, loaderAction } from '../actions';
import { CALENDAR_SETTING_TYPES } from '../actions/types_calendar_setting';
import { customAxios } from 'src/services/useExPressApi';
import { API_ENDPOINTS } from 'src/services/api-endpoints';
import { NotificationManager } from "react-notifications";
import { EventTitleResponseModal } from 'src/dataModels/calendarSettingModal';
import { GenericResponse } from 'src/dataModels/authSetModels';



export function* getCalendarEventTitleSaga() {
  yield takeLatest(CALENDAR_SETTING_TYPES.GET_CALENDAR_EVENT_TITLE, getCalendarEventTitleWorker);
}

function* getCalendarEventTitleWorker() {
  yield put(loaderAction(true));
  try {
    const response: EventTitleResponseModal = yield customAxios.get(API_ENDPOINTS.CALENDAR_SETTING.GET_CALENDAR_EVENT_TITLE);
    if (response && response.status) {
      yield put(saveCalendarEventTitle(response.resultData));
      yield put(loaderAction(false));
    }
  } catch (err) {
    yield put(loaderAction(false));
  }
}



export function* insertCalendarEventTitleSaga() {
  yield takeLatest(CALENDAR_SETTING_TYPES.INSERT_CALENDAR_EVENT_TITLE, insertCalendarEventTitleWorker);
}

function* insertCalendarEventTitleWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: GenericResponse = yield customAxios.post(API_ENDPOINTS.CALENDAR_SETTING.INSERT_CALENDAR_EVENT_TITLE, param['payload']);
    if (response && response.status) {
      NotificationManager.success('Event Title added successfully')
      yield put(getCalendarEventTitle());
      yield put(loaderAction(false));
    }
  } catch (err) {
    yield put(loaderAction(false));
  }
}


export function* updateCalendarEventTitleSaga() {
  yield takeLatest(CALENDAR_SETTING_TYPES.UPDATE_CALENDAR_EVENT_TITLE, updateCalendarEventTitleWorker);
}

function* updateCalendarEventTitleWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: GenericResponse = yield customAxios.post(API_ENDPOINTS.CALENDAR_SETTING.UPDATE_CALENDAR_EVENT_TITLE, param['payload']);
    if (response && response.status) {
      NotificationManager.success('Event Title updated successfully')
      yield put(getCalendarEventTitle());
      yield put(loaderAction(false));
    }
  } catch (err) {
    yield put(loaderAction(false));
  }
}

export function* deleteCalendarEventTitleSaga() {
  yield takeLatest(CALENDAR_SETTING_TYPES.DELETE_CALENDAR_EVENT_TITLE, deleteCalendarEventTitleWorker);
}

function* deleteCalendarEventTitleWorker(param: any) {
  try {
    yield put(loaderAction(true));
    const response: GenericResponse = yield customAxios.delete(`${API_ENDPOINTS.CALENDAR_SETTING.DELETE_CALENDAR_EVENT_TITLE}?id=${param['payload']}`);
    if (response && response.status) {
      NotificationManager.success('Event Title deleted successfully')
      yield put(getCalendarEventTitle());
      yield put(loaderAction(false));
    }
  } catch (err) {
    yield put(loaderAction(false));
  }
}