import { Error } from "@progress/kendo-react-labels";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { loaderAction } from "src/redux/actions";
import { onShowFileTempFields } from "./file-template-utility";
import DEVELOPMENT_CONFIG from "../../helper/config";
export function AddDocumentFileTemplate({
    name,
    onComponentLoaded,
    controlErrors,
    showValidationError,
    focusEnable,
    selectedPatientList,
    isEdit,
    onGoalsDataStateChange,
    preselctedGoalEdit,
    isViewDoc,
    dxPreselectedData,
    onDxStateChange,
    tpId,
    isDraft,
    draftCommonControlls,
    docFieldMappings,
    isViewMode,
    docIndex = 0,
    documentId,
    isClientEdit = false
    // setHtmlFileValidationError,
}) {
    const itemsRef = useRef(null);
    const [errorList, setErrorList] = useState([]);
    const [ComponentToRender, setComponentToRender] = useState(null);
    const dispatch = useDispatch();
    useEffect(() => {
        if (ComponentToRender && onComponentLoaded) {

            onComponentLoaded();
        }
    }, [ComponentToRender])

    useEffect(() => {
        importTemplate()
    }, [name]);



    async function importTemplate() {
        try {
            const module = await import(`./file-template/${name}`);
            setComponentToRender(() => (module.default ? module.default : module[name]));
        } catch (error) {
            try {
                const fileUrl = `${DEVELOPMENT_CONFIG.DOCUMENT_TEMPLATE_S3_URL}/${name}.js`;
                const module = await import(/* webpackIgnore: true */ fileUrl);
                setComponentToRender(() => module.default);
            } catch (s3Error) {
                setComponentToRender(() => (props) => (
                    <div>The selected template ({name}) is not available at the moment. Please contact support.</div>
                ));
            }
        }

        // Ensure onComponentLoaded is called after everything
        if (onComponentLoaded) {
            onComponentLoaded();
        }
    }


    useEffect(() => {
        if (ComponentToRender && docFieldMappings && docFieldMappings.length && docFieldMappings.length > 0) {
            dispatch(loaderAction(true))
            onShowFileTempFields(docFieldMappings, documentId, isViewMode, name, docIndex, isClientEdit);
            dispatch(loaderAction(false))
        } else {

        }
    }, [ComponentToRender])

    useEffect(() => {
        const handleChange = (_) => {
            // setHtmlFileValidationError()
        };

        const formEl = document.getElementById(name);
        formEl?.addEventListener("change", handleChange);

        return () => {
            formEl?.removeEventListener("change", handleChange);
        };
    }, []);

    useEffect(() => {
        let errorList =
            !showValidationError || !controlErrors || controlErrors.length < 1
                ? []
                : controlErrors;
        setErrorList(errorList);
    }, [showValidationError, controlErrors]);

    useEffect(() => {
        if (focusEnable && errorList && errorList.length > 0) {
            const uniqueIndex = errorList[0].id;
            if (
                itemsRef.current &&
                itemsRef.current[uniqueIndex] &&
                itemsRef.current[uniqueIndex].focus
            ) {
                itemsRef.current[uniqueIndex].focus();
            }
        }
    }, [errorList, focusEnable]);

    function showErrorFor(id) {
        if (!errorList) return <></>;
        const error = errorList.find((error) => error.id === id);
        if (!error) return <></>;
        return <Error>{error.msg}</Error>;
    }

    return (ComponentToRender) ? (
        <ComponentToRender
            itemsRef={itemsRef}
            showErrorFor={showErrorFor}
            selectedPatientList={selectedPatientList}
            isEdit={isEdit}
            onGoalsDataStateChange={onGoalsDataStateChange}
            preselctedGoalEdit={preselctedGoalEdit}
            isViewDoc={isViewDoc}
            dxPreselectedData={dxPreselectedData}
            onDxStateChange={onDxStateChange}
            tpId={tpId}
            isDraft={isDraft}
            draftCommonControlls={draftCommonControlls}
        />
    ) : (
        <></>
    );
}