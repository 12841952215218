import React, { ComponentState, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { ClientService } from "src/services/clientService";
import APP_ROUTES from "src/helper/app-routes";
import { renderErrors } from "src/helper/error-message-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";

import { NichqHeader } from "./Nichq-heads";
import { BdssQueSet } from "./BdssQueSet";
import ClientQuestionnaireService from "./functionToSaveQuestionnaireFromPortal";






export const BDSS_Scale = ({ BDSS, BDSS_HEADERS, BDSS_ADDON, response, interfered, isPrint, questionId, isFromCP, token, queId }) => {

  const [bdss, setBdss] = useState<any[]>([]);
  const { state } = useLocation();
  const selectedClientId = useSelector((state: ComponentState) => state.selectedClientId);
  const navigate = useNavigate();

  const [bdssHeader, setBdssHeader] = useState<any[]>([]);
  const [bdssAddon, setBdssAddon] = useState<any[]>([]);


  useEffect(() => {

    const Bdss = BDSS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    const BdssHeader = BDSS_HEADERS.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    const BdssAddon = BDSS_ADDON.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          if (foundItem.value) {
            return { ...item, isSelected: true, value: foundItem.value };
          } else {
            return { ...item, isSelected: false, value: "" };
          }
        }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });


    setBdss(Bdss)
    setBdssHeader(BdssHeader)
    setBdssAddon(BdssAddon)



  }, [response])

  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId, 0,
      [
        ...bdss,
        ...bdssAddon,
        ...bdssHeader,
      ],
      questionId
    )
      .then((result:any) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        // setLoading(false);
        renderErrors(error.message);
      });
  };

  const handleSaveFromPortal = async () => {
    ClientQuestionnaireService.updatequestionnaireFromPortal(selectedClientId, 0, 
      
      [
        ...bdss,
        ...bdssAddon,
        ...bdssHeader,
      ],
       queId, token)
  }

  const handleCompleteFromPortal = async () => {
    try {
      ClientQuestionnaireService.completeQuestionnaireFromPortal(false, queId, selectedClientId, 0, [
        ...bdss,
        ...bdssAddon,
        ...bdssHeader,
      ],  {isCompleted: true, inProgress: false, sentToClientPortal: null}, token)
    } catch (error) {
      console.error('Error completing client questionnaire:', error);
      NotificationManager.error('Failed to complete client questionnaire');
    }
  };

  return (

    <div  className="ml-3 spsrDocument">
      
      <div className="mt-4 client-name-bdss">
        <h6>BRIEF BIPOLAR DISORDER SYMPTOM SCALE</h6>
      </div>

      <div className="mt-4 client-name-bdss">
        <label><b>1. HOSTILITY:</b> Animosity, contempt, belligerence, threats, arguments, tantrums, property
        destruction, fights and any other expression of hostile attitudes or actions. Do not infer
        hostility from neurotic defenses, anxiety or somatic complaints. Do not include incidents of
        appropriate anger or obvious self-defense. </label>
        <br />
        <i><label>How have you been getting along with people (family, co-workers, etc.)?</label></i>

        <br />
        <i><label>Have you been irritable or grumpy lately? (How do you show it? Do you keep it to yourself?)</label></i>

        <br />
        <i><label>Were you ever so irritable that you would shout at people or start fights or arguments? (Have
          you found yourself yelling at people you didn't know?)</label></i>

        <br />
        <i><label>Have you hit anyone recently?</label></i>

      </div>
      <div className="mt-2 px-5 client-name-bdss">
        <div className="row md-12 f-14">
          <div className="md-4"><label>NA Not assessed</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label >1 Not Present</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label >2 Very Mild</label></div>
          <div className="md-8">&nbsp;
           <label> Irritable or grumpy, but not overtly expressed.  </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>3 Mild</label></div>
          <div className="md-8">&nbsp;
           <label> Argumentative or sarcastic.</label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>4 Moderate</label></div>
          <div className="md-8">&nbsp;
           <label> Overtly angry on several occasions OR yelled at others excessively.</label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>5 Moderately Severe</label></div>
          <div className="md-8">&nbsp;
          </div>
          <label >  Has threatened, slammed about or thrown things. </label>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>6 Severe</label></div>
          <div className="md-8">&nbsp;
          <label>  Has assaulted others but with no harm likely, e.g., slapped or pushed, OR destroyed
            property, e.g., knocked over furniture, broken windows.</label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"> <label>7 Extremely Severe</label></div>
          <div className="md-8">&nbsp;
          <label> Has attacked others with definite possibility of harming them or with actual harm, e.g.,
            assault with hammer or weapon. </label>

          </div>
        </div>
      </div>

      <div className="mt-4 client-name-bdss">
      <label>2. ELEVATED MOOD: A pervasive, sustained and exaggerated feeling of well-being,
        cheerfulness, euphoria (implying a pathological mood), optimism that is out of proportion to
        the circumstances. Do not infer elation from increased activity or from grandiose statements
        alone. </label>
        <br />
        <i> <label>  Have you felt so good or high that other people thought that you were not your normal self? </label>
        </i><br />
        <i> <label> Have you been feeling cheerful and “on top of the world” without any reason? </label></i><br />
        <label> [If patient reports elevated mood/euphoria, ask the following]:     </label><br />
        <i> <label> Did it seem like more than just feeling good? How long did that last?</label></i><br />
      </div>
      <div className="mt-2 px-5 client-name-bdss">
        <div className="row md-12 f-14">
          <div className="md-4"><label>NA Not assessed</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>1 Not Present</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>2 Very Mild</label></div>
          <div className="md-8">&nbsp;
          <label>  Seems to be very happy, cheerful without much reason. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>3 Mild</label></div>
          <div className="md-8">&nbsp;
           <label> Some unaccountable feelings of well-being that persist. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>4 Moderate</label></div>
          <div className="md-8">&nbsp;
           <label> Reports excessive or unrealistic feelings of well-being, cheerfulness, confidence or
            optimism inappropriate to circumstances, some of the time. May frequently joke, smile,
            be giddy or overly enthusiastic OR few instances of marked elevated mood with
            euphoria. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>5 Moderately Severe</label></div>
          <div className="md-8">&nbsp;
            <label> Reports excessive or unrealistic feelings of well-being, confidence or optimism
            inappropriate to circumstances much of the time. May describe “feeling on top of the
            world,” “like everything is falling into place," or “better than ever before,” OR several
            instances of marked elevated mood with euphoria. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>6 Severe</label></div>
          <div className="md-8">&nbsp;
           <label> Reports many instances of marked elevated mood with euphoria OR mood definitely
            elevated almost constantly throughout interview and inappropriate to content. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>7 Extremely Severe</label></div>
          <div className="md-8">&nbsp;
           <label> Patient reports being elated or appears almost intoxicated, laughing, joking, giggling,
            constantly euphoric, feeling invulnerable, all inappropriate to immediate circumstances. </label>
          </div>
        </div>
      </div>

      <div className="mt-4 client-name-bdss">
        <label>3. GRANDIOSITY: Exaggerated self-opinion, self-enhancing conviction of special abilities or
        powers or identity as someone rich or famous. Rate only patient's statements about himself,
        not his demeanor. Note: If the subject rates a “6” or “7” due to grandiose delusions, you
        must rate Unusual Thought Content at least a “4” or above. 
        <br />
        <i>Is there anything special about you? Do you have any special abilities or powers? Have you
          thought that you might be somebody rich or famous?</i><br />
         [If the patient reports any grandiose ideas/delusions, ask the following]: <br />
        <i>How often have you been thinking about [use patient's description]? Have you told anyone
          about what you have been thinking? Have you acted on any of these ideas?</i><br />
          </label>
      </div>
      <div className="mt-2 px-5 client-name-bdss">
        <div className="row md-12 f-14">
          <div className="md-4"><label>NA Not assessed</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>1 Not Present</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>2 Very Mild</label></div>
          <div className="md-8">&nbsp;
          <label>  Feels great and denies obvious problems, but not unrealistic. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>3 Mild</label></div>
          <div className="md-8">&nbsp;
           <label> Exaggerated self-opinion beyond abilities and training. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>4 Moderate</label></div>
          <div className="md-8">&nbsp;
           <label> Inappropriate boastfulness, claims to be brilliant, insightful, or gifted beyond realistic
            proportions, but rarely self-discloses or acts on these inflated self-concepts. Does not
            claim that grandiose accomplishments have actually occurred. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>5 Moderately Severe</label></div>
          <div className="md-8">&nbsp;
           <label> Same as 4 but often self-discloses and acts on these grandiose ideas. May have doubts
            about the reality of the grandiose ideas. Not delusional. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>6 Severe</label></div>
          <div className="md-8">&nbsp;
           <label> Delusional--claims to have special powers like ESP, to have millions of dollars, invented
            new machines, worked at jobs when it is known that he was never employed in these
            capacities, be Jesus Christ, or the President. Patient may not be very preoccupied. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>7 Extremely Severe</label></div>
          <div className="md-8">&nbsp;
           <label> Delusional--Same as 6 but subject seems very preoccupied and tends to disclose or act
            on grandiose delusions. </label>
          </div>
        </div>
      </div>

      <div className="mt-4 client-name-bdss">
        <label>4. DEPRESSION: Include sadness, unhappiness, anhedonia, and preoccupation with
        depressing topics (can’t attend to TV, conversations due to depression), hopelessness, loss
        of self-esteem (dissatisfied or disgusted with self or feelings of worthlessness). Do not
        include vegetative symptoms, e.g., motor retardation, early waking, or the amotivation that
        accompanies the deficit syndrome. 
        <br />
        <i>How has your mood been recently? Have you felt depressed (sad, down, unhappy as if you
          didn't care)?</i><br />
        <i>Are you able to switch your attention to more pleasant topics when you want to?</i><br />
        <i>Do you find that you have lost interest in or get less pleasure from things you used to enjoy,
          like family, friends, hobbies, watching TV, eating?</i><br />
         [If subject reports feelings of depression, ask the following]: <br />
        <i>How long do these feelings last? Has it interfered with your ability to perform your usual
          activitieslwork?</i><br />
          </label>
      </div>
      <div className="mt-2 px-5 f-14">
        <div className="row md-12 f-14">
          <div className="md-4"><label>NA Not assessed</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>1 Not Present</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>2 Very Mild</label></div>
          <div className="md-8">&nbsp;
          <label>  Occasionally feels sad, unhappy or depressed.  </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>3 Mild</label></div>
          <div className="md-8">&nbsp;
          <label>  Frequently feels sad or unhappy but can readily turn attention to other things. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>4 Moderate</label></div>
          <div className="md-8">&nbsp;
          <label>  Frequent periods of feeling very sad, unhappy, moderately depressed, but able to </label>
          <label>  function with extra effort.</label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>5 Moderately Severe</label></div>
          <div className="md-8">&nbsp;
          <label> Frequent, but not daily, periods of deep depression OR some areas of functioning are
            disrupted by depression.</label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>6 Severe</label></div>
          <div className="md-8">&nbsp;
          <label>  Deeply depressed daily but not persisting throughout the day OR many areas of
            functioning are disrupted by depression.</label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>7 Extremely Severe</label></div>
          <div className="md-8">&nbsp;
          <label>  Deeply depressed daily OR most areas of functioning are disrupted by depression. </label>
          </div>
        </div>
      </div>

      <div className="mt-4  client-name-bdss">
        <label>5. ANXIETY: Reported apprehension, tension, fear, panic or worry. Rate only the patient's
        statements, not observed anxiety that is rated under TENSION. 
        <br />
        <i>Have you been worried a lot during [mention time frame]? Have you been nervous or
          apprehensive? (What do you worry about?)</i><br />
        <i>Are you concerned about anything? How about finances or the future?</i><br />
        <i>When you are feeling nervous, do your palms sweat or does your heart beat fast (or
          shortness of breath, trembling, choking)?</i><br />
         [If patient reports anxiety or autonomic accompaniment, ask the following]; <br />
        <i>How much of the time have you been [use patient's description]?</i><br />
        <i>Has it interfered with your ability to perform your usual activitieslwork?</i><br />
        </label>
      </div>
      <div className="mt-2 px-5 f-14">
        <div className="row md-12 f-14">
          <div className="md-4"><label>NA Not assessed</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>1 Not Present</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>2 Very Mild</label></div>
          <div className="md-8">&nbsp;
          <label> Reports some discomfort due to worry OR infrequent worries that occur more than usual
            for most normal individuals. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>3 Mild</label></div>
          <div className="md-8">&nbsp;
          <label>  Worried frequently but can readily turn attention to other things. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>4 Moderate</label></div>
          <div className="md-8">&nbsp;
          <label> Worried most of the time and cannot turn attention to other things easily but no
            impairment in functioning OR occasional anxiety with autonomic accompaniment but no
            impairment in functioning. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>5 Moderately Severe</label></div>
          <div className="md-8">&nbsp;
          <label>  Frequent, but not daily, periods of anxiety with autonomic accompaniment, OR some
            areas of functioning are disrupted by anxiety or worry. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>6 Severe</label></div>
          <div className="md-8">&nbsp;
          <label>  Anxiety with autonomic accompaniment daily but not persisting throughout the day OR
            many areas of functioning are disrupted by anxiety or constant worry. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>7 Extremely Severe</label></div>
          <div className="md-8">&nbsp;
           <label> Anxiety with autonomic accompaniment persisting throughout the day OR most areas of
            functioning are disrupted by anxiety or constant worry. </label>
          </div>
        </div>
      </div>

      <div className="mt-4 client-name-bdss">
        <label>6. UNUSUAL THOUGHT CONTENT: Unusual, odd, strange or bizarre thought content. Rate
        the degree of unusualness, not the degree of disorganization of speech. Delusions are
        patently absurd, clearly false or bizarre ideas that are expressed with full conviction.
        Consider the patient to have full conviction if he/she has acted as though the delusional
        belief were true. Ideas of reference/persecution can be differentiated from delusions in that
        ideas are expressed with much doubt and contain more elements of reality. Include thought
        insertion, withdrawal and broadcast. Include grandiose, somatic and persecutory delusions
        even if rated elsewhere. Note: If Somatic Concern, Guilt, Suspiciousness, or Grandiosity are
        rated “6” or “7" due to delusions, then Unusual Thought Content must be rated a “4" or
        above. </label>
        <br />
        <label>
        <i>Have you been receiving any special messages from people or from the way things are
          arranged around you? Have you seen any references to yourself on TV or in the
          newspapers?</i><br />
        <i>Can anyone read your mind?</i><br />
        <i>Do you have a special relationship with God?</i><br />
        <i>Is anything like electricity, X-rays, or radio waves affecting you?</i><br />
        <i>Are thoughts put into your head that are not your own?</i><br />
        <i>Have you felt that you were under the control of another person or force?</i><br />
         [If patient reports any odd ideas/delusions, ask the following]: <br />
        <i>How often do you think about [use patient's description]?</i><br />
        <i>Have you told anyone about these experiences? How do you explain the things that have
          been happening [specify]?</i><br />
          </label>
      </div>
      <div className="mt-2 px-5 f-14">
        <div className="row md-12 f-14">
          <div className="md-4"><label>NA Not assessed</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>1 Not Present</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>2 Very Mild</label></div>
          <div className="md-8">&nbsp;
          <label> Ideas of reference (people may stare or may laugh at him), ideas of persecution (people
            may mistreat him). Unusual beliefs in psychic powers, spirits, UFOs, or unrealistic beliefs
            in one's own abilities. Not strongly held. Some doubt. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>3 Mild</label></div>
          <div className="md-8">&nbsp;
          <label>  Same as 2, but degree of reality distortion is more severe as indicated by highly unusual
            ideas or greater conviction. Content may be typical of delusions (even bizarre), but
            without full conviction. The delusion does not seem to have fully formed, but is
            considered as one possible explanation for an unusual experience. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>4 Moderate</label></div>
          <div className="md-8">&nbsp;
          <label>  Delusion present but no preoccupation or functional impairment. May be an encapsulated
            delusion or a firmly endorsed absurd belief about past delusional circumstances. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>5 Moderately Severe</label></div>
          <div className="md-8">&nbsp;
          <label> Full delusion(s) present with some preoccupation OR some areas of functioning
            disrupted by delusional thinking. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>6 Severe</label></div>
          <div className="md-8">&nbsp;
           <label> Full delusion(s) present with much preoccupation OR many areas of functioning are
            disrupted by delusional thinking. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>7 Extremely Severe</label></div>
          <div className="md-8">&nbsp;
           <label> Full delusions present with almost total preoccupation OR most areas of functioning are
            disrupted by delusional thinking. </label>
          </div>
        </div>
      </div>

      <div className="mt-4 client-name-bdss">
        <label>Rate the following items on the basis of observed behavior and speech.</label>
      </div>

      <div className="mt-4 client-name-bdss">
        <label>7. EXCITEMENT: Heightened emotional tone, or increased emotional reactivity to interviewer
        or topics being discussed, as evidenced by increased intensity of facial expressions, voice
        tone, expressive gestures or increase in speech quantity and speed. </label>
      </div>
      <div className="mt-2 px-5 f-14">
        <div className="row md-12 f-14">
          <div className="md-4"><label>NA Not assessed</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>1 Not Present</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>2 Very Mild</label></div>
          <div className="md-8">&nbsp;
          <label> Subtle and fleeting or questionable increase in emotional intensity. For example, at times,
            seems keyed-up or overly alert. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>3 Mild</label></div>
          <div className="md-8">&nbsp;
           <label> Subtle but persistent increase in emotional intensity. For example, lively use of gestures
            and variation in voice tone. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>4 Moderate</label></div>
          <div className="md-8">&nbsp;
          <label> Definite but occasional increase in emotional intensity. For example, reacts to interviewer
            or topics that are discussed with noticeable emotional intensity. Some pressured speech. </label> 
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>5 Moderately Severe</label></div>
          <div className="md-8">&nbsp;
          <label> Definite and persistent increase in emotional intensity. For example, reacts to many
            stimuli, whether relevant or not, with considerable emotional intensity. Frequent
            pressured speech. </label> 
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>6 Severe</label></div>
          <div className="md-8">&nbsp;
          <label>   Marked increase in emotional intensity. For example reacts to most stimuli with
            inappropriate emotional intensity. Has difficulty settling down or staying on task. Often
            restless, impulsive, or speech is often pressured. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>7 Extremely Severe</label></div>
          <div className="md-8">&nbsp;
          <label>  Marked and persistent increase in emotional intensity. Reacts to all stimuli with
            inappropriate intensity, impulsiveness. Cannot settle down or stay on task. Very restless
            and impulsive most of the time. Constant pressured speech. </label>
          </div>
        </div>
      </div>

      <div className="mt-4 client-name-bdss">
        <label>8. MOTOR HYPERACTIVITY: Increase in energy level evidenced in more frequent movement
        and/or rapid speech. Do not rate if restlessness is due to akathisia. </label>
      </div>
      <div className="mt-2 px-5 f-14">
        <div className="row md-12 f-14">
          <div className="md-4"><label>NA Not assessed</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>1 Not Present</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>2 Very Mild</label></div>
          <div className="md-8">&nbsp;
          <label> Some restlessness, difficulty sitting still, lively facial expressions, or somewhat talkative. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>3 Mild</label></div>
          <div className="md-8">&nbsp;
          <label>   Occasionally very restless, definite increase in motor activity, lively gestures, 1-3 brief
            instances of pressured speech.</label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>4 Moderate</label></div>
          <div className="md-8">&nbsp;
           <label>Very restless, fidgety, excessive facial expressions or nonproductive and repetitious
            motor movements. Much pressured speech, up to one third of the interview. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>5 Moderately Severe</label></div>
          <div className="md-8">&nbsp;
          <label>  Frequently restless, fidgety. Many instances of excessive non-productive and repetitious
            motor movements. On the move most of the time. Frequent pressured speech, difficult
            to interrupt. Rises on 1-2 occasions to pace. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>6 Severe</label></div>
          <div className="md-8">&nbsp;
          <label> Excessive motor activity, restlessness, fidgety, loud tapping, noisy, etc., throughout most
            of the interview. Speech can only be interrupted with much effort. Rises on 3-4
            occasions to pace. </label> 
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>7 Extremely Severe</label></div>
          <div className="md-8">&nbsp;
          <label>  Constant excessive motor activity throughout entire interview, e.g., constant pacing,
            constant pressured speech with no pauses, interviewee can only be interrupted briefly
            and only small amounts of relevant information can be obtained. </label>
          </div>
        </div>
      </div>

      <div className="mt-4 client-name-bdss">
        <label>9. EMOTIONAL WITHDRAWAL: Deficiency in patient's ability to relate emotionally during
        interview situation. Use your own feeling as to the presence of an “invisible barrier” between
        patient and interviewer. Include withdrawal apparently due to psychotic processes. </label>
      </div>
      <div className="mt-2 px-5 f-14">
        <div className="row md-12 f-14">
          <div className="md-4"><label>NA Not assessed</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>1 Not Present</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>2 Very Mild</label></div>
          <div className="md-8">&nbsp;
          <label>   Lack of emotional involvement shown by occasional failure to make reciprocal comments,
            occasionally appearing preoccupied, or smiling in a stilted manner, but spontaneously
            engages the interviewer most of the time. </label> 
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>3 Mild</label></div>
          <div className="md-8">&nbsp;
          <label>   Lack of emotional involvement shown by noticeable failure to make reciprocal comments,
            appearing preoccupied, or lacking in warmth, but responds to interviewer when
            approached. </label> 
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>4 Moderate</label></div>
          <div className="md-8">&nbsp;
          <label>  Emotional contact not present much of the interview because subject does not elaborate
            responses, fails to make eye contact, doesn't seem to care if interviewer is listening, or
            may be preoccupied with psychotic material. </label> 
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>5 Moderately Severe</label></div>
          <div className="md-8">&nbsp;
          <label>  Same as “4” but emotional contact not present most of the interview. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>6 Severe</label></div>
          <div className="md-8">&nbsp;
          <label> Actively avoids emotional participation. Frequently unresponsive or responds with yes/no
            answers (not solely due to persecutory delusions). Responds with only minimal affect. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>7 Extremely Severe</label></div>
          <div className="md-8">&nbsp;
          <label>   Consistently avoids emotional participation. Unresponsive or responds with yes/no
            answers (not solely due to persecutory delusions). May leave during interview or just not
            respond at all. </label> 
          </div>
        </div>
      </div>

      <div className="mt-4 client-name-bdss">
        <label>10. BLUNTED AFFECT: Restricted range in emotional expressiveness of face, voice, and
        gestures. Marked indifference or flatness even when discussing distressing topics. In the
        case of euphoric or dysphoric patients, rate Blunted Affect if a flat quality is also clearly
        present. 
        <br />
        <i>Use the following probes at end of interview to assess emotional responsivity:</i><br />
        <i>Have you heard any good jokes lately? Would you like to hear a joke?</i><br />
        </label>
      </div>
      <div className="mt-2 px-5 f-14">
        <div className="row md-12 f-14">
          <div className="md-4"><label>NA Not assessed</label></div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>1 Not Present</label> </div>
          <div className="md-8">&nbsp;</div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>2 Very Mild</label> </div>
          <div className="md-8">&nbsp;
          <label>   Emotional range is slightly subdued or reserved but displays appropriate facial
            expressions and tone of voice that are within normal limits. </label> 
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>3 Mild</label> </div>
          <div className="md-8">&nbsp;
          <label>   Emotional range overall is diminished, subdued, or reserved, without many spontaneous
            and appropriate emotional responses. Voice tone is slightly monotonous. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"><label>4 Moderate</label> </div>
          <div className="md-8">&nbsp;
          <label>  Emotional range is noticeably diminished, patient doesn't show emotion, smile, or react to
            distressing topics except infrequently. Voice tone is monotonous or there is noticeable
            decrease in spontaneous movements. Displays of emotion or gestures are usually
            followed by a return to flattened affect. </label> 
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"> <label>5 Moderately Severe</label></div>
          <div className="md-8">&nbsp;
        <label>   Emotional range very diminished, patient doesn't show emotion, smile or react to
            distressing topics except minimally, few gestures, facial expression does not change very
            often. Voice tone is monotonous much of the time. </label> 
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"> <label>6 Severe</label></div>
          <div className="md-8">&nbsp;
           <label> Very little emotional range or expression. Mechanical in speech and gestures most of the
            time. Unchanging facial expression. Voice tone is monotonous most of the time. </label>
          </div>
        </div>
        <div className="row md-12 f-14">
          <div className="md-4"> <label>7 Extremely Severe</label></div>
          <div className="md-8">&nbsp;
          <label>Virtually no emotional range or expressiveness, stiff movements. Voice tone is
            monotonous all of the time. </label>  
          </div>
        </div>
      </div>

      <div className="mt-4 client-name-bdss">
        <h5>
        Texas Implementation of Medication Algorithms
        <br/>
      <label>  Brief Bipolar Disorder Symptom Scale</label>
        </h5>
      </div>

      <div className="mt-4 client-name-bdss">
      <label>Instructions:
        Indicate the score for each item in the appropriate cell to the right of the item. Evaluate the pattern and severity of symptom(s) to guide clinical decision making.
        <br/>
        Presence of Mild to Moderate Symptoms may indicate need for medication adjustment.
<br/>
Any score &gt; 4 is within the range of Severe Symptoms, and indicates a need to make treatment changes. </label>
      </div>


      {
        bdssHeader.length > 0 &&
        <div className="row mt-4">
        <NichqHeader  

          title={""}
          disabled={isFromCP ? false : (response && response.length>0)}
          subTitle={''}
          listItems={bdssHeader}
          setListItems={setBdssHeader}
        /></div>
      }
      {
        bdss.length > 0 &&
        <BdssQueSet

          subTitle={''}
          listItems={bdss}
          setListItems={setBdss}
        />
      }
      {
        bdssAddon.length > 0 &&
        <NichqHeader

          title={""}
          disabled={isFromCP ? false : (response && response.length>0)}
          subTitle={''}
          listItems={bdssAddon}
          setListItems={setBdssAddon}
        />
      }



{isFromCP ? (
          <div className="text-start mt-3 col-12">
           <button onClick={handleCompleteFromPortal} className="submitButon">
            Save & Complete
          </button>
          <button onClick={handleSaveFromPortal} className="cancelBtn">
            Save progress
          </button>
          </div>
        ) : state == null && !isPrint ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleSubmit} className="submitButon">
              Submit
            </button>
          </div>
        ) : null}









    </div>

  )

} 