import React from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

import { Checkbox } from "@progress/kendo-react-inputs"

export interface LockDialogProps {
  title?,
  onClose?,
  activeType?,
  handleDelete?,
  handleReactive?,
  setDeleteAssociateSign?,
  isDeleteAssociateSign?,
  textForDialogBox?
}


const LockDialogModal = (props: LockDialogProps) => {
  const {
    title,
    onClose,
    activeType,
    handleDelete,
    handleReactive,
    setDeleteAssociateSign,
    isDeleteAssociateSign,
    textForDialogBox
  } = props;

  // useImperativeHandle(ref, () => ({
  //   handleDelete
  // }))

  const onChange = (e) => {
    setDeleteAssociateSign(e.target.value)
  }

  return (
    <Dialog onClose={onClose}
      className="small-dailog xs-modal blue_theme"
      title={activeType === true ?
        `Unlock ${title}` : `Lock ${title}`}>
      <p
        style={{
          margin: "25px",
        }}
      >
        {activeType === true ? 'Are you sure you want to unlock and allow editing again. Continue?' : props.textForDialogBox}
      </p>

      {
        activeType === true &&
        <div>
        <p className="f-12 d-flex gap-1">
          <Checkbox
            name="isDeleteAssociate"
            value={isDeleteAssociateSign}
            onChange={onChange}
            style={{ marginRight: "5px", marginTop: "0px" }}
          />
          Delete associate signatures?
        </p>
        </div>
      }



      <DialogActionsBar>
        {activeType ? (
          <button
            className="submitButon"
            onClick={handleReactive}
          >
            Yes
          </button>
        ) : (
          <button
            className="submitButon"
            onClick={handleDelete}
          >
            Yes
          </button>
        )}
        <button
          className="cancelBtn"
          onClick={onClose}
        >
          No
        </button>


      </DialogActionsBar>
    </Dialog>
  )
}

export default LockDialogModal