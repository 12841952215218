import React, { ComponentState, useState } from 'react'
import { AddDocumentFileTemplate } from "../documents/add-document-file-template"
import { onDataChange } from '../staff/documents/addStaffDocComponents/additionalFunction';
import { customAxios } from 'src/services/useExPressApi';
import { API_ENDPOINTS } from 'src/services/api-endpoints';
import { NotificationManager } from "react-notifications";
import { Loader } from "@progress/kendo-react-indicators";
import { useSelector } from 'react-redux';
import DateTimeHelper from 'src/helper/date-time-helper';
import { timeRecordingTypeEnum } from '../documents/document-utility';
import { convertTimeToLocally } from '../documents/document-utility';
import { totHoursAndMinutes } from '../documents/document-utility';
import { PreviewDocumentTemplate } from '../documents/template/preview-document-template';
import moment from 'moment';
import { InputDocumentTemplate } from '../documents/template/input-document-template';
import { templateControls } from '../documents/template/document-template-utility';
interface UpdateDocByClientProps {
    htmlFileName: string
    controlErrors?: any
    showValidationError?: boolean
    docFieldMappings: any
    editDocId: string
    documentDetail: any
    token: string
    docParentObj: any
    onFinish(): void
    metaData: any
    template: any
}
export const UpdateDocByClient = (props: UpdateDocByClientProps) => {
    const classToCheckValueContainer = "contains-value";
    const [isUpdating, setIsUpdating] = useState(false);
    const [templateFocusEnable, setTemplateFocusEnable] = useState();
    const [newControl, setNewControl] = React.useState({
        name: "",
        text: "",
        textHtml: "",
    });
    const [showValidationError, setShowValidationError] = useState(false)
    const [controlErrors, setControlErrors] = useState([]);
    const clinicLogo = useSelector((states: ComponentState) => states.logoByte);
    const metaData = props.metaData
    const documentDetail = props.documentDetail
    const classRow = "row col-lg-11 ml-1 mt-3"
    const template = documentDetail.template
    const templateSystem = props.template
    const isHtmlFileTypeTemplate = documentDetail.isHtmlFileTypeTemplate
    const docTreatmentPlans = documentDetail.docTreatmentPlans
    const docSignature = documentDetail.docSignature ? documentDetail.docSignature : []
    const docClientSignature = documentDetail.docClientSignature ? documentDetail.docClientSignature : []
    function getValueUsingKey(key, type) {
        const documentFieldMapping = getDocumentFieldsMappings();
        for (const keyValue of documentFieldMapping) {
            if (
                type === templateControls.checkbox &&
                keyValue.keyName.startsWith(key)
            ) {
                return keyValue.keyValue;
            } else if (keyValue.keyName === key) {
                if (type === templateControls.datePicker) {
                    if (!keyValue || !keyValue.keyValue) {
                        return "";
                    } else if (
                        keyValue.keyValue.includes("month") ||
                        keyValue.keyValue.includes("date") ||
                        keyValue.keyValue.includes("year")
                    ) {
                        return "";
                    }
                } else if (type === templateControls.timePicker) {
                    if (
                        keyValue.keyValue.includes("hour") ||
                        keyValue.keyValue.includes("minute")
                    ) {
                        return "";
                    }
                }
                return keyValue.keyValue;
            }
        }
        return "";
    }
    function setControlErrorList() {

        let errors: any = [];

        if (templateSystem && (templateSystem?.controlList?.length > 0)) {

            for (const control of templateSystem.controlList) {
                const value = getValueUsingKey(control.id, control.type);

                if (control.isRequired && control.isClientFillable) {
                    if (!value) {
                        errors.push({
                            id: control.id,
                            required: true,
                            msg: `${control.title} is required.`,
                        });
                    }
                }

                if (control.minInputChar && control.minInputChar > 0 &&  control.isClientFillable) {
                    if (value.length < control.minInputChar) {
                        errors.push({
                            id: control.id,
                            minError: true,
                            msg: `${control.title} should contain atleast ${control.minInputChar} characters.`,
                        });
                    }
                }
            }
        }

        setControlErrors(errors);
        return errors;
    }

    function findNameValueOfChildRecursively(child) {
        if (!child.getAttribute) return {};

        let keyName = child.getAttribute("name");
        let keyValue = child.getAttribute("value") || child.value;

        if (
            child.getAttribute("type") === "radio" ||
            child.getAttribute("type") === "checkbox"
        ) {
            return child.checked ? { keyName, keyValue } : {};
        }
        else if (child.type === "select-one" && child.childNodes[0]) {
            return { keyName, keyValue: child.childNodes[0].value };
        }
        else if (keyName?.includes('datepicker')) {
            return { keyName: keyName, keyValue: child.childNodes[0].childNodes[0]['value'] };
        }
        
        else if (keyName) {
            return { keyName, keyValue };
        }
        for (const children of child.childNodes) {
            const ret = findNameValueOfChildRecursively(children);
            if (ret.keyName) {
                return ret;
            }
        }
        if (newControl) {
            let data = {
                keyName: newControl.name,
                keyValue: newControl.textHtml,
                plainText: newControl.text,
            };
            return data;
        }
        return {};
    }
    function getDocumentFieldsMappings() {
        const documentFieldsMappings: { keyName: string; keyValue: string; plainText: string }[] = [];
    
        for (const item of Array.from(document.getElementsByClassName("contains-value"))) {
            const { keyName, keyValue, plainText } = findNameValueOfChildRecursively(item);
            
            if (keyName) {
                documentFieldsMappings.push({
                    keyName,
                    keyValue: keyValue === 'month/day/year' ? '' : keyValue,
                    plainText
                });
            }
        }
        
        return documentFieldsMappings;
    }
    



    const saveDocData = async () => {
        setIsUpdating(true)
        let fieldsMapping = onDataChange(props?.htmlFileName, true);

        const documentFieldsMappings =
            isHtmlFileTypeTemplate === true
                ? fieldsMapping
                : getDocumentFieldsMappings();
        // const documentsFieldMapping = props.docParentObj.documentFieldsMappings;
        // let fieldArray: any[] = []
        // documentsFieldMapping?.forEach((mapping) => {
        //     const updatedValObj = fieldsMapping?.find((mappedData) => mappedData.keyName === mapping.keyName)
        //     if (updatedValObj) {
        //         fieldArray.push(updatedValObj)
        //     } else {
        //         fieldArray.push(mapping)
        //     }
        // })
        let anyError = false;
        if (templateSystem && !isHtmlFileTypeTemplate) {
            if (setControlErrorList().length > 0) {
                anyError = true;
            }
        }
        if (anyError) {
            setIsUpdating(false);
            setShowValidationError(true);
            return;
        }

        const updatedData = { ...props.docParentObj, documentFieldsMappings: documentFieldsMappings }

        delete updatedData['documentCommonControls'];
        const docSignatureResp: any = await customAxios.put<any, any>(`${API_ENDPOINTS.UPDATE_DOCUMENT}`, updatedData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + props.token
            },
        })
        if (docSignatureResp.status) {
            NotificationManager.success("Document is updated successfully");
            setTimeout(() => {
                props.onFinish()
            }, 2000)

        }
        setTimeout(() => {
            setIsUpdating(false)
        }, 2200)

    }

    function renderVerticalPair(headerText, contentText) {
        return <div className="col-4 p-0 mb-1 mt-1">
            <p className="col-11 mb-0 mt-0 px-0 printableHeader">{headerText}</p>
            <p className="col-11 ml-2 mb-0 mt-0 px-0 printableContent">{contentText}</p>
        </div>
    }

    function renderHeader(headerText) {
        return <p className="col-12 mb-0 mt-0 px-0 printableHeader">{headerText}</p>
    }

    function renderContent(contentText) {
        return <p className="col-12 ml-2 mb-0 mt-0 px-0 printableContent">{contentText}</p>
    }

    function renderUnits() {
        if (!documentDetail || !documentDetail.billingUnits || documentDetail.billingUnits === 0) {
            return <></>
        }
        return renderVerticalPair("Units", documentDetail.billingUnits)
    }

    function renderPlaceOfService() {
        let hasSomePOS = documentDetail.placeOfServiceList.length > 0
        return (
            <div className={classRow}>
                {renderHeader("Place of Service")}
                {!hasSomePOS && renderContent("No Place of Service")}
                {hasSomePOS && <span>
                    {documentDetail?.placeOfServiceList.map((obj) => {
                        return renderContent(obj.placeOfServiceName)
                    })}
                </span>
                }
            </div>
        )
    }

    function renderStaffSignature() {
        return docSignature?.length < 1
            ? <></>
            : docSignature.map((obj, index) => (
                <div
                    key={index}
                    className="col-12 border p-2 mb-3 mt-4 keep-together"
                >
                    <div className="labelFont">
                        <div className="">
                            <p className="mb-0 printableContent" >
                                Signed by:{" "}<span className="fw-500" >{obj.staffName}</span>
                                {obj?.staffCredentials && (
                                    <span className="staff-credentials" >({obj.staffCredentials})</span>
                                )}
                            </p>
                            <p className="mb-0 printableContent" >
                                Date:{" "}<span className="fw-500" >{moment(obj.dateSig).format("M/D/YYYY")}</span>
                            </p>
                        </div>
                        <img
                            className="signImage"
                            alt="demo"
                            src={"data:image/png;base64," + obj.signature}
                        />
                        <div>
                            <p className="f-10 mb-0" >
                                Electronically signed:{" "}<span className="fw-300" >{moment.utc(obj.utcDateCreated).local().format("M/D/YYYY hh:mm A")}</span>
                            </p>
                        </div>
                    </div>
                </div>
            ))
    }

    function renderClientSignature() {
        return docClientSignature?.length > 0
            ? docClientSignature?.map((obj, index) => (
                <div
                    key={index}
                    className="col-12 border p-2 mb-3 mt-4 keep-together labelFont"
                >
                    <div className="">
                        <div className="">
                            <p className="mb-0 printableContent" >
                                Signed by: <span className="fw-500" >
                                    {`${obj?.fullNameAtSig} ${obj.isParent === true ? "(parent-guardian)" : "(client)"}`}
                                </span>
                            </p>
                            <p className="mb-0 printableContent" >
                                Date: <span className="fw-500" >{moment(obj.sigDateTime).format("M/D/YYYY")}</span>
                            </p>
                        </div>
                        <img
                            className="signImage"
                            alt="demo"
                            src={"data:image/png;base64," + obj.signature}
                        />
                        <div className="">
                            <p className="f-10 mb-0" >
                                Electronically signed:<span className="fw-300" >
                                    {moment.utc(obj.utcDateCreated).local().format("M/D/YYYY hh:mm A")}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            ))
            : <></>
    }
    function renderTreatmentPlans() {
        return (
            <div className={classRow}>
                {renderHeader("Treatment Plan")}
                {docTreatmentPlans && docTreatmentPlans.length > 0 && (
                    <div className="col-12 p-0 mx-2">
                        {
                            docTreatmentPlans.map((plan, index) => {
                                const goalText = plan.goalName + (plan.goalDescription ? ` (${plan.goalDescription})` : ' (N/A)');
                                return (
                                    <div key={index} className="p-2 border mt-2">
                                        <p className="printableHeader">Goal:</p>
                                        <p className="printableContent">{goalText}</p>
                                        <p className="printableHeader">Obj:</p>
                                        <p className="printableContent">{plan.objectiveName}</p>
                                        <p className="printableHeader">Int:</p>
                                        <p className="printableContent">{plan.interventionName}</p>
                                    </div>
                                );
                            })
                        }
                    </div>
                )}
            </div>
        );
    }

    function renderTemplate() {
        return  <InputDocumentTemplate
                   key={''}
                   template={templateSystem}
                   classToCheckValueContainer={classToCheckValueContainer}
                   documentFieldsMappings={documentDetail?.documentFieldsMappings}
                   setNewControl={setNewControl}
                   controlErrors={controlErrors}
                   showValidationError={showValidationError}
                   focusEnable={templateFocusEnable}
                   setControlErrorList={''}
                   isFromCp={true}
                 />
    }



    return <div>

        <div className="row">
            <div className="col-md-12">
                <h6>{'Please Review and Complete the Pending Information in the Document.'}</h6>
                <div className="document-user-cover" style={{ background: "#FFFFFF" }}>
                    <ul className="d-flex align-items-center list-unstyled ml-3"  >
                        <li>
                            <p className="document-pdf-img">
                                <img src={"data:image/png;base64," + clinicLogo} className="user-pdf" alt="" />
                            </p>
                        </li>
                        <li >
                            <p className="printableBigHeader ml-3">{metaData?.clinicName}</p>
                            <p className="printableBigHeader ml-3">{documentDetail?.docTemplateName}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className={classRow} style={{ width: '125%' }}>
            {renderVerticalPair("Client", documentDetail?.clientNameDoc)}
            {renderVerticalPair("Staff", documentDetail?.createByStaffName)}
            {renderVerticalPair("Service Date", DateTimeHelper.formatDatePickerString(documentDetail?.serviceDateStr))}
            {
                template?.timeRecordingMethodId !== timeRecordingTypeEnum.notRequired &&
                renderVerticalPair(

                    template?.timeRecordingMethod === 'Duration' ? 'Time Only' :
                        template?.timeRecordingMethod === 'Actual Time' ? 'Actual Time' :
                            template?.timeRecordingMethod === 'Shift' ? 'Shift' :
                                'Time/Duration/Shift'
                    ,
                    documentDetail?.documentTimeRecording?.startTime && documentDetail?.documentTimeRecording?.endTime
                        ? convertTimeToLocally(documentDetail.documentTimeRecording) : documentDetail?.documentTimeRecording?.totalMinutes > 0
                            ? totHoursAndMinutes(documentDetail?.documentTimeRecording?.totalMinutes) : documentDetail?.documentTimeRecording?.shiftName
                                ? documentDetail?.documentTimeRecording?.shiftName : "No Service Time"
                )
            }
            {
                template?.showClientProgress &&
                renderVerticalPair("Progress", documentDetail ? documentDetail.clientProgress : '')
            }
            {
                template?.showClientDiags &&
                renderVerticalPair("Diagnosis", documentDetail ? documentDetail.clientDiagnosisName : "")
            }
            {
                template?.showVisitType &&
                renderVerticalPair("Visit Type", documentDetail ? (documentDetail.isFaceToFace ? "Face to Face" : documentDetail.isTelehealth ? "Secure Telehealth" : documentDetail.isIndirectVisit ? "Indirect" : documentDetail.isTelephone ? "Phone" : "") : null)
            }
            {/* {renderVerticalPair("Date Submission", DateTimeHelper.formatDatePickerString(documentDetail?.utcDateCreated))} */}
            {renderUnits()}
        </div>
        {
            template?.posType !== 'Not Required' && renderPlaceOfService()
        }
        {documentDetail?.specificPlaceOfService &&
            <div className={classRow} >
                {renderHeader("Specific Place")}
                {renderContent(documentDetail?.specificPlaceOfService)}
            </div>
        }
        {template?.showSiteOfService &&
            <div className={classRow} >
                {renderHeader("Location/Site of Service")}
                {renderContent(documentDetail?.siteName)}
            </div>
        }
        {
            template?.showServiceControl &&
            <div className={classRow} >
                {renderHeader("Service")}
                {renderContent(documentDetail?.serviceNameTemp)}
            </div>
        }

        {(template?.showTreatmentPlan) && renderTreatmentPlans()}

        <div className={classRow}>
            {isHtmlFileTypeTemplate &&
                <AddDocumentFileTemplate
                    onComponentLoaded={() => { }}
                    name={props?.htmlFileName}
                    controlErrors={props?.controlErrors}
                    showValidationError={props.showValidationError}
                    focusEnable={true}
                    selectedPatientList={[]}
                    isEdit={false}
                    onGoalsDataStateChange={() => { }}
                    preselctedGoalEdit={null}
                    isViewDoc={false}
                    dxPreselectedData={() => { }}
                    onDxStateChange={() => { }}
                    tpId={0}
                    isDraft={false}
                    draftCommonControlls={[]}
                    isViewMode={false}
                    docFieldMappings={props.docFieldMappings}
                    documentId={props.editDocId}
                    isClientEdit={true} />
            }
            {!isHtmlFileTypeTemplate && ((template && template.controlList) || (templateSystem && templateSystem?.controlList)) && renderTemplate()}
        </div>


        {(docSignature.length > 0 || docClientSignature.length > 0) &&
            <>
                Signature
                <div className="dmr">
                    <div className={classRow} >
                        {renderHeader("Signature")}
                        {renderStaffSignature()}
                        {renderClientSignature()}
                    </div>
                </div>
            </>}




        <button disabled={isUpdating} type="submit" onClick={saveDocData} className="submitButon mt-2">
            {isUpdating ? <Loader style={{ color: 'white' }} /> : " Save Document"}
        </button>

    </div >


}