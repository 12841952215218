import React, { forwardRef, useEffect, useState } from "react";
import dropdownIcon from "../../assets/images/dropdowmIMage.png";
import { useDispatch, useSelector } from "react-redux";
import { getStaffReportsWithTypes } from "src/redux/actions";

type ReportDrawerContainerProps = {
  onStateChange: (state: any) => void;
};

const ReportDrawerContainer = forwardRef<HTMLDivElement, ReportDrawerContainerProps>(({ onStateChange }, ref) => {
  const [selected, setSelected] = useState();
  const { staffReportWithTypeResp, fetchReportTypes } = useSelector(
    (state: any) => state.StaffReducer
  );
  const staffId = useSelector((state: any) => state.loggedIn?.staffId);
  const dispatch = useDispatch();

  const handleStateChange = (state) => {
    setSelected(state);
    onStateChange(state);
  };

  useEffect(() => {
    if (fetchReportTypes) {
      const data = {
        staffId: staffId
      }
      dispatch(getStaffReportsWithTypes(data));
    }
  }, [fetchReportTypes, dispatch]);

  useEffect(() => {
    if (staffReportWithTypeResp.length > 0) {
      setSelected(staffReportWithTypeResp[0]?.reports[0]?.reportId)
    }
  }, [staffReportWithTypeResp])

  return (
    <div className="side-bar-left reportSideBar">
      <div id="accordion" className="staffMessage my-2">
        {staffReportWithTypeResp &&
          staffReportWithTypeResp.map((type) => (
            <div key={type.id}>
              <div id={`heading${type.id}`}>
                <h5 className="mb-0 text-left position-relative">
                  <button
                    className="btn w-100 text-left p-0"
                    data-toggle="collapse"
                    data-target={`#collapse${type.id}`}
                    aria-expanded="true"
                    aria-controls={`collapse${type.id}`}
                  >
                   {(type.reports && type.reports.length>0) && <img
                      src={dropdownIcon}
                      alt="dropdown"
                      className="dropDownCollapse"
                    />}
                    {type.reportTypeName}
                  </button>
                </h5>
              </div>
              <div
                id={`collapse${type.id}`}
                className="collapse show"
                aria-labelledby={`heading${type.id}`}
              >
                <ul
                  style={{ listStyle: "none", padding: "10px", paddingLeft:"3px" }}
                  className="k-drawer-items cursor-pointer"
                >
                  {type.reports.map((item) => (
                    <li
                      key={item.reportId}
                      className={`staff-text d-flex justify-content-between w-100 ${selected === item.reportId ? "k-selected" : ""
                        }`}
                      onClick={() => handleStateChange(item.reportId)}
                    >
                      <div>{item.reportName}</div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
});

export default ReportDrawerContainer;
