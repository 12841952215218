import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DateTimeHelper from "src/helper/date-time-helper";
import { permissionEnum } from "src/helper/permission-helper";
import { AddDocumentFileTemplate } from "../add-document-file-template";
import { convertTimeToLocally, timeRecordingTypeEnum, totHoursAndMinutes } from "../document-utility";
import { ACTION_ID } from "../list-document-history";
import { PreviewDocumentTemplate } from "../template/preview-document-template";
import { levelOfRisk } from "../view-document";
import './document-styles.css';
import { styles } from "./style";
import { red } from "@mui/material/colors";
import CapNotesServiceGoals from "../cap-notes-goals-section";

function DocumentTemplatePdfItem({
  onComponentLoaded,
  documentDetail,
  diagnosisList,
  docSignature,
  docClientSignature,
  isHtmlFileTypeTemplate,
  htmlFileName,
  template,
  fieldsMapping,
  documentId,
  docTreatmentPlans,
  historyList,
  docHasControlls,
  documentCommonControls,
  mileagePayRate,
  metaData,
  docIndex = 0,
  capNoteData,
  capServiceName
}) {
  const _elementRef = useRef(null);
  const state = useSelector(states => {
    return states;
  });
  const currencyFormat = (num) => {
    const sign = num < 0 ? '-' : '';
    const formattedNumber = Math.abs(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    return `${sign}$${formattedNumber}`;
  };

  const userAccessPermission = useSelector(
    (state) => state.userAccessPermission
  );
  const clinicLogo = useSelector((states) => states.logoByte);

  const classRow = "row col-lg-11 ml-1 mt-3"
  const [riskListData, setRiskListData] = useState([])

  const riskList = (() => { try { return JSON.parse(localStorage.getItem('riskList')) || null; } catch { return null; } })();
  const riskFactorList = (() => { try { return JSON.parse(localStorage.getItem('riskFactorList')) || null; } catch { return null; } })();
  const riskProtectiveList = (() => { try { return JSON.parse(localStorage.getItem('riskProtectiveList')) || null; } catch { return null; } })();

  useEffect(() => {
    if (documentDetail.riskAssessment?.riskAssessmentDetails.length > 0) {
      setRiskListData(documentDetail.riskAssessment?.riskAssessmentDetails?.map((item) => {

        const areaLevel = levelOfRisk?.find((data) => {
          return data?.value === item?.riskLevelId
        })
        const areaRisk = riskList?.find((data) => {
          return data?.id === item?.areaRiskId
        })
        // Map protective factors IDs to corresponding objects
        const protectiveFactors = item.protectiveFactorIds?.map((protectiveFactorId) => {
          return riskProtectiveList?.find((data) => data.id === protectiveFactorId);
        });

        // Map risk factors IDs to corresponding objects
        const riskFactors = item.riskFactorIds?.map((riskFactorId) => {
          return riskFactorList?.find((data) => data.id === riskFactorId);
        });
        return {
          documentRiskId: item?.documentRiskId,
          riskArea: areaRisk,
          riskLevelId: areaLevel,
          intentToAct: item?.intentToAct,
          meansToAct: item?.meansToAct,
          planToAct: item?.planToAct,
          protectiveFactors: protectiveFactors,
          riskFactors: riskFactors,
          additionalInfo: item?.additionalInfo
        }
      }))
    }

  }, [documentDetail?.riskAssessment])


  function renderCommonControlGoalObjectiveInterventions(interventions) {
    if (!interventions || interventions.length < 1) {
      return <></>
    }
    return <>
      {interventions.map((intervention, index) => {

        const commaSeparatedServiceTypes = intervention.serviceTypes?.map(item => item.serviceTypes).join(", ");
        const commaSeparatedStaffTypes = intervention.staffTypes?.map(item => item.name).join(", ");

        return <div key={index} className=''>
          {renderHeader("Intervention")}
          {renderContent(intervention.intervention)}
          {renderHeader("Service Type")}
          {renderContent(commaSeparatedServiceTypes)}
          {renderHeader("Staff Type")}
          {renderContent(commaSeparatedStaffTypes)}
          {renderHeader("Frequency:")}
          {renderContent(`${intervention.numFreq} ${intervention.freq}`)}
        </div>
      })
      }
    </>
  }

  function renderCommonControlGoalObjectives(objectives) {
    if (!objectives || objectives.length < 1) {
      return <></>
    }
    return <>
      {objectives.map((objective, index) => {
        return <div key={index} className=''>
          {index != 0 && <hr className="hr-line" />}
          {renderHeader("Objective")}
          {renderContent(objective.objective)}
          {renderCommonControlGoalObjectiveInterventions(objective.interventions)}
        </div>
      })
      }
    </>
  }

  function renderCommonControlGoals() {
    if (!(documentCommonControls?.documentGoals && documentCommonControls?.documentGoals.length > 0)) {
      return <></>
    }
    return (<div className={classRow} style={{ marginLeft: '15px' }}>
      {renderHeader("Goals")}
      <div className="col-12 p-0  mt-2">
        {
          documentCommonControls?.documentGoals.map((goal, index) => {
            return <div key={index} className="p-2 border">
              {renderHeader("Goal Name")}
              {renderContent(goal.goalName)}
              {renderHeader("Start Date")}
              {renderContent(goal.startDate && moment(goal.startDate).format('M/D/YYYY'))}
              {renderHeader("Target Date")}
              {renderContent(goal.targetDate && moment(goal.targetDate).format('M/D/YYYY'))}
              {renderHeader("Description")}
              {renderContent(goal.goalDescription)}
              {renderCommonControlGoalObjectives(goal.objectives)}
            </div>
          })
        }
      </div >
    </div >)
  }

  function renderCommonControlDiagnosis() {
    if (!documentCommonControls?.documentDiagnosis || documentCommonControls?.documentDiagnosis.length < 1) {
      return <></>
    }
    return (
      <div className={classRow} style={{ marginLeft: '15px' }}>
        {renderHeader("Diagnosis")}
        <div className="col-12 p-0 ">
          {
            documentCommonControls?.documentDiagnosis.map((selectedDx, index) => {
              return <div key={index} className="p-2 border mt-2">
                {renderHeader("Dx")}
                {renderContent(selectedDx.diagnosisName)}
                {renderHeader("Date Diagnose")}
                {renderContent(moment(selectedDx.dateDiagnose).format('M/D/YYYY'))}
              </div>
            })
          }
        </div>
      </div>)
  }

  function renderRiskAssessment() {
    const hasRiskAssessments = documentDetail.riskAssessment?.riskAssessmentDetails &&
      documentDetail.riskAssessment?.riskAssessmentDetails.length > 0;
    return (
      <div className={classRow} style={{ marginLeft: '15px' }}>
        {renderHeader("Risk Assessment")}
        {!hasRiskAssessments && renderContent("NA")}
        {hasRiskAssessments && (
          <div className="col-12 p-0 ">{riskListData?.map((risk, index) => {
            return <div key={index} className="p-2 border">
              <p className="printableHeader">Area of Risk: </p>
              <p className="printableContent">{risk.riskArea?.name}</p>
              <p className="printableHeader ">Level of Risk: </p>
              <p className="printableContent">{risk.riskLevelId?.label}</p>
              <p className="printableHeader">Intent to Act: </p>
              <p className="printableContent">{risk.intentToAct}</p>
              <p className="printableHeader ">Plan to Act: </p>
              <p className="printableContent">{risk.planToAct}</p>
              <p className="printableHeader">Means to Act: </p>
              <p className="printableContent">{risk.meansToAct}</p>
              <p className="printableHeader ">Risk factors: </p>
              <p className="printableContent">{risk.riskFactors && risk.riskFactors.length > 0 ? (
                <ul>
                  {risk.riskFactors.map((item, index) => (
                    <li key={index}>{item?.name}</li>
                  ))}
                </ul>
              ) : (
                <p>No risk factors found.</p>
              )}</p>
              <p className="printableHeader">Protective Factors: </p>
              <p className="printableContent">{risk.protectiveFactors && risk.protectiveFactors.length > 0 ? (
                <ul>
                  {risk.protectiveFactors.map((item, index) => (
                    <li key={index}>{item?.name}</li>
                  ))}
                </ul>
              ) : (
                <p>No protective factors found.</p>
              )}</p>
              <p className="printableHeader">Additional Information: </p>
              <p className="printableContent">{risk.additionalInfo}</p>
            </div>
          })}</div>)}
      </div>
    );
  }



  function renderTreatmentPlans() {
    return (
      <div className={classRow} style={{ marginLeft: '15px' }}>
        {renderHeader("Treatment Plan")}
        {docTreatmentPlans && docTreatmentPlans.length > 0 && (
          <div className="col-12 p-0 ">
            {
              docTreatmentPlans.map((plan, index) => {
                const goalText = plan.goalName + (plan.goalDescription ? ` (${plan.goalDescription})` : ' (N/A)');
                return (
                  <div key={index} className="p-2 border mt-2">
                    <p className="printableHeader">Goal:</p>
                    <p className="printableContent">{goalText}</p>
                    <p className="printableHeader">Obj:</p>
                    <p className="printableContent">{plan.objectiveName}</p>
                    <p className="printableHeader">Int:</p>
                    <p className="printableContent">{plan.interventionName}</p>
                  </div>
                );
              })
            }
          </div>
        )}
      </div>
    );
  }

    function renderMileageSection() {
      return (
        <div className={classRow} style={{ marginLeft: '15px' }}>
          {renderHeader("Document Mileage")}
          {documentCommonControls?.documentMileages?.length > 0 ? (
            <div className="col-12 p-0 ">
              {documentCommonControls?.documentMileages?.map((mileage, index) => (
                <div key={index} className="p-2 border mt-2">
                  {mileage.totalMileage > 0 ? (
                    <>
                      <p className="printableHeader">Mileage:</p>
                      <p className="printableContent">
                        {mileage.mileageTypeId === 1 ? "By Address" : "By Odometer"}
                      </p>
                      {mileage.mileageTypeId === 1 ? (
                        <>
                          <p className="docTitle">Start Address:</p>
                          <p className="docContent">{mileage.startAddress}</p>
                          <p className="docTitle">Destination Address:</p>
                          <p className="docContent">{mileage.endAddress}</p>
                          <p className="docTitle">Total Mileage:</p>
                          <p className="docContent">{mileage.totalMileage} mi</p>
                          <p className="docTitle">Total Amount:</p>
                          <p className="docContent">
                            {mileage?.totalMileage && mileagePayRate?.rateAmt
                              ? currencyFormat(mileage.totalMileage * mileagePayRate.rateAmt)
                              : "Calculating..."}
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="docTitle">Start Odometer:</p>
                          <p className="docContent">{mileage.odoStart} mi</p>
                          <p className="docTitle">End Odometer:</p>
                          <p className="docContent">{mileage.odoEnd} mi</p>
                          <p className="docTitle">Total Mileage:</p>
                          <p className="docContent">{mileage.totalMileage} mi</p>
                          <p className="docTitle">Total Amount:</p>
                          <p className="docContent">
                            {mileage?.totalMileage && mileagePayRate?.rateAmt
                              ? currencyFormat(mileage.totalMileage * mileagePayRate.rateAmt)
                              : "Calculating..."}
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <p className="docContent">N/A</p>
                  )}
                </div>
              ))}
            </div>
          ) : (
            <p className="docContent">N/A</p>
          )}
        </div>
      );
    }


  function renderTemplate() {
    return <PreviewDocumentTemplate
      controlList={template.controlList}
      documentFieldsMappings={documentDetail.documentFieldsMappings}
      isPrintMode={true}
      isViewMode={false}
    />;
  }



  function renderStaffSignature() {
    return docSignature.length < 1
      ? renderContent("---")
      : docSignature.map((staffDocSign, index) => (
        <div
          key={index}
          className="col-12 border p-2 mb-3 mt-4 keep-together"
        >
          <div className="labelFont">
            <div className="">
              <p className="mb-0 printableContent" >
                {staffDocSign.staffId === documentDetail?.createdBy
                  ? "Document Author" : "Co-Signing Staff"}:{" "}<span className="fw-500" >{staffDocSign.staffName}</span>
                {staffDocSign?.staffCredentials && (
                  <span className="staff-credentials" >{`, ${staffDocSign.staffCredentials}`}</span>
                )}
              </p>
              {staffDocSign?.position && <p className="mb-0 printableContent">
                {staffDocSign.position}
              </p>}
              <p className="mb-0 printableContent" >
                Date:{" "}<span className="fw-500" >{moment(staffDocSign.dateSig).format("M/D/YYYY")}</span>
              </p>
            </div>
            <img
              className="signImage"
              alt="demo"
              src={"data:image/png;base64," + staffDocSign.signature}
            />
            <div>
              <p className="f-10 mb-0" >
                Electronically signed:{" "}<span className="fw-300" >{moment.utc(staffDocSign.utcDateCreated).local().format("M/D/YYYY hh:mm A")}</span>
              </p>
            </div>
          </div>
        </div>
      ))
  }

  function renderClientSignature() {
    return docClientSignature?.length > 0
      ? docClientSignature?.map((obj, index) => (
        <div
          key={index}
          className="col-12 border p-2 mb-3 mt-4 keep-together labelFont"
        >
          <div className="">
            <div className="">
              <p className="mb-0 printableContent" >
                Signed by: <span className="fw-500" >
                  {`${obj?.fullNameAtSig} ${obj.isParent === true ? "(parent-guardian)" : "(client)"}`}
                </span>
              </p>
              <p className="mb-0 printableContent" >
                Date: <span className="fw-500" >{moment(obj.sigDateTime).format("M/D/YYYY")}</span>
              </p>
            </div>
            <img
              className="signImage"
              alt="demo"
              src={"data:image/png;base64," + obj.signature}
            />
            <div className="">
              <p className="f-10 mb-0" >
                Electronically signed:<span className="fw-300" >
                  {moment.utc(obj.utcDateCreated).local().format("M/D/YYYY hh:mm A")}
                </span>
              </p>
            </div>
          </div>
        </div>
      ))
      : <></>
  }


  function renderHeader(headerText) {
    return <p className="col-12 mb-0 mt-0 px-0 printableHeader">{headerText}</p>
  }

  function renderContent(contentText) {
    return <p className="col-12 ml-2 mb-0 mt-0 px-0 printableContent">{contentText}</p>
  }

  function renderVerticalPair(headerText, contentText) {
    return <div className="col-4 p-0 mb-1 mt-1">
      <p className="col-11 mb-0 mt-0 px-0 printableHeader">{headerText}</p>
      <p className="col-11 ml-2 mb-0 mt-0 px-0 printableContent">{contentText}</p>
    </div>
  }

  function renderUnits() {
    if (!documentDetail || !documentDetail.billingUnits || documentDetail.billingUnits == 0) {
      return <></>
    }
    return renderVerticalPair("Units", documentDetail.billingUnits)
  }

  function renderCommonControlls() {
    return (
      <div className="">
        {renderCommonControlGoals()}
        {renderCommonControlDiagnosis()}
      </div>
    );
  }

  function renderPlaceOfService() {
    let hasSomePOS = documentDetail.placeOfServiceList.length > 0
    return (
      <div className={classRow} style={{ marginLeft: '15px' }}>
        {renderHeader("Place of Service")}
        {!hasSomePOS && renderContent("No Place of Service")}
        {hasSomePOS && <span>
          {documentDetail?.placeOfServiceList.map((obj) => {
            return renderContent(obj.placeOfServiceName)
          })}
        </span>
        }
      </div>
    )
  }

  function renderAdditionalInfo(action) {
    let oldVersion = action.oldVersion;
    let actionId = action.actionId;
    let parsedInfo = JSON.parse(oldVersion)
    if (action.actionId === ACTION_ID.signed || action.actionId === ACTION_ID.documentSignByClient || action.actionId === ACTION_ID.documentSignByParent) {
      var imgUrl = 'data:image/jpeg;base64,' + action.signature;
      return (<td><img src={imgUrl} style={{ height: "50px", width: "100px" }} /></td>)
    }
    else {
      return (<td className="printableContent text-wrap">
        {
          actionId == ACTION_ID.billingStatusUpdated &&
          <>
            {"Changed from " + parsedInfo?.oldBillingStatus + " to " + parsedInfo?.newBillingStatus}
          </>

        }
        {
          actionId == ACTION_ID.serviceUpdated &&
          <>
            {"Changed from " + parsedInfo?.oldServiceName + " to " + parsedInfo?.newServiceName}
          </>
        }
        {
          actionId == ACTION_ID.dxUpdated &&
          <>
            {"Changed from " + parsedInfo?.oldDxName + " to " + parsedInfo?.newDxName}
          </>
        }
        {
          actionId == ACTION_ID.serviceSiteUpdated &&
          <>
            {"Changed from " + parsedInfo?.oldServiceSiteName + " to " + parsedInfo?.newServiceSiteName}
          </>
        }
        {
          actionId == ACTION_ID.serviceDateUpdated &&
          <>
            {"Changed from " + parsedInfo?.oldServiceDate + " to " + parsedInfo?.newServiceDate}
          </>
        }
      </td>);
    }
  }

  function renderHistory() {
    if (!historyList || historyList.length < 1) {
     
      return <></>
    }

    return (
      <div className="table-responsive table_view_dt mt-3 ml-3 doc-history blueThemeTable ">
        {renderHeader("Document History")}
        <table className="table col-11 k-grid ">
          <thead>
            <tr>
              <th scope="col" className="tableContent" >Action</th>
              <th scope="col" className="tableContent">By Staff</th>
              <th scope="col" className="tableContent">Date Time</th>
              <th scope="col" className="tableContent">Additional Info</th>
            </tr>
          </thead>
          <tbody>
            {historyList.map((item, index) => {

              const dateTime = moment.utc(item.utcDateCreated).local()
              return <tr key={index} >
                <td className="printableContent text-wrap">{item.actionName}</td>
                <td className="printableContent text-wrap">{item.staffName}</td>
                <td className="printableContent text-wrap">
                  {dateTime.format("M/D/YYYY")}
                  <br />
                  {dateTime.format("hh:mm A")}
                </td>
                {renderAdditionalInfo(item)}
              </tr>
            })}
          </tbody>
        </table>
      </div >
    );
  }

  return (
    <div>
      <style> {styles}</style>
      <div className="row">
        <div className="col-md-12">
          <div className="document-user-cover" style={{ background: "#FFFFFF" }}>
            <ul className="d-flex align-items-center list-unstyled ml-3"  >
              <li>
                <p className="document-pdf-img">
                  <img src={"data:image/png;base64," + clinicLogo} className="user-pdf" alt="" />
                </p>
              </li>
              <li >
                <p className="printableBigHeader ml-3">{metaData?.clinicName}</p>
                <p className="printableBigHeader ml-3">{documentDetail?.docTemplateName}</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className={classRow} style={{ width: '100%', marginLeft: '15px' }}>
        {renderVerticalPair("Client", documentDetail?.clientNameDoc)}
        {renderVerticalPair("Staff", documentDetail?.createByStaffName)}
        {renderVerticalPair("Service Date", DateTimeHelper.formatDatePickerString(documentDetail?.serviceDateStr))}
        {
          template?.timeRecordingMethodId !== timeRecordingTypeEnum.notRequired &&
          renderVerticalPair(

            template?.timeRecordingMethod == 'Duration' ? 'Time Only' :
              template?.timeRecordingMethod == 'Actual Time' ? 'Actual Time' :
                template?.timeRecordingMethod == 'Shift' ? 'Shift' :
                  'Time/Duration/Shift'
            ,
            documentDetail?.documentTimeRecording?.startTime && documentDetail?.documentTimeRecording?.endTime
              ? convertTimeToLocally(documentDetail.documentTimeRecording) : documentDetail?.documentTimeRecording?.totalMinutes > 0
                ? totHoursAndMinutes(documentDetail?.documentTimeRecording?.totalMinutes) : documentDetail?.documentTimeRecording?.shiftName
                  ? documentDetail?.documentTimeRecording?.shiftName : "No Service Time"
          )
        }
        {
          template?.showClientProgress &&
          renderVerticalPair("Progress", documentDetail ? documentDetail.clientProgress : '')
        }
        {
          template?.showClientDiags &&
          renderVerticalPair("Diagnosis", documentDetail ? documentDetail.clientDiagnosisName : "")
        }
        {
          template?.showVisitType &&
          renderVerticalPair("Visit Type", documentDetail ? (documentDetail.isFaceToFace ? "Face to Face" : documentDetail.isTelehealth ? "Secure Telehealth" : documentDetail.isIndirectVisit ? "Indirect" : documentDetail.isTelephone ? "Phone" : "") : null)
        }
        {/* {renderVerticalPair("Date Submission", DateTimeHelper.formatDatePickerString(documentDetail?.utcDateCreated))} */}
        {renderUnits()}
      </div>
      {
        template?.posType != 'Not Required' && renderPlaceOfService()
      }
      {documentDetail?.specificPlaceOfService &&
        <div className={classRow} style={{ marginLeft: '15px' }}>
          {renderHeader("Specific Place")}
          {renderContent(documentDetail?.specificPlaceOfService)}
        </div>
      }
      {
        template?.showServiceControl &&
        <div className={classRow} style={{ marginLeft: '15px' }}>
          {renderHeader("Service")}
          {renderContent(documentDetail?.capServiceName?documentDetail.capServiceName: documentDetail?.serviceNameTemp)}
        </div>
      }

      {(template?.showTreatmentPlan) && renderTreatmentPlans()}
      {(template?.showMileage) && renderMileageSection()}
      {template?.showRiskAssessment && renderRiskAssessment()}
      {(docHasControlls) && renderCommonControlls()}
      {(documentDetail?.capNoteData?.capGoals) &&
                 <>
                  <h6 className="docTitle">{`CAP Services`}</h6>
                    <CapNotesServiceGoals
                      isSavePressed={false}
                      setCapNoteData={() => { }}
                      isViewMode={true}
                      preselectedData={documentDetail?.capNoteData?.capGoals ? documentDetail?.capNoteData?.capGoals : []}
                    />
                   </>

                  }
      {/* Templates */}
      <div className={classRow} style={{ marginLeft: '15px' }}>
        {isHtmlFileTypeTemplate &&
          <AddDocumentFileTemplate
            onComponentLoaded={onComponentLoaded}
            controlErrors={null}
            showValidationError={null}
            focusEnable={null}
            name={htmlFileName}
            selectedPatientList={[{ id: documentDetail?.clientId, name: documentDetail?.clientName }]}
            isEdit={false}
            onGoalsDataStateChange={() => { }}
            isViewDoc={true}
            preselctedGoalEdit={documentCommonControls?.documentGoals}
            dxPreselectedData={documentCommonControls?.documentDiagnosis}
            onDxStateChange={() => { }}
            docIndex={docIndex}
          />}
        {!isHtmlFileTypeTemplate && template && template.controlList && renderTemplate()}
      </div>

      {renderHistory()}

      {/* Signature */}
      <div className="dmr">
      <div className={classRow} style={{ marginLeft: '15px' }}>
          {renderHeader("Signature")}
          {renderStaffSignature()}
          {renderClientSignature()}
        </div>
      </div>
    </div >
  );
}
export default DocumentTemplatePdfItem;