import { takeLatest, put, takeEvery } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { SaveServiceSetDDL, loaderAction, saveStaffList, saveStaffNotificationSetting ,saveStaffTaskNotificationSetting,saveStaffNotificationMedium ,saveStaffCredentialList,getStaffCredentialList, saveStaffAuditLogList, saveStaffAuditLogMetaData, staffAuditLogListChangedAction, fetchStaffListAgainAction, saveCaseloadReportToExcel,saveStaffNewNotificationSetting,saveStaffComment,getStaffComment, saveAllStaffReports, saveStaffReports, fetchStaffReportsAction, fetchAllStaffReportsAction, saveReportsByReportType, saveReportTypes, fetchReportTypes, saveReportColumns, saveRoleReports, saveStaffReportColumnPreferences, getStaffReportColumnPreferences, getAssignedStaffOfReport, saveAssignedStaffOfReport, saveStaffReportsWithTypes, getRoleReports, saveStaffSchedulerNotificationSetting,saveStaffServiceReportByStaffId, saveStaffDDLByClinicId} from '../actions';
import { ServiceSetDDLResponse,DeleteStaffCredentialResponse,getStaffCredentialListResponse,StaffServiceReportResponse } from 'src/dataModels/staffResponsesModels';
import { staffServicess } from 'src/services/staffServices';
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';
import { StaffResponse } from 'src/dataModels/clientResponse';

export function* GetServiceSetDDLSaga() {
    yield takeLatest(TYPES.GET_SERVICE_SET_DDL, ServiceSetDDLWorker)
}
function* ServiceSetDDLWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ServiceSetDDLResponse = yield staffServicess.serviceSetDDL(param['payload'])
        yield put(SaveServiceSetDDL(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* GetStaffNotificationSettingSaga() {
    yield takeLatest(TYPES.GET_STAFF_NOTIFICATION_SETTING, GetStaffNotificationSettingWorker)
}
function* GetStaffNotificationSettingWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ServiceSetDDLResponse = yield staffServicess.getstaffNotificationSetting(param['payload'])
        yield put(saveStaffNotificationSetting(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* GetStaffTaskNotificationSettingSaga() {
  yield takeLatest(TYPES.GET_STAFF_TASK_NOTIFICATION_SETTING, GetStaffTaskNotificationSettingWorker)
}
function* GetStaffTaskNotificationSettingWorker(param: any) {
  try {
      yield put(loaderAction(true));
      const response: ServiceSetDDLResponse = yield staffServicess.getstaffNotificationSetting(param['payload'])
      yield put(saveStaffTaskNotificationSetting(response));
      yield put(loaderAction(false));
  }
  catch (err) {
      yield put(loaderAction(false));

  }
}

export function* GetStaffSchedulerNotificationSettingSaga() {
  yield takeLatest(TYPES.GET_STAFF_SCHEDULER_NOTIFICATION_SETTING, GetStaffSchedulerNotificationSettingWorker)
}
function* GetStaffSchedulerNotificationSettingWorker(param: any) {
  try {
      yield put(loaderAction(true));
      const response: ServiceSetDDLResponse = yield staffServicess.getstaffNotificationSetting(param['payload'])
      yield put(saveStaffSchedulerNotificationSetting(response));
      yield put(loaderAction(false));
  }
  catch (err) {
      yield put(loaderAction(false));

  }
}

export function* GetStaffNewNotificationSettingSaga() {
    yield takeLatest(TYPES.GET_STAFF_NEW_NOTIFICATION_SETTING, GetStaffNewNotificationSettingWorker)
}
function* GetStaffNewNotificationSettingWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ServiceSetDDLResponse = yield staffServicess.getstaffNotificationSetting(param['payload'])
        yield put(saveStaffNewNotificationSetting(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}
export function* SaveStaffNotificationSaga() {
    yield takeLatest(TYPES.SAVE_STAFF_NOTIFICATION, GetStaffNotificationWorker)
}
function* GetStaffNotificationWorker(param: any) {
    try {
        yield put(loaderAction(true));
        yield staffServicess.savestaffNotification(param['payload'])
        NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getStaffListSaga() {
    yield takeLatest(TYPES.GET_STAFF_LIST, staffListWorker);
}

function* staffListWorker() {
    try {
        // yield put(loaderAction(true));
        const response: StaffResponse = yield staffServicess.staffList();
        // yield put(loaderAction(false));
        yield put(saveStaffList(response.resultData));
    } catch (err) {
    }
}
export function* GetStaffNotificationMediumSettingSaga() {
    yield takeLatest(TYPES.GET_STAFF_NOTIFICATION_MEDIUM_SETTING, GetStaffNotificationMediumSettingWorker)
}
function* GetStaffNotificationMediumSettingWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ServiceSetDDLResponse = yield staffServicess.getstaffNotificationMediumSetting(param['payload'])
        yield put(saveStaffNotificationMedium(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* SaveStaffNotificationMediumSaga() {
    yield takeLatest(TYPES.SAVE_STAFF_NOTIFICATION_MEDIUM_SETTING, SaveStaffNotificationMediumSagaWorker)
}
function* SaveStaffNotificationMediumSagaWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.saveStaffNotificationMediumSettings(param['payload'])
        if (response !== undefined)
            NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* SaveStaffCredentialInfoSaga() {
    yield takeLatest(TYPES.SAVE_STAFF_CREDENTIAL_INFO, SaveStaffCredentialInfoWorker)
}
function* SaveStaffCredentialInfoWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.SaveStaffCredentialInfo(param['payload'])
        if (response !== undefined)
            NotificationManager.success(NOTIFICATION_MESSAGE.ADD_CREDENTIAL);
        const staffId = param['payload']?.staffId;
        yield put(getStaffCredentialList(staffId));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* DeleteStaffCredentialSaga() {
    yield takeLatest(TYPES.DELETE_STAFF_CREDENTIAL, DeleteStaffCredentialWorker)
}
function* DeleteStaffCredentialWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: DeleteStaffCredentialResponse = yield staffServicess.DeleteStaffCredential(param['payload']?.deleteCredentialId)
        if (response.statusCode === 200) {

            NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_CREDENTIAL);
        }
        const staffId = param['payload']?.staffId;
        yield put(getStaffCredentialList(staffId));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* GetStaffCredentialListSaga() {
    yield takeLatest(TYPES.GET_STAFF_CREDENTIAL_LIST, GetStaffCredentialListWorker)
}
function* GetStaffCredentialListWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: getStaffCredentialListResponse = yield staffServicess.getStaffCredentialList(param['payload'])
        yield put(saveStaffCredentialList(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* updateStaffCredentialSaga() {
    yield takeLatest(TYPES.UPDATE_STAFF_CREDENTIAL, updateStaffCredentialWorker)
}
function* updateStaffCredentialWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.updateStaffCredential(param['payload'])
        if (response.statusCode === 200) {

            NotificationManager.success(NOTIFICATION_MESSAGE.UPDATE_CREDENTIAL);
        }
        const staffId = param['payload']?.staffId;
        yield put(getStaffCredentialList(staffId));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* getStaffAuditLogSaga() {
    yield takeLatest(TYPES.GET_STAFF_AUDIT_LOG_LIST, getStaffAuditLogWorker)
}
function* getStaffAuditLogWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.getStaffAuditLogList(param['payload'])
        if (response.statusCode === 200) {
            yield put(saveStaffAuditLogList(response.resultData));
            yield put(saveStaffAuditLogMetaData(response.metaData));
            yield put(staffAuditLogListChangedAction(false));
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* assignAdditionalClientsToStaffSaga() {
    yield takeLatest(TYPES.ASSIGN_ADDITIONAL_CLIENTS_TO_STAFF, assignAdditionalClientsToStaffWorker)
}
function* assignAdditionalClientsToStaffWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.assignAdditionalClientsToStaff(param['payload'])
        if (response.statusCode === 200) {
           NotificationManager.success("Clients assigned successfully")
           yield put(fetchStaffListAgainAction(true))
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* assignStaffToSiteSaga() {
    yield takeLatest(TYPES.ASSIGN_STAFF_TO_SITE, assignStaffToSiteWorker)
}
function* assignStaffToSiteWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.assignStaffToSite(param['payload'])
        if (response.statusCode === 200) {
           NotificationManager.success("Site assigned successfully")
           yield put(fetchStaffListAgainAction(true))
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* exportCaseloadReportToExcelSaga() {
    yield takeLatest(TYPES.EXPORT_CASELOAD_REPORT_TO_EXCEL, exportCaseloadReportToExcelWorker)
}
function* exportCaseloadReportToExcelWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.getCaseloadReportExcel(param['payload'])
        if (response.statusCode === 200) {
           yield put(saveCaseloadReportToExcel(response))
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getStaffCommentSaga() {
    yield takeLatest(TYPES.GET_STAFF_COMMENT, getStaffCommentWorker);
  }
  function* getStaffCommentWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getStaffComment(param['payload']);
      yield put(saveStaffComment(response?.resultData));
      yield put(loaderAction(false));
    }
    catch (err) {
    }
  }
  
  export function* addUpdateStaffCommentSaga() {
    yield takeLatest(TYPES.SAVE_UPDATE_STAFF_COMMENT, addUpdateStaffCommentWorker);
  }
  
  function* addUpdateStaffCommentWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.addUpdateStaffComment(param['payload']);
      if(response && response?.status) {
        NotificationManager.success(NOTIFICATION_MESSAGE.UPDATE_STAFF_COMMENT);
        const staffId = param['payload']?.staffId;
        yield put(getStaffComment(staffId));
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }


  export function* getStaffReportSaga() {
    yield takeLatest(TYPES.GET_STAFF_REPORTS, getStaffReportWorker);
  }
  function* getStaffReportWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getStaffReports(param['payload']);
      yield put(saveStaffReports(response.resultData))
      yield put (fetchStaffReportsAction(false))
      yield put(loaderAction(false));
    }
    catch (err) {
    }
  }
  
  export function* getAllStaffReportSaga() {
    yield takeLatest(TYPES.GET_ALL_STAFF_REPORTS, getAllStaffReportWorker);
  }
  
  function* getAllStaffReportWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getAllStaffReports();
      if(response && response?.status) {
        yield put(
            saveAllStaffReports(
                response.resultData.map((report) => ({
                    ...report,
                    reportId: report.id,
                }))
            )
        );
        yield put (fetchAllStaffReportsAction(false))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* postStaffReportSaga() {
    yield takeLatest(TYPES.POST_STAFF_REPORTS, postStaffReportWorker);
  }
  
  function* postStaffReportWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.postStaffReports(param['payload']);
      if(response && response?.status) {
        yield put(saveAllStaffReports(response.resultData))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* getReportTypesSaga() {
    yield takeLatest(TYPES.GET_REPORT_TYPES, getReportTypesWorker);
  }
  
  function* getReportTypesWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getReportTypes();
      if(response && response?.status) {
        yield put(saveReportTypes(response.resultData))
        yield put(fetchReportTypes(false))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* getReportsByReportTypeSaga() {
    yield takeEvery(TYPES.GET_REPORTS_BY_REPORT_TYPE, getReportsByReportTypeWorker);
  }
  
  function* getReportsByReportTypeWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getReportsByReportType(param['payload']);
      if(response && response?.status) {
        yield put(saveReportsByReportType(response.resultData))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }
  
  export function* getReportColumnsSaga() {
    yield takeLatest(TYPES.GET_REPORT_COLUMNS, getReportColumnsWorker);
  }
  
  function* getReportColumnsWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getReportColumns(param['payload']);
      if(response && response?.status) {
        yield put(saveReportColumns(response.resultData))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }
  
  export function* getRoleReportsSaga() {
    yield takeLatest(TYPES.GET_ROLE_REPORTS, getRoleReportsWorker);
  }
  
  function* getRoleReportsWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getRoleReports(param['payload']);
      if(response && response?.status) {
        yield put(saveRoleReports(response.resultData))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* getStaffReportColumnPreferencesSaga() {
    yield takeLatest(TYPES.GET_STAFF_REPORT_COLUMN_PREFERENCES, getStaffReportColumnPreferencesWorker);
  }
  
  function* getStaffReportColumnPreferencesWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getStaffReportColumnPreferences(param['payload']);
      if(response && response?.status) {
        yield put(saveStaffReportColumnPreferences(response.resultData))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* assignReportsToRoleSaga() {
    yield takeLatest(TYPES.ASSIGN_REPORTS_TO_ROLE, assignReportsToRoleWorker);
  }
  
  function* assignReportsToRoleWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.assignReportsToRole(param['payload']);
      if(response && (response?.statusCode === 200)) {
        NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS)
        yield put(getRoleReports())
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* insertStaffReportColPreferencesSaga() {
    yield takeLatest(TYPES.INSERT_STAFF_REPORT_COLUMN_PREFERENCES, insertStaffReportColPreferencesWorker);
  }
  
  function* insertStaffReportColPreferencesWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.insertStaffReportColPreferences(param['payload']);
      if(response && response?.status) {
        NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS)
        const data = {
          staffId: param['payload'].staffId,
          reportId: param['payload'].reportId
        }
        yield put(getStaffReportColumnPreferences(data));
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* getAssignedStaffOfReportSaga() {
    yield takeLatest(TYPES.GET_ASSIGNED_STAFF_OF_REPORT, getAssignedStaffOfReportWorker);
  }
  
  function* getAssignedStaffOfReportWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getAssignedStaffOfReport(param['payload']);
      if(response && response?.status) {
        yield put(saveAssignedStaffOfReport(response.resultData))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* assignReportToMultipleStaffSaga() {
    yield takeLatest(TYPES.ASSIGN_REPORT_TO_MULTIPLE_STAFF, assignReportToMultipleStaffWorker);
  }
  
  function* assignReportToMultipleStaffWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.assignReportToMultipleStaff(param['payload']);
      if(response && response?.status) {
        NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS)
        yield put(getAssignedStaffOfReport(param['payload']))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* getStaffReportWithTypesSaga() {
    yield takeLatest(TYPES.GET_STAFF_REPORTS_WITH_TYPES, getStaffReportWithTypesWorker);
  }
  
  function* getStaffReportWithTypesWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getStaffReportsWithTypes(param['payload']);
      if(response && response?.status) {
        yield put(saveStaffReportsWithTypes(response.resultData))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* getStaffServiceReportByStaffIdSaga() {
    yield takeLatest(TYPES.GET_STAFF_SERVICE_REPORT_BY_STAFF_ID, getStaffServiceReportByStaffIdWorker);
  }
  
  function* getStaffServiceReportByStaffIdWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:StaffServiceReportResponse = yield staffServicess.getStaffServiceReportByStaffId(param['payload']);
      if(response && response?.status) {
        yield put(saveStaffServiceReportByStaffId(response?.resultData))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }

  export function* getStaffDDLByClinicIdSaga() {
    yield takeLatest(TYPES.GET_STAFF_DDL_BY_CLINIC_ID, getStaffDDLByClinicIdWorker);
  }
  
  function* getStaffDDLByClinicIdWorker(param: any) {
    try {
      yield put(loaderAction(true));
      const response:any = yield staffServicess.getStaffDDLByClinicId(param['payload']);
      if(response && response?.status) {
        yield put(saveStaffDDLByClinicId(response?.resultData))
      }
      yield put(loaderAction(false));
    }
    catch (err) {
      yield put(loaderAction(false));
    }
  }