import React, { useEffect, useState } from "react";
import Loader from "src/control-components/custom-drawer/custom-drawer"
import CustomDrawer from "src/control-components/custom-drawer/custom-drawer";
import ClientHeader from "../client/client-profile/client-header/client-header";
import { ClientService } from "src/services/clientService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import MyComponent from "../client/client-profile/questionnaire/questionnaire-module/patient-health-questionnaire";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useLocation } from "react-router";
import { useNavigate } from "react-router";
import { renderErrors } from "src/helper/error-message-helper";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { customAxios } from "src/services/useExPressApi";
import { GET_CLIENT_DETAILS, GET_CLIENT_INSURANCE, GET_CLIENT_PRIVATE_PAY_STATUS, GET_CLIENT_PROFILE_IMG, SELECTED_CLIENT_ID } from "src/redux/actions/types";
import { useDispatch, useSelector } from "react-redux";
import { Encrption } from "../encrption";
import DEVELOPMENT_CONFIG from "../../helper/config"


function UpdateClientQuestionnaire() {
  const location = useLocation()
  const dispatch = useDispatch()
  const navigate = useNavigate();
    const selectedClientId = useSelector((state) => state.selectedClientId);
    const [loading, setLoading] = useState(false);
    //   const selectedClientId = useSelector((state) => state.selectedClientId);
      const [questionsDetails, setQuestionDetails] = useState();
      const [questionList, setQuestionList] = useState([]);
      const [questionListById, setQuestionListById] = useState([]);
      const [isQueInfoFetched, setIsQueInfoFetched] = useState(false);
      let token = localStorage.getItem(DEVELOPMENT_CONFIG.TOKEN);
      
  useEffect(() => {
      dispatch({
        type: SELECTED_CLIENT_ID,
        payload: location?.state?.field?.clientId,
      });
  }, [])

  useEffect(() => {
    getQuestions();
    getQuestionsById();
  }, [])

  const handleChange = (e) => {
    const value = e.target.value;
    setQuestionDetails(value);
  };

  const getQuestionsById = async () => {
    const data = {
      id: location?.state?.field?.id
    }
    const metaResponse = await customAxios.post(`${API_ENDPOINTS.GET_QUESTIONS_BY_Id}${data?.id}`, {} , {
      headers: {
        'Authorization': 'Bearer ' + location?.state?.field?.token, 
        'Content-Type': 'application/json',
      },
    });    
    if(metaResponse && metaResponse?.resultData) {
      let questionListById = metaResponse?.resultData?.data;
      setQuestionListById(JSON.parse(questionListById));
      setIsQueInfoFetched(true)
    }
  };

  const getQuestions = async () => {
          const metaResposne = await customAxios.get(`${API_ENDPOINTS.GET_QUESTIONS}`, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + location?.state?.field?.token
            },
          })
          if(metaResposne && metaResposne?.resultData) {
            let questionList = metaResposne?.resultData;
            setQuestionList(questionList);
          }
  };

  return <>
     <div className="d-flex flex-wrap">
       {loading === true && <Loader />}
       <div className="col-md-12 col-lg-12">
         {/* <ClientHeader /> */}
         <div className=" mt-5">
          {location == null ? (
            <div className=" col-lg-4 col-md-6 col-12 template">
              <label>Questions</label>
              <DropDownList
                // label="Questions"
                onChange={handleChange}
                data={questionList}
                value={questionsDetails}
                textField="fullName"
                dataItemKey="id"
                placeholder="Questions"
              />
            </div>
          ) : (
            ""
          )}

         {isQueInfoFetched && <MyComponent
            key={location?.state?.field?.id}
            queId={location?.state?.field?.id}
            isFromCP={location.state.field.isFromCP}
            componentType={
              questionsDetails?.enumId || location?.state?.field?.enumId
            }
             questionId={questionsDetails?.id}
             questionListById={questionListById}
             token = {location?.state?.field?.token}
          />}
        </div>
      </div>
    </div>
  </>
}
export default UpdateClientQuestionnaire