import React, { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import apiHelper from "../../../helper/api-helper";
import { useDispatch, useSelector } from "react-redux";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
  processListBoxDragAndDrop,
} from "@progress/kendo-react-listbox";
import NotificationManager from "react-notifications";
import Loader from "../../../control-components/loader/loader";
import { permissionEnum } from "../../../helper/permission-helper";
import { renderErrors } from "src/helper/error-message-helper";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { fetchStaffReportsAction, getGlobalClinicSites } from "src/redux/actions";
import { customAxios } from "src/services/useExPressApi";

const SELECTED_FIELD = "selected";

const AssignStaffReport = ({ available, assigned }) => {
  const lastSelectedIndex = React.useRef(0);
  const selectedStaffId = useSelector((state: any) => state.selectedStaffId);
  const [selectedID, setSelectedID] = useState([] as any[]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()
  const [state, setState] = React.useState({
    available: [],
    assigned: [],
    draggedItem: {},
  });
  const userAccessPermission = useSelector(
    (state: any) => state.userAccessPermission
  );

  useEffect(() => {
    fillData();
  }, [available, assigned]);

  function getavailableList() {
    return !available
      ? []
      : available.map((x) => {
        return { selected: false, ...x };
      });
  }

  function getAssignedList() {
    return !assigned
      ? []
      : assigned.map((item) => {
        return { selected: false, ...item };
      });
  }

  async function fillData() {
    let AssignedList = getAssignedList();
    setState({
      ...state,
      available: getavailableList().filter(
        (available) =>
          !AssignedList.find(
            (assigned) =>
              assigned.reportId === available.reportId ||
              assigned.reportId === available.reportId
          )
      ),
      assigned: AssignedList,
      draggedItem: {},
    });
  }

  const handleItemClick = (event, data, connectedData) => {
    let last = lastSelectedIndex.current;
    const newData = [...state[data]];
    let id = [...selectedID];
    const current = newData.findIndex(
      (dataItem) => dataItem.reportId === event.dataItem.reportId
    );
    if (!event.nativeEvent.shiftKey) {
      lastSelectedIndex.current = last = current;
    }

    if (!event.nativeEvent.metaKey && !event.nativeEvent.ctrlKey) {
      newData.forEach((item) => (item.selected = false));
      id = [];
    }

    const select = !event.dataItem.selected;

    for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
      newData[i].selected = select;
    }
    setState({
      ...state,
      [data]: newData.map((item: any) => {
        if (item.reportId === event.dataItem.reportId) {
          id.push(item.reportId);
          setSelectedID(id);
        }
        return item;
      }),
    });
  };

  const handleToolBarClick = async (e) => {
    setLoading(true);
    let toolName = e.toolName || "";
    let result: any = processListBoxData(
      state.available,
      state.assigned,
      toolName,
      SELECTED_FIELD
    );

    let body = {
      staffId: selectedStaffId,
      reportIds: Array.from(
        new Set(result.listBoxTwoData.map((item) => item.reportId))
      ),
    };

    try {
      await customAxios.post(API_ENDPOINTS.POST_STAFF_REPORTS, body);
      setLoading(false);
      dispatch(fetchStaffReportsAction(true))
    } catch (error) {
      setLoading(false);
      renderErrors(error);
    }

    setState({
      ...state,
      available: result.listBoxOneData,
      assigned: result.listBoxTwoData.map((item) => ({
        ...item,
        reportId: item?.reportId || item?.id,
      })),
    });
  };

  const handleDragStart = (e) => {
    setState({ ...state, draggedItem: e.dataItem });
  };

  const handleDrop = (e) => {
    let result: any = processListBoxDragAndDrop(
      state.available,
      state.assigned,
      state.draggedItem,
      e.dataItem,
      "name"
    );
    setState({
      ...state,
      available: result.listBoxOneData,
      assigned: result.listBoxTwoData,
    });
  };

  return (
    <>
      <h4 className="address-title text-grey pb_20">
        <span className="f-24">Reports</span>
      </h4>

      {loading && <Loader />}
      <div className="row justify-content-center">

        <div className="col k-pr-2 order-given colorOrder" >
          <h6>Available</h6>
          <ListBox
            className="k-reset-assign-up"
            style={{
              height: 400,
              width: "100%",
            }}
            data={state.available}
            textField="reportName"
            selectedField={SELECTED_FIELD}
            onItemClick={(e) => handleItemClick(e, "available", "assigned")}
            onDragStart={handleDragStart}
            onDrop={handleDrop}
            toolbar={() => {
              return (
                <>
                  {userAccessPermission[permissionEnum.MANAGE_STAFF_SITES] && (
                    <span className="k-reset-assign-up">
                      <ListBoxToolbar
                        tools={[
                          "transferTo",
                          "transferFrom",
                          "transferAllTo",
                          "transferAllFrom",
                        ]}
                        data={state.available}
                        dataConnected={state.assigned}
                        onToolClick={handleToolBarClick}
                      />
                    </span>
                  )}
                </>
              );
            }}
          />
        </div>
        <div className="col-md-6 col k-pl-0">
          <h6>Assigned</h6>
          <ListBox
            style={{
              height: 400,
              width: "100%",
            }}
            data={state.assigned}
            textField="reportName"
            selectedField={SELECTED_FIELD}
            onItemClick={(e) => handleItemClick(e, "assigned", "available")}
            onDragStart={handleDragStart}
            onDrop={handleDrop}
          />
        </div>
      </div>

    </>
  );
};

export default AssignStaffReport;
