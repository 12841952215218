import { Dialog } from "@progress/kendo-react-dialogs";
import { useNavigate } from "react-router";
import APP_ROUTES from "../../helper/app-routes";

interface UnsignedDocumentsDialogProps {
  onClose: () => void;
  unsignedDocuments: number[];
  signedDocuments: number[];
}

const UnsignedDocumentsDialog = ({
  onClose,
  unsignedDocuments,
  signedDocuments,
}: UnsignedDocumentsDialogProps) => {
  const navigate = useNavigate();

  const handleViewDocument = (documentId: number) => {
    sessionStorage.setItem("documentData", JSON.stringify({
      id: documentId,
      backRoute: APP_ROUTES.DOCUMENT_LIST,
    }));
    window.open(APP_ROUTES.DOCUMENT_VIEW, "_blank");
  };

  return (
    <Dialog
      onClose={onClose}
      title="Batch Signature Results"
      className="dialog-modal medium-modal education width_90 blue_theme"
      width={"70%"}
      height={"85vh"}
    >
      <h6 className="fw-500">
        We couldn't add your signature to {unsignedDocuments.length}{" "}
        document(s). Please review the following documents:
      </h6>
      <div className="row m-0  mt-md-2 gy-2">
        {unsignedDocuments && unsignedDocuments.length > 0 && (
          <div className="col-md-6 col-12 ">
            <h6 className="fw-500 f-16"> Unsuccessful Documents:</h6>
            <div className="dash-filter-grid-bill">
              <div style={{ maxHeight: "600px", overflowY: "auto" }}>
                <ul
                  className="list-profile list-style-none p-0 mb-0"
                  style={{ listStyle: "none" }}
                >
                  {unsignedDocuments.map((docStatusData: any, index) => (
                    <li key={docStatusData.documentId} className="px-2">
                      <span className="mr-2" style={{ width: "30px" }}>
                        {index + 1}.
                      </span>
                      <span className="anchar-pointer text-theme px-0">
                        <i
                          className="fa fa-eye pr-1"
                          onClick={() =>
                            handleViewDocument(docStatusData.documentId)
                          }
                        ></i>
                        <span
                          className="hover_underline f-16 fw-500"
                          onClick={() =>
                            handleViewDocument(docStatusData.documentId)
                          }
                        >
                          {docStatusData.documentId}
                        </span>
                        {" - "}
                        <span style={{ color: "black" }}>
                          {docStatusData.reason}
                        </span>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        )}

        {signedDocuments && signedDocuments.length > 0 && (
          <>
            <div className="col-md-6 col-12 ">
              <h6 className="fw-500 f-16">Successfully Signed Documents:</h6>
              <div className="dash-filter-grid-bill">
                <div>
                  <ul
                    className="list-profile list-style-none p-0 mb-0"
                    style={{ listStyle: "none" }}
                  >
                    {signedDocuments.map((docId, index) => (
                      <li key={docId} className="px-2">
                        <span className="mr-2" style={{ width: "30px" }}>
                          {index + 1}.
                        </span>
                        <span className="anchar-pointer text-theme">
                          <i
                            className="fa fa-eye pr-1"
                            onClick={() => handleViewDocument(docId)}
                          ></i>
                          <span
                            className="hover_underline"
                            onClick={() => handleViewDocument(docId)}
                          >
                            {docId}
                          </span>
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default UnsignedDocumentsDialog;
