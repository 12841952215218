import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveClinicLogoBytes } from "src/redux/actions";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { getSettings } from "src/services/settingsService";
import { customAxios } from "src/services/useExPressApi";
import DocumentTemplatePdf from "src/app-modules/documents/pdf-generator/pdf-doc-temp";
import ProgressLoading from "src/control-components/loader/loader_with_text";

const CreateDocZip = ({ preparedZipData,docIndex,onClose}) => {
    const clinicId = useSelector((state: any) => state.loggedIn?.clinicId);
    const [documentDetailItems, setDocDetailItems] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!documentDetailItems && preparedZipData) {
            getDocMetaData();
        }
    }, [documentDetailItems, preparedZipData]);

    const getDocMetaData = async () => {
        const clinicLogoResp = await getSettings.getClinicLogo(clinicId, true);
        if (clinicLogoResp.status) {
            dispatch(saveClinicLogoBytes(clinicLogoResp.resultData.clinicLogo));
        }

        const docsArray = preparedZipData.map((element) => element.documentId);
        const metaResponse: any = await customAxios.post(
            `${API_ENDPOINTS.GET_DOC_PRINT_META_DATA}`,
            docsArray
        );

        if (metaResponse && metaResponse.status) {
            const metaData = metaResponse.resultData.map((doc) => {
                const matchedStateItem = preparedZipData.find(
                    (item: any) => item?.documentId?.toString() === doc?.documentId?.toString()
                );
                const clientId = matchedStateItem ? matchedStateItem?.documentDetail?.clientId : null;
                const gender =   matchedStateItem? matchedStateItem?.documentDetail?.gender :null;
        
                return {
                    ...doc,
                    clientId: clientId,
                    gender: gender
                };
            });
            setDocDetailItems(metaData);
        }
        
    };
    if (!documentDetailItems) return <ProgressLoading message={'Please wait while we are collecting information for all selected documents.'}></ProgressLoading>

    if (!preparedZipData || preparedZipData.length < 1 || !documentDetailItems) {
        return <></>;
    }

    return (
        <DocumentTemplatePdf
            documentPrintData={preparedZipData}
            onlyView={false}
            isClientFileDoc = {true}
            metaDataDocs={documentDetailItems}
            docIndex={docIndex}
            onClose={onClose}
        />
    );
};

export default CreateDocZip;