import React, { useEffect, useState } from "react";
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import APP_ROUTES from "src/helper/app-routes";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { TableRowsLoader } from "src/control-components/custom-skelton";
import CustomSkeleton from "src/control-components/skeleton/skeleton";
import { getter } from "@progress/kendo-react-common";
import { orderBy } from "@progress/kendo-data-query";

const idGetter = getter("id");
const SELECTED_FIELD = "selected";
function TelehealthLogsDetails() {
  const stateForReducer = useSelector((states) => {
    return states;
  });
  const { telehealthLogsResp } = stateForReducer["ReportReducer"];
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(500);
  const [logsData, setLogsData] = useState([]);
  const [roomDataStart, setRoomDataStart] = useState();
  const [roomDataEnd, setRoomDataEnd] = useState();
  const [correctRoomDetails, setCorrectRoomDetails] = useState();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [sort, setSort] = useState([]);
  const [selectedState, setSelectedState] = React.useState({});

  const handleSelectedTab = (e) => {
    setSelectedTab(e.selected);
  };

  useEffect(() => {
    if (telehealthLogsResp && telehealthLogsResp?.resultData) {
      setLoading(true);
      const correctObj = telehealthLogsResp?.resultData?.find(
        (item) => item.roomId === location?.state?.roomId
      );
      setCorrectRoomDetails(correctObj);
      setLogsData(correctObj?.roomEventLogs[0]?.participantLogs);
      setRoomDataStart(correctObj?.roomEventLogs[0]);
      setRoomDataEnd(correctObj?.roomEventLogs[1]);
      setLoading(false);
    }
  }, [telehealthLogsResp]);

  const pageChange = (event) => {
    let skip = event.page.skip;
    let take = event.page.take;
    setPage(skip);
    setPageSize(take);
    let newValue = skip / take;
    let finalValue = newValue + 1;
    window.scrollTo(0, 0);
  };

  function renderParticipantGrid() {
    return (
      <div>
        {loading ? (
          <TableRowsLoader rowsNum={12} />
        ) : (
          <div className="client-accept blue_theme">
            <div className="address-line-content mt-3 custom-grid table-heading-auth blueThemeTable">
              <Grid
                style={{
                  height: logsData.length > 0 ? "100%" : "250px",
                }}
                data={orderBy(logsData.slice(page, page + pageSize), sort).map(
                  (item) => ({
                    ...item,
                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                  })
                )}
                skip={page}
                take={pageSize}
                onPageChange={pageChange}
                total={logsData.length}
                className="pagination-row-cus listPage-noData"
                pageable={{
                  pageSizes: [10, 50, 100, 300, 400, 500],
                }}
                dataItemKey={"id"}
                sortable={true}
                sort={sort}
                onSortChange={(e) => {
                  setSort(e.sort);
                }}
              >
                <GridColumn
                  title="Participant"
                  field="participantSid"
                  className="cursor-default"
                />
                <GridColumn
                  title="Event"
                  field="roomEvent"
                  className="cursor-default"
                  cell={(props) => {
                    const { dataItem, field } = props;
                    const value = dataItem[field];
                    const formattedValue = value
                      ? value
                          .replace(/-/g, ' ') 
                          .replace(/\b\w/g, (char) => char.toUpperCase()) 
                      : '';

                    return <td>{formattedValue}</td>;
                  }}
                />
                <GridColumn
                  className="cursor-default"
                  field="clientName"
                  title="Client"
                />
                <GridColumn
                  className="cursor-default"
                  field="staffName"
                  title="Staff"
                />
                <GridColumn
                  title="Time Stamp"
                  field="timeStamp"
                  className="cursor-default"
                  cell={(props) => {
                    let field = props.dataItem.timestamp;
                    return (
                      <td className="cursor-default">
                        {moment.utc(field).local().format("M/D/YYYY")} at{" "}
                        {moment.utc(field).local().format("hh:mm A")}
                      </td>
                    );
                  }}
                />
              </Grid>
            </div>
          </div>
        )}
      </div>
    );
  }

  function renderRoomDetails() {
    return (
      <>
        <div className="p-2 row">
          <div className="col-xl-12 col-md-8 col-12 px-lg-4  mb-3">
            <div className="mb-2 d-flex justify-content-start">
              <b className="f-24">Room Info</b>
            </div>
            <div className="row shadowbox pt-3 border-radis">
              <ul className="list-unstyled mb-0 details-info firts-details-border position-relative row">
                <li className="text-left col-md-6 col-sm-6">
                  <p className="col-md-10 mb-3 labelColor">Room Name</p>
                  <p className="col-md-12 fw-600 valueColor">
                    {!roomDataStart ? (
                      <CustomSkeleton shape="text" />
                    ) : (
                      roomDataStart?.roomName
                    )}
                  </p>
                </li>

                <li className="text-left col-md-6 col-sm-6">
                  <p className="col-md-10 mb-3 labelColor">Room SID</p>
                  <p className="col-md-12 fw-600 valueColor">
                    {!roomDataStart ? (
                      <CustomSkeleton shape="text" />
                    ) : (
                      roomDataStart?.roomId
                    )}
                  </p>
                </li>

                <li className="text-left col-md-6 col-sm-6">
                  <p className="col-md-10 mb-3 labelColor">Date Created</p>
                  <p className="col-md-12 fw-600 valueColor">
                    {!roomDataStart ? (
                      <CustomSkeleton shape="text" />
                    ) : (
                      moment
                        .utc(roomDataStart?.timeStamp)
                        .local()
                        .format("M/D/YYYY")
                    )}{" "}
                    at{" "}
                    {moment
                      .utc(roomDataStart?.timeStamp)
                      .local()
                      .format("hh:mm A")}
                  </p>
                </li>

                <li className="text-left col-md-6 col-sm-6">
                  <p className="col-md-10 mb-3 labelColor">Date Completed</p>
                  <p className="col-md-12 fw-600 valueColor">
                    {!roomDataStart ? (
                      <CustomSkeleton shape="text" />
                    ) : (
                      moment
                        .utc(roomDataEnd?.timeStamp)
                        .local()
                        .format("M/D/YYYY")
                    )}{" "}
                    at{" "}
                    {moment
                      .utc(roomDataEnd?.timeStamp)
                      .local()
                      .format("hh:mm A")}
                  </p>
                </li>

                <li className="text-left col-md-6 col-sm-6">
                  <p className="col-md-10 mb-3 labelColor">Status</p>
                  <p className="col-md-12 fw-600 valueColor">
                    {!roomDataEnd && !roomDataStart ? (
                      <CustomSkeleton shape="text" />
                    ) : (
                      (roomDataEnd?.roomStatus || roomDataStart?.roomStatus)
                        ?.replace(/-/g, ' ')
                        .replace(/\b\w/g, (char) => char.toUpperCase())
                    )}
                  </p>
                </li>

                <li className="text-left col-md-6 col-sm-6">
                  <p className="col-md-10 mb-3 labelColor">Total Duration</p>
                  <p className="col-md-12 fw-600 valueColor">
                    {!roomDataStart ? (
                      <CustomSkeleton shape="text" />
                    ) : correctRoomDetails?.duration}
                  </p>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="inner-section-edit position-relative text-center tabs-kendoselect  dateDropdown mt-2">
        <div className="d-flex">
          <button
              type="button"
              value="BACK"
              onClick={() => navigate(-1)}
              className="border-0 bg-transparent arrow-rotate mb-3"
              style={{ padding: "0", margin: "0" }}
            >
              <i className="fa-solid fa-arrow-left mr-2"></i> Back
            </button>
        </div> 
        <div className="f-24 mb-2 d-flex justify-content-start">
          <b>Room Summary</b>
        </div>
        <TabStrip
          className="setting-tabs-staff"
          selected={selectedTab}
          onSelect={handleSelectedTab}
        >
          <TabStripTab title="About">{renderRoomDetails()}</TabStripTab>

          <TabStripTab title="Participants">
            {renderParticipantGrid()}
          </TabStripTab>
        </TabStrip>
      </div>
    </>
  );
}

export default TelehealthLogsDetails;
