import { MouseEventHandler } from "react";
import "./text-color.css";

export interface LinkTextProps {
  onClick?: MouseEventHandler<HTMLSpanElement>;
  showEye?: boolean;
  text?: string | number;
  preset?:
  | "default" |  "blue" 
}

export const PRESETS = {
  default: "./text-color.css",
  blue: "blue",
};

function NoteneticLinkText(props: LinkTextProps) {
  const { onClick, showEye, text, preset, ...others } = props;

  return (
    <div>
      <span
        className={preset ? PRESETS[preset] : PRESETS.default}
        onClick={onClick}
      >
        {showEye && <i className="fa fa-eye pr-1"></i>}
        {text}
      </span>
    </div>
  );
}

export default NoteneticLinkText;
