import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ClientService } from "../../../../../../services/clientService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import APP_ROUTES from "src/helper/app-routes";
import RecentIssue from "./recent-issues";
import Interference from "./cats-interference";
import { useLocation } from "react-router";
// import InputKendoRct from "../../../control-components/input/input";
import InputKendoRct from "src/control-components/input/input";
import { renderErrors } from "src/helper/error-message-helper";
import ClientQuestionnaireService from "./functionToSaveQuestionnaireFromPortal";

const ChildAdolesent = ({
  list,
  questionId,
  subList,
  interfered,
  response,
  isPrint,
  isFromCP,
  token,
   queId
}) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const selectedClientId = useSelector((state) => state.selectedClientId);
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [listItems, setListItems] = useState(list);
  const [listItems2, setListItems2] = useState(subList);
  const [listItems3, setListItems3] = useState(interfered);
  const [botherQuestion, setBotherQuestion] = useState({
    questionId: "bother_ques_1",
    value: "",
  });

  const [fields, setFields] = useState({
    event: "",
    brother: "",
  });
  
  const totalSum = listItems2.reduce(
    (sum, item) => sum + (Number(item.value) || 0),
    0
  );

  useEffect(() => {
    const l1 = listItems.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        if (item.type === "question") {
          const [firstValue] = foundItem.value ? foundItem.value.split(",").map(v => v.trim()) : [null];
            return {
              ...item,
              isSelected: firstValue === "true" ? true : firstValue === "false" ? false : null,
              value: foundItem.value || "null,",
            };
          }
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    const l2 = listItems2.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    const l3 = listItems3.map((item) => {
      const foundItem = response.find((i) => i.questionId === item.questionId);
      if (foundItem) {
        return { ...item, ...foundItem };
      } else {
        return item;
      }
    });

    const botherQ = response.find(
      (i) => i.questionId === botherQuestion.questionId
    );

    setTimeout(() => {
      setListItems(l1);
      setListItems2(l2);
      setListItems3(l3);
      if (botherQ) {
        setBotherQuestion((prev) => ({ ...prev, value: botherQ.value }));
      }
    }, 0);
  }, [response]);

  const handleRadioChange = (itemId, value) => {
    const updatedItems = listItems.map((item) => {
      if (item.questionId === itemId) {
        if (itemId === "CATS_Q15") {
          if (value === "true") {
            return { ...item, isSelected: true, value: `true, ` };
          } else {
            return { ...item, isSelected: false, value: `false, ` };
          }
        }
        return { ...item, value: value };
      }
      return item;
    });
    setListItems(updatedItems);
  };

  const handleChange = (e) => {
    setBotherQuestion({ ...botherQuestion, value: e.target.value });
  };
  const handleEventChange = (item, newValue) => {
    const updatedItems = listItems.map((listItem) => {
      if (listItem.id === item.id) {
        const [radioValue] = listItem.value.split(",");
        return {
          ...listItem,
          value: `${radioValue},${newValue}`,
        };
      }
      return listItem;
    });
    setListItems(updatedItems);
  };
  
  const handleSubmit = async () => {
    await ClientService.insertClientQuestionnaire(
      selectedClientId,
      totalSum,
      [...listItems, ...listItems2, ...listItems3, botherQuestion],
      questionId,
      queId,
      token
    )
      .then((result) => {
        let questionList = result.resultData;
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error.message);
      });
  };

  // const arr = Object.entries(selectedValues).map(([key, value]) => {
  //   return { key: parseInt(key), value };
  // });

  const condition = "true";
  const condition2 = "1";
  const condition3 = "0"
  const condition4 = "false"
  const foundItem = listItems?.find((item) => item.value === condition || item.value === condition2  || item.value === condition3 || item.value === condition4);

  const calculateSum = (listItems) => {
    const sum = listItems.reduce((acc, item) => acc + item.value, 0);
    // setTotalSum(sum);
  };

  // const handleChildStateChange1 = (childState) => {
  //   setState1(childState);
  // };

  // const handleChildStateChange2 = (childState) => {
  //   setState1(childState);
  // };


  const handleSaveFromPortal = async () => {
    ClientQuestionnaireService.updatequestionnaireFromPortal(selectedClientId, totalSum, [...listItems, ...listItems2, ...listItems3, botherQuestion], queId, token)
  }

  const handleCompleteFromPortal = async () => {
    try {
      ClientQuestionnaireService.completeQuestionnaireFromPortal(false, queId, selectedClientId, totalSum, [...listItems, ...listItems2, ...listItems3, botherQuestion],  {isCompleted: true, inProgress: false, sentToClientPortal: null}, token)
    } catch (error) {
      console.error('Error completing client questionnaire:', error);
      NotificationManager.error('Failed to complete client questionnaire');
    }
  };
  

  return (
    <div className="child_main_table mt-3 col-12">
      <div>
        <div className="head-4">CATS - Child & Adolescent Trauma screen</div>
        <div className="head-6">Caregiver Report (Ages 7 - 17 years)</div>
        <label className="pb-3 mt-2 border-bottom">
          Stressful or scary events happen to many people. Below is a list of
          stressful and scary events that sometimes happen. Mark YES if it
          happened to you. Mark No if it didn’t happen to you.
        </label>
      </div>
       

      {
      isPrint ? (
        (foundItem || listItems[listItems.length - 1].isSelected) ? (
          <>
            {listItems.map((item) => (
              <div className="row mb-3 py-2 border-bottom" key={item.id}>
                <div className="col-12">
                  <div>
                    <label>
                      {item.id}. {item.name}
                    </label>
                  </div>
                </div>
                <div className="col-12 select_table_phq select-table-drops">
                  <input
                    type="radio"
                    name={`radio_${item.questionId}`}
                    value={true}
                    checked={
                      item?.type === "question" ? item.isSelected : item.value === "true"
                    }
                    onChange={() => handleRadioChange(item.questionId, "true")}
                    className="input_box_cats"
                  />
                  <label className="mr-3">Yes</label>
                  <input
                    type="radio"
                    name={`radio_${item.questionId}`}
                    value={false}
                    checked={
                      item?.type === "question"
                        ? item.isSelected === null
                          ? false
                          : !item.isSelected
                        : item.value === "false"
                    }
                    onChange={() => handleRadioChange(item.questionId, "false")}
                    className="input_box_cats"
                  />
                  <label className="mr-3">No</label>
                </div>
              </div>
            ))}
          </>
        ) : null
      ) : (
        listItems.map((item) => (
          <div className="row mb-3 py-2 border-bottom" key={item.id}>
            <div className="col-12">
              <div>
                <label>
                  {item.id}. {item.name}
                </label>
              </div>
            </div>
            <div className="col-12 select_table_phq select-table-drops">
              <input
                type="radio"
                name={`radio_${item.questionId}`}
                value={true}
                checked={
                  item?.type === "question" ? item.isSelected : item.value === "true"
                }
                onChange={() => handleRadioChange(item.questionId, "true")}
                className="input_box_cats"
              />
              <label className="mr-3">Yes</label>
              <input
                type="radio"
                name={`radio_${item.questionId}`}
                value={false}
                checked={
                  item?.type === "question"
                    ? item.isSelected === null
                      ? false
                      : !item.isSelected
                    : item.value === "false"
                }
                onChange={() => handleRadioChange(item.questionId, "false")}
                className="input_box_cats"
              />
              <label className="mr-3">No</label>
            </div>
          </div>
        ))
      )
    }
      {listItems[listItems.length - 1].isSelected ? (
        <div className="mb-3 col-lg-4 col-md-6 col-12 px-md-0">
          <InputKendoRct
            value={
              listItems[listItems.length - 1].value
                ? listItems[listItems.length - 1].value.split(",")[1]
                : ""
            }
            onChange={(e) =>
              handleEventChange(listItems[listItems.length - 1], e.target.value)
            }
            name="event"
            label="Describe Event"
          />
        </div>
      ) : null}
      <div className="mb-3 col-12 col-md-8 col-xl-5 px-0">
        <InputKendoRct
          value={botherQuestion.value}
          onChange={handleChange}
          name="brother"
          label="Which one bothers the child most now ?"
          width={"100%"}
        />
      </div>
      {foundItem || listItems[listItems.length - 1].isSelected ? (
        <RecentIssue
          list={subList}
          calculateSum={calculateSum}
          totalSum={totalSum}
          // onChildStateChange={handleChildStateChange1}
          listItems={listItems2}
          setListItems={setListItems2}
        />
      ) : null}
      {foundItem || listItems[listItems.length - 1].isSelected ? (
        <Interference
          list={interfered}
          listItems={listItems3}
          setListItems={setListItems3}
          // onChildStateChange={handleChildStateChange2}
        />
      ) : null}

{isFromCP ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleCompleteFromPortal} className="submitButon">
            Save & Complete
          </button>
          <button onClick={handleSaveFromPortal} className="cancelBtn">
            Save progress
          </button>
          </div>
        ) : state == null && !isPrint ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleSubmit} className="submitButon">
              Submit
            </button>
          </div>
        ) : null}

    </div>
  );
};

export default ChildAdolesent;
