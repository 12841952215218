import ApiHelper from "../helper/api-helper";
import moment from "moment";
import { API_ENDPOINTS } from "./api-endpoints";
import { customAxios } from "./useExPressApi";

const getEventStatus = () => {
  return new Promise((resolve, reject) => {
    customAxios.get(API_ENDPOINTS.GET_EVENT_STATUS, null)
      .then((result) => {
        resolve(result?.resultData);
      })
      .catch((error) => {
        //TODO://Implement error at common place in API Helper along with loader at common place
        reject(error);
      });
  });
};

const getEventCategory = () => {
  return new Promise((resolve, reject) => {
    customAxios.get(API_ENDPOINTS.GET_EVENT_CATEGORY, null)
      .then((result) => {
        resolve(result?.resultData);
      })
      .catch((error) => {
        //TODO://Implement error at common place in API Helper along with loader at common place
        reject(error);
      });
  });
};


const getGender = () => {
  return new Promise((resolve, reject) => {
    customAxios.get(API_ENDPOINTS.GET_GENDER, null)
      .then((result) => {
        resolve(result?.resultData);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


const changePass = (fields) => {
  var data = {
    "currentPassword": fields.currentPassword,
    "newPassword": fields.newPassword
  };
  return customAxios.post(API_ENDPOINTS.CHANGE_PASSWORD, data,);
};



const forgotPass = (userName, domainName) => {
  return customAxios.get(API_ENDPOINTS.FORGOT_PASSWORD + userName + "&" + "domainName=" + domainName);
};

const syncStaffOnlineStatus = () => {
  return customAxios.get(API_ENDPOINTS.SYNC_STAFF_STATUS);
};


const confirmOtp = (fields, domainName) => {
  var data = {
    "userName": fields.userName,
    "otp": fields.otp,
    "domainName": domainName
  };
  return customAxios.post(API_ENDPOINTS.VERIFY_OTP, data,);
};


const updatePassword = (fields) => {
  var data = {
    "newPassword": fields.newPassword,
  };
  return customAxios.post(API_ENDPOINTS.UPDATE_PASSWORD, data,);
};

const logOutUser = (sessionId) => {
  var data = {
    "sessionId": sessionId,
  };
  return customAxios.post(API_ENDPOINTS.LOGOUT_USER, data,);
};

const addDocumentExisting = (documentId, linkedDocIds) => {
  var data = {
    "documentId": documentId,
    "linkedDocId": linkedDocIds
  };

  return customAxios.post(API_ENDPOINTS.LINKED_DOCUMENTS, data,);
};

const updateDocBillingStatus = (id, docIds) => {
  var data = {
    "billingStatusId": id,
    "documentIds": docIds
  };

  return customAxios.post(API_ENDPOINTS.UPDATE_BILLING_STATUSES, data,);
};

const updateMultiDocBillingStatus = (billingStatusId, docIds,comment) => {
  const DocumentIds = docIds.map((item) => item?.documentId)
  var data = {
    "billingStatusId": billingStatusId?.id,
    "documentIds": DocumentIds,
 };
  if(comment && comment.length>0){
    data = {...data,"billingComment":comment};
  }

  return customAxios.post(API_ENDPOINTS.UPDATE_BILLING_STATUSES, data,);
};

const updatePayRollStatus = (billingUpdateDate, docIds,billingStatus,payrollComments) => {
  const DocumentIds = docIds.map((item) => item?.documentId)
  
  let data = {
    "payrollPaid":billingStatus,
    "payrollComments": payrollComments,
    "documentIds": DocumentIds,
  };
  if(billingUpdateDate && billingUpdateDate!=null){
    data={...data,payrollDate: moment(billingUpdateDate).format("YYYY-MM-DD")}
  }
  return customAxios.post(API_ENDPOINTS.UPDATE_PAYROLL_STATUSES, data,);
};


const getDocumentByClientId = (id) => {
  return customAxios.get(API_ENDPOINTS.GET_DOCUMENTS_BY_CLIENT_ID + id);
};

const getLinkedDocByDocId = (id) => {
  return customAxios.get(API_ENDPOINTS.GET_DOCUMENTS_BY_DOCUMENT_ID + id);
};

const getQuestionnaireByClientId = (id) => {
  return customAxios.get(API_ENDPOINTS.GET_QUESTIONNAIRE_BY_CLIENT_ID + id);
};

const addClientQuestionnaire = (documentId, clientQuestionnaireId, createdBy) => {
  var data = {
    "documentId": documentId,
    "clientQuestionnaireId": clientQuestionnaireId,
    "createdBy": createdBy
  };

  return customAxios.post(API_ENDPOINTS.LINKED_QUESTOINNAIRE, data,);
};


export const CommonService = {
  getEventStatus,
  getGender,
  changePass,
  forgotPass,
  updatePassword,
  confirmOtp,
  logOutUser,
  syncStaffOnlineStatus,
  getDocumentByClientId,
  addDocumentExisting,
  getLinkedDocByDocId,
  getQuestionnaireByClientId,
  addClientQuestionnaire,
  updateDocBillingStatus,
  updateMultiDocBillingStatus,
  updatePayRollStatus,
  getEventCategory
};