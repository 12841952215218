import React, { ComponentState, useEffect, useState } from 'react'
import { Dialog } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from 'react-redux';
import { getDocAttachment } from 'src/redux/actions';
import CustomSkeleton from 'src/control-components/skeleton/skeleton';
import { DocAttachements } from 'src/dataModels/docListModel';



const ViewAttachmentDialog = ({ onClose, clientName, dos, title, docId }) => {
    const [docAttachment, setDocAttachment] = useState([])
    const dispatch = useDispatch()
    const state: any = useSelector(states => {
        return states;
    });
    const { docAttachments } = state['DocumentReducer'];

    
    
    useEffect(()=> {
        if(docId) {
            dispatch(getDocAttachment(docId))
        }
    }, [docId])

    useEffect(()=> {
        if(docAttachments && docAttachments.length > 0) {
            setDocAttachment(docAttachments)
        }
    }, [docAttachments])

    function renderAttachments() {
        const li = docAttachment.map((file: DocAttachements, index) => {
          return (
            <li
              key={index}
              className="ml-2 mx-1 my-2 cursor-pointer d-flex align-items-center attachment-item"
            >
              <a href={file.fileUrl} target="_blank" download>
                {file.fileName}
              </a>
            </li>
          );
        });
        return (
          <ul className="d-flex flex-wrap upload-attachemnt list-unstyled file-attachment mt-3 align-items-center">
            {li}
          </ul>
        );
      }
      

    return (
        <>
            <Dialog
                onClose={onClose}
                title = {title}
                className="dialog-modal medium-modal education width_90 blue_theme" 
                width={"40%"} height={"70%"}
            >    
                    <div className='d-flex justify-content-between align-items-center mb-2 px-3'>
                      <span className='f-16'><b>Document ID:</b>{`  ${docId}`}</span>
                      <span className='f-16'><b>Client Name:</b>{`  ${clientName}`}</span>
                      <span className='f-16'><b>DOS:</b>{`  ${dos}`}</span>
                    </div>
                    <hr/>
                    <div className="form-group  mb-3 mt-3 px-0 col-md-12 ">
                      {docAttachment?.length < 0 ? (
                        <CustomSkeleton shape="text" />
                      ) : docAttachment?.length > 0 ? (
                        renderAttachments()
                      ) : (
                        <p className="ml-2 docContent">NA</p>
                      )}
                    </div>            
            </Dialog>
        </>
    )
}

export default ViewAttachmentDialog