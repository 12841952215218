import axios, { AxiosError } from "axios";
import { makeUseAxios } from "axios-hooks";
import HttpStatusCode from "./HttpStatusCode";
import { renderErrors } from "../helper/error-message-helper";
import DEVELOPMENT_CONFIG from "../helper/config";

export const customPuppeteerAxios = axios.create({
  baseURL: DEVELOPMENT_CONFIG.puppeteer_base_url,
  headers: {
    "Content-Type": "application/octet-stream",
  },
  responseType: "blob",
});

customPuppeteerAxios.interceptors.response.use(
  async (resp: any) => {
    switch (resp.status) {
      case HttpStatusCode.OK:
        return resp;

      case HttpStatusCode.BAD_REQUEST:
        renderErrors(resp.data.message);
        break;

      case HttpStatusCode.UNAUTHORIZED:
        renderErrors(resp.data.message);

      case HttpStatusCode.NOT_FOUND:
        renderErrors(resp.data.message);
        break;

      case HttpStatusCode.INTERNAL_SERVER_ERROR:
        renderErrors(resp.data.message);
        break;

      case HttpStatusCode.BAD_GATEWAY:
        renderErrors("Unable to connect to server please try again...");
        break;

      default:
        return resp;
    }
  },
  (error: AxiosError) => {
    try {
      if (error.code === "NETWORK_ERROR") {
        renderErrors("Unable to connect to server please try again...");
      } else {
        renderErrors((error.response?.data as Object)["Message"]);
      }
    } catch (E) {
      Promise.reject(error);
    }
  }
);

export const useExpressApi = makeUseAxios({
  cache: false,
  axios: customPuppeteerAxios,
});
