import {DropDownListProps } from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";
import { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSiteListData } from "./globalDataCheckerService";
import DropDownListComponent from "src/control-components/drop-down-component/drop-down";

export const SiteDropDownComponent = (props: SiteDdlProps) => {
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const { selectedSite, onChangeSite, ...rest } = props
    const [filter, setFilter] = useState<any>();
    const { sitesList } = state['DocumentReducer'];
    const [siteList, setSiteList] = useState([]);
    const [loading, setLoading] = useState(false)
    const [isDataLoadStart, setDataLoadStart] = useState(false);

    function onFilterChange(event, listName) {
        setFilter({ ...filter, [listName]: event.filter });
    }
    useEffect(() => {

        if (!isDataLoadStart) {
            setLoading(true)
            callSiteListApiData()
            setDataLoadStart(true)
        }
        if (sitesList != null) {
            setLoading(false)
            setSiteList(sitesList);
        }
    }, [isDataLoadStart, sitesList])

    const callSiteListApiData = async () => {
        getSiteListData()
    }

    return <div className="dropdown-service document-dropdown">
        <div className="p-0 col-md-12 pr-3 pl-3">
            <div className="position-relative d-inline-block w-100 topSpacing">

                <DropDownListComponent
                    filterable={true}
                    data={filterBy(siteList, filter ? filter.siteList : [])}
                    onFilterChange={(event) => {
                        onFilterChange(event, "siteList");
                    }}
                    loading={loading}
                    textField="name"
                    label="Site"
                    name="site"
                    value={selectedSite}
                    onChange={onChangeSite}
                />
            </div>
        </div>
    </div>
}

export interface SiteDdlProps {
    selectedSite: any,
    onChangeSite?: (newValue: DropDownListProps) => void
}