export const DOCUMENTS_TYPES = {
 APPLY_BATCH_SIGN:"APPLY_BATCH_SIGN",
 SAVE_BATCH_SIGN_RESP:"SAVE_BATCH_SIGN_RESP",
 GET_CAP_NOTES_GOALS:"GET_CAP_NOTES_GOALS",
 SAVE_CAP_NOTES_GOALS:"SAVE_CAP_NOTES_GOALS",
 GET_CAP_NOTES_INTERVENTION:"GET_CAP_NOTES_INTERVENTION",
 SAVE_CAP_NOTES_INTERVENTION:"SAVE_CAP_NOTES_INTERVENTION",
 GET_CAP_NOTES_ASSESSMENTS:"GET_CAP_NOTES_ASSESSMENTS",
 SAVE_CAP_NOTES_ASSESSMENTS:"SAVE_CAP_NOTES_ASSESSMENTS",
 GET_DOC_SETTINGS:"GET_DOC_SETTINGS",
 SAVE_DOC_SETTINGS:"SAVE_DOC_SETTINGS"
}
