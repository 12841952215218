import { Dialog } from '@progress/kendo-react-dialogs'
// import { Loader } from '@progress/kendo-react-indicators'
import React, { useEffect, useState } from 'react'
import { API_ENDPOINTS } from 'src/services/api-endpoints'
import { customAxios } from 'src/services/useExPressApi'
import { convertServerDocumentToLocal } from './document-utility'
import { mapDocumentTemplate } from './template/document-template-utility'
import { getSettings } from 'src/services/settingsService'
import { useDispatch, useSelector } from 'react-redux'
import { saveClinicLogoBytes } from 'src/redux/actions'
import DocumentTemplatePdf from './pdf-generator/pdf-doc-temp';
import { docs } from 'src/services/documentService'
import Loading from 'src/control-components/loader/loader'



const LatestDocumentId = ({ latestDocumentId, onClose }) => {

    const [isDataFetched, setDataFetched] = useState(false)
    const [recordFetchStart, setRecordFetchStart] = useState(false)
    const [currentDocumentData, setDocumentDetailData] = useState<any>()
    const [documentMeta, setDocumentMeta] = useState([])
    const dispatch = useDispatch()
    const stateData: any = useSelector(states => {
        return states;
    });
    const clinicId = stateData.loggedIn.clinicId;
 
    useEffect(() => {
        if (!recordFetchStart) {
            fetchDocAllInfo()
        }
        setRecordFetchStart(true)
    }, [recordFetchStart])

    useEffect(() => {
        if (currentDocumentData && currentDocumentData.documentId) {
            getDocMetaData()
        }
    }, [currentDocumentData])

    useEffect(() => {
        if (documentMeta && documentMeta.length > 0) {
            setDataFetched(true)
        }
    }, [documentMeta])

    const getDocMetaData = async () => {
        const clinicLogoResp = await getSettings.getClinicLogo(clinicId, true);
        if (clinicLogoResp.status) {
            dispatch(saveClinicLogoBytes(clinicLogoResp.resultData.clinicLogo));
        }


        const metaResponse: any = await customAxios.post(`${API_ENDPOINTS.GET_DOC_PRINT_META_DATA}`, [latestDocumentId]);
        if (metaResponse && metaResponse.status) {
            setDocumentMeta(metaResponse.resultData)
        }
    }


    const fetchDocAllInfo = async () => {
        let riskAreaList: any = await docs.getRiskDDL('riskArea')
        let riskFactorsList: any = await docs.getRiskDDL('riskFactors')
        let protectiveFactorsList: any = await docs.getRiskDDL('protectiveFactors')
        localStorage.setItem('riskList', JSON.stringify(riskAreaList?.resultData));
        localStorage.setItem('riskFactorList', JSON.stringify(riskFactorsList?.resultData));
        localStorage.setItem('riskProtectiveList', JSON.stringify(protectiveFactorsList?.resultData));

        const multiDocData: any = await customAxios.post(`${API_ENDPOINTS.GET_MULTIPLE_DOCUMENT_DETAIL}`, [latestDocumentId]);

        if (multiDocData && multiDocData.resultData.length > 0) {
            const documentDetailItems = multiDocData.resultData[0]
            const hadDx = (documentDetailItems.documentDetails.documentCommonControls.documentGoals?.length ?? 0 > 0) ? true : false
            const hasGoals = (documentDetailItems.documentDetails.documentCommonControls.documentDiagnosis?.length ?? 0 > 0) ? true : false

            const docData = {
                // staffInfo: staffInfo,
                documentDetail: convertServerDocumentToLocal(documentDetailItems.documentDetails),
                // diagnosisList: diagnosisList,
                docSignature: documentDetailItems.staffSignatures,
                docClientSignature: documentDetailItems.clientSignatures,
                isHtmlFileTypeTemplate: documentDetailItems.documentDetails.isHtmlFileTypeTemplate,
                htmlFileName: documentDetailItems.documentDetails.htmlFileName,
                template: mapDocumentTemplate(documentDetailItems.template),
                fieldsMapping: documentDetailItems.documentDetails.documentFieldsMappings,
                documentId: documentDetailItems.documentId,
                docTreatmentPlans: documentDetailItems.documentDetails?.documentTreatmentPlans,
                // historyList: historyList,
                docHasControlls: (hadDx || hasGoals),
                documentCommonControls: documentDetailItems.documentDetails.documentCommonControls,
                capNoteData: documentDetailItems.documentDetails.capNoteData,
                capServiceName: documentDetailItems.documentDetails.capServiceName
            }
            setDocumentDetailData(docData)
        }
    }
console.log(currentDocumentData,'currentDocumentData')
    return (
        <Dialog onClose={onClose} title={"View Last Document"} className="dialog-modal xl-modal scrollwidth" >
            <div>
                {
                    !isDataFetched ? <Loading></Loading> :

                        <DocumentTemplatePdf
                            documentPrintData={[{
                                staffInfo: currentDocumentData.staffInfo,
                                documentDetail: currentDocumentData.documentDetail,
                                diagnosisList: currentDocumentData.diagnosisList,
                                docSignature: currentDocumentData.docSignature,
                                docClientSignature: currentDocumentData.docClientSignature,
                                isHtmlFileTypeTemplate: currentDocumentData.isHtmlFileTypeTemplate,
                                htmlFileName: currentDocumentData.htmlFileName,
                                template: currentDocumentData.template,
                                fieldsMapping: currentDocumentData.fieldsMapping,
                                documentId: currentDocumentData.documentId,
                                docTreatmentPlans: currentDocumentData.documentFormattedVal?.documentTreatmentPlans,
                                historyList: currentDocumentData.historyList,
                                docHasControlls: currentDocumentData.docHasControlls,
                                documentCommonControls: currentDocumentData.documentCommonControls,
                                mileagePayRate: currentDocumentData?.mileagePayRate,
                                capNoteData:currentDocumentData.capNoteData,
                                capServiceName:currentDocumentData.capServiceName
                            }]}
                            onlyView={true}
                            metaDataDocs={documentMeta}
                            isClientFileDoc={undefined}
                            onClose={()=>{}}
                        />

                }
            </div>
        </Dialog>
    )
}
export default LatestDocumentId

