import React from "react";

interface EditableCellProps {
    dataItem: any;
    field: string;
    onEdit: (dataItem: any) => void;
    validateDataItemForEditVisiblity: any;
}

const EditableCell: React.FC<EditableCellProps> = ({ dataItem, field, onEdit, validateDataItemForEditVisiblity }) => {
    if (!validateDataItemForEditVisiblity(dataItem)) return <td>{dataItem[field]}</td>;
    return (
        <td>
            <span>{dataItem[field]}</span>
            <button
                onClick={() => onEdit(dataItem)}
                title={`Edit`}
                className="bg-transparent border-0 text-theme"
                data-toggle="tooltip"
                data-placement="top"
            >
                <i className="fa-solid fa-pencil ml-2"></i>
            </button>
        </td>
    );
};

export default EditableCell;
