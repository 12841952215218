import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Checkbox } from "@progress/kendo-react-inputs";
import moment from "moment";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import helper from "src/helper/api-helper";
import { updateDocumentDiagnosis } from "src/redux/actions";
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { showError } from "src/util/utility";
import Loading from "../../../control-components/loader/loader";

const DiagnosisModel = ({ onClose, clientId, diagnosisListOfAllClient, documentId, documentFormattedVal }) => {
    const dispatch = useDispatch();
    const [selectedDiagId, setSelectedDiagId] = useState<any>(documentFormattedVal?.clientDiagnosisId);
    const [selectedDiag, setSelectedDiag] = useState<any>();
    const [diagnosisList, setDiagnosisList] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (diagnosisListOfAllClient) {
            setLoading(false)
            setDiagnosisList(diagnosisListOfAllClient.find((x: any) => x.clientId == clientId)?.diagnosis || [])
        } else {
            fetchDiagnosisList();
        }
    }, [diagnosisListOfAllClient]);

    function fetchDiagnosisList() {
        setLoading(true)
        const body = {
            clientIds: [clientId],
            isActive: true,
        };
        helper
            .postRequest(API_ENDPOINTS.GET_CLIENTS_DIAGNOSIS, body)
            .then((result) => {
                const data = result.resultData;
                setDiagnosisList(data.find((x: any) => x.clientId == clientId)?.diagnosis || []);
            })
            .catch((error) => {
                showError(error, "Fetch Diagnosis List");
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleSubmit = () => {
        if (selectedDiagId === documentFormattedVal?.clientDiagnosisId) {
            onClose();
            return;
        }

        const data = {
            documentId: documentId,
            dxId: selectedDiagId,
            dxName: selectedDiag.icd10,
        };
        dispatch(updateDocumentDiagnosis(data));
        onClose();
    };

    const handleDiagChange = (e: any, diagnosis: any) => {
        if (e.value) {
            setSelectedDiagId(diagnosis.id);
            setSelectedDiag(diagnosis)
        } else if (diagnosis.id == selectedDiagId) {
            setSelectedDiagId(undefined);
        }
    };

    const CustomCheckBox = (props) => {
        const diagnosis = props.dataItem;
        let diagId = diagnosis.id;
        let checked = selectedDiagId == diagId;

        return (
            <td>
                <div className="k-chip-content">
                    <Checkbox
                        id={diagId}
                        name={diagnosis.diagnoseName}
                        value={checked}
                        checked={checked}
                        onChange={(e) => handleDiagChange(e, diagnosis)}
                    />
                </div>
            </td>
        );
    };

    function renderMainView() {
        if (!diagnosisList || diagnosisList.length === 0) {
            return (
                <div className="k-form-error">
                    <p>{"Diagnosis missing for " + documentFormattedVal?.clientNameDoc}.</p>
                </div>
            );
        }

        return (
            <div className="grid-table blueThemeTable">
                <Grid data={diagnosisList}>
                    <GridColumn
                        cell={(props) => <CustomCheckBox {...props} />}
                        className="cursor-default icon-align"
                        width="70px"
                    />
                    <GridColumn
                        title="Diagnosis"
                        cell={(props) => {
                            let field = props.dataItem;
                            return <td>{field?.icd10 + " (" + field?.diagnoseName + ")"}</td>;
                        }}
                    />
                    <GridColumn
                        field="dateDiagnose"
                        title="Date"
                        cell={(props) => {
                            let field = props.dataItem;
                            return <td>{moment(field.dateDiagnose).format("M/D/YYYY")}</td>;
                        }}
                    />
                </Grid>
            </div>
        );
    }

    return (
        <Dialog
            onClose={onClose}
            title="Edit Diagnosis"
            className="small-dailog medium-modal width_90 blue_theme"
            width="50%"
            height="60%"
        >
            {loading && <Loading />}
            {!loading && <div className="edit-client-popup custom-client-popup editDiagnosisHeight pb-0" >
                <div className="popup-modal slibling-data ">
                    {renderMainView()}
                </div>
                <div className="preview-btn-bottom mt-2">
                    <div className="border-bottom-line"></div>
                    <div className="d-flex my-3">
                        <div className="right-sde">
                            <button className="submitButon" onClick={handleSubmit}>
                                Submit
                            </button>
                        </div>
                        <div className="right-sde-grey">
                            <button className="cancelBtn" onClick={onClose}>
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>}
        </Dialog>
    );
};

export default DiagnosisModel;
