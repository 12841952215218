import React, { useState, ComponentState, forwardRef } from "react";
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { Button } from "@progress/kendo-react-buttons";
import { DocumentExcelList } from "src/dataModels/documentExcelList";
import moment from "moment";
import { useSelector } from "react-redux";
import { permissionEnum, userIsSuperAdmin } from "../../../helper/permission-helper";

const DocumentExportToExcel = forwardRef((props: any, ref: any) => {
  const state: ComponentState = useSelector(states => {
    return states;
  });
  const { documentList } = props;
  const documentExportToExcelList: DocumentExcelList[] = convertList(documentList);
  const _exporter = React.useRef<ExcelExport | null>(null);
  const isBillingManager = state.getRolePermission.isBillingManager;
  const isHumanResourcesManager = state.getRolePermission.isHumanResourcesManager
  const staffLoginInfo = state.getStaffReducer;
  const userAccessPermission = useSelector(
    (state: ComponentState) => state.userAccessPermission
  );
  const exportExcel = () => {
    if (_exporter.current) {
      _exporter.current.save();
    }
  }
  function formatAsUSD(amount) {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(amount);
  }
  function handleBillingComment(jsonData: any): string {
    if (!jsonData) {
      return "";
    }
    let data = JSON.parse(jsonData);

    if (Array.isArray(data)) {
      return data
        .map((item, index) => `${index + 1}. ${item?.comment || ""}`)
        .join(", ");
    }
    else {
      return data?.comment || "";
    }
  }

    function handlePayrollStatus(fields: any) {
    const comments = fields?.payrollComments ? ` (${fields.payrollComments})` : '';
    if (fields?.payrollPaid === 'YES') {
      return ('Paid on ' + moment(fields.payrollDate).format("M/DD/YY") + comments);
    }
    else if (fields?.payrollPaid === 'RO') {
      return ('RO  ' + moment(fields.payrollDate).format("M/DD/YY") + comments)
    }
    else if ((fields?.payrollPaid === 'NO')) {
      return `No${comments}`
    }
    else {
      return (`NR${comments}`)
    }
  }

  function convertList(originalList: number[]): DocumentExcelList[] {
    const newList: DocumentExcelList[] = [];
    for (const item of documentList) {
      const obj: DocumentExcelList =
      {
        ID: item?.id,
        DOS: item?.serviceDateObj,
        Client: (item?.clientLName + ", " + item?.clientFName),
        DOB: item?.dateOfBirth,
        Service: item?.service,
        Duration: item?.timeStr,
        PlaceOfService: item?.placeOfSerCode.join(', '),
        StaffSupervisor: item?.supervisorName !== null ? (item?.createdByStaff + "(" + (item?.supervisorName) + ")") : item?.createdByStaff,
        DX: item?.clientDiagnosisName,
        DocStatus: item?.docStatus,
        AuthID: item?.custAuthId,
        BillStatus: item?.billingStatusName,
        BillingComment: handleBillingComment(item?.billingComments),
        PrimaryInsurance: item?.primaryInsurance,
        Units: item?.numUnits,
        SevRate: formatAsUSD(item?.serviceRate),
        TotalBilled: formatAsUSD(item?.amtBilled),
        SiteOFService: item?.siteName,
        DateSubmitted: moment.utc(item?.utcDateCreated).local().format("M/D/YYYY"),
        Locked: item?.isLocked,
        Printed : item?.isPrinted,
        Signed: item?.isSignedByClient ? "Client Signed" : item?.isSignedByReviewer ? "Reviewer Signed" : item?.isSignedBySupervisor ? "Supervisor Signed" : item?.isSignedByAuthor ? "Author Signed" :item.isSignedByParent?"Parent signed":"",
        Template: item?.documentTemplateName + `  (${item?.templateTypeName})`,
        Payroll: handlePayrollStatus(item),
        LastBilledDate: item.lastBilledDate != null ? (moment(item.lastBilledDate).format("MM/DD/YY")) + "(" + item?.batchId + ")" : "",
        AmtPaid: formatAsUSD(item?.amtPaid),
        DatePaid: item?.datePaid != null ? moment.utc(item.datePaid).local().format("M/D/YYYY") : ""
      };
      newList.push(obj);
    }
    return newList;
  }
  return (
    <div>
      <Button onClick={exportExcel} className="cancelBtn" style={{ height: '34px' }}>
        <i className="fa-regular fa-file-excel "></i> Export to Excel
      </Button>
      <ExcelExport
        data={documentExportToExcelList}
        fileName="Documents.xlsx"
        ref={_exporter}>
        <ExcelExportColumn field="ID" title="ID" width={100} locked={true} />
        <ExcelExportColumn field="DOS" title="DOS" width={150} />
        <ExcelExportColumn field="Template" title="Template" />
        <ExcelExportColumn field="Client" title="Client" />
        <ExcelExportColumn field="DOB" title="DOB" />
        <ExcelExportColumn field="Service" title="Service" />
        <ExcelExportColumn field="Duration" title="Duration" />
        <ExcelExportColumn field="PlaceOfService" title="Place of Service" />
        <ExcelExportColumn field="StaffSupervisor" title="Staff (Supervisor)" />
        <ExcelExportColumn field="DX" title="DX" />
        <ExcelExportColumn field="DocStatus" title="Doc Status" />
        <ExcelExportColumn field="PrimaryInsurance" title="Payer" />
        <ExcelExportColumn field="Units" title="Units" />
        {isBillingManager && <ExcelExportColumn field="SevRate" title="Sevice Rate" />}
        {isBillingManager && <ExcelExportColumn field="TotalBilled" title="Total Billed" />}
        {isBillingManager && <ExcelExportColumn field="BillStatus" title="Bill Status" />}
        {(isBillingManager || userIsSuperAdmin(staffLoginInfo?.roleId)) && <ExcelExportColumn field="BillingComment" title="Billing Comment" />}
        {isBillingManager && <ExcelExportColumn field="LastBilledDate" title="Last Billed" />}
        {isBillingManager && <ExcelExportColumn field="AmtPaid" title="Amt Paid" />}
        {isBillingManager && <ExcelExportColumn field="DatePaid" title="Date Paid" />}
        {userAccessPermission[permissionEnum.MANAGE_AUTHORIZATIONS] && <ExcelExportColumn field="AuthID" title="Auth ID" />}
        <ExcelExportColumn field="SiteOFService" title="Site Of Service" />
        <ExcelExportColumn field="DateSubmitted" title="Date Submitted" />
        <ExcelExportColumn field="Locked" title="Locked" />
        <ExcelExportColumn field="Signed" title="Signed" />
        <ExcelExportColumn field="Printed" title="Printed" />
        {(isHumanResourcesManager || userIsSuperAdmin(staffLoginInfo?.roleId)) && <ExcelExportColumn field="Payroll" title="Payroll" />}
      </ExcelExport>
      &nbsp;&nbsp;
    </div>
  );
});
export default DocumentExportToExcel;
