import { CALENDAR_SETTING_TYPES } from '../actions/types_calendar_setting';
import { EventTitleResponseDataModal } from 'src/dataModels/calendarSettingModal';


const initialState = {
    calendarEventTitleResp: [] as EventTitleResponseDataModal[]
};

export const CalendarSettingReducer = (state = initialState, action:any) => {
    const oldState = { ...state };
    const { type, payload } = action;
    switch (type) {

        case CALENDAR_SETTING_TYPES.SAVE_CALENDAR_EVENT_TITLE:
            return {
                ...oldState,
                calendarEventTitleResp: payload,
            };
    }
    return oldState;
};