import { filterBy } from "@progress/kendo-data-query";
import { Dialog } from "@progress/kendo-react-dialogs";
import { MultiSelect } from "@progress/kendo-react-dropdowns";
import React, { ComponentState, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputKendoRct from "src/control-components/input/input";
import helper from "src/helper/api-helper";
import { getDocumentbyId, getPOS, savePOSPayload } from 'src/redux/actions';
import { API_ENDPOINTS } from "src/services/api-endpoints";
import { showError } from "src/util/utility";

const PlaceOfServiceModel = ({ onClose, documentId, templateId }) => {

    const state: ComponentState = useSelector(states => { return states; });
    const { POSlist, documentsDetail } = state['DocumentReducer']
    const dispatch = useDispatch()

    const [selectedPOS, setSelectedPOS] = useState<any[]>([])
    const [isAccepted, setIsAccepted] = useState(false)
    const [placeOfServiceList, setPlaceOfServiceList] = useState<any[]>([])
    const [filter, setFilter] = useState<any>({});
    const [showCommunityText, setShowCommunityText] = useState(false);
    const [communityText, setCommunityText] = useState('');
    const [templateSetting, setTemplateSetting] = useState<any>();

    const posTypeEnum = { notRequired: 1, singleSelection: 2, multiSelection: 3 };

    useEffect(() => {
        if (!isAccepted) {
            dispatch(getPOS(0))
            dispatch(getDocumentbyId(documentId))
            setIsAccepted(true)
        }
    }, [isAccepted])

    useEffect(() => {
        if (!templateSetting) {
            helper
                .queryGetRequestWithEncryption(
                    API_ENDPOINTS.GET_DOCUMENT_TEMPLATE_PREF_BY_TEMPLATE_ID,
                    templateId
                )
                .then((result) => {
                    if (result.resultData) {
                        console.log(result)
                        setTemplateSetting(result.resultData)
                    }
                })
                .catch(err => showError(err, "Fetching Template Settings"))
        }
    }, [templateSetting])

    useEffect(() => {
        if (POSlist) {
            setPlaceOfServiceList(POSlist)
            if (documentsDetail?.placeOfServiceList && documentsDetail?.placeOfServiceList.length > 0) {
                setSelectedPOS(
                    POSlist.filter((pos1) =>
                        documentsDetail?.placeOfServiceList.find(
                            (pos2) => pos2.id == pos1.id || pos2.placeOfServiceId == pos1.id
                        )
                    )
                );
                setCommunityText(documentsDetail?.specificPlaceOfService);
            }
        }
    }, [POSlist, documentsDetail])

    useEffect(() => {
        const isCommunity99Available = selectedPOS?.find(x => x.name.startsWith("99")) != undefined;
        if (!isCommunity99Available) {
            setCommunityText('')
        }
        setShowCommunityText(isCommunity99Available)
    }, [selectedPOS]);

    const handleSubmit = () => {

        let body: any = {
            documentId: documentId,
            placeOfServiceId: selectedPOS?.map((item: any) => item?.id),
            placeOfServiceDetailList: selectedPOS
        }

        if (communityText && communityText.length > 0) {
            body = {
                ...body,
                'specificPlaceOfService': communityText
            }
        }

        dispatch(savePOSPayload(body))
        onClose()

    }

    function onFilterChange(event, listName) {
        setFilter({ ...filter, [listName]: event.filter });
    }

    function renderMultiSelectableItem(li, itemProps) {
        const itemChildren = (
            <span>
                <input
                    type="checkbox"
                    name={itemProps.dataItem}
                    checked={itemProps.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                />
                &nbsp;{li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }

    const handlePlaceOfServiceSelection = (selectedValue: any) => {
        if (templateSetting?.posTypeId === posTypeEnum.singleSelection) {
            // Enforce single selection by storing only the last selected value
            setSelectedPOS(selectedValue.length > 0 ? [selectedValue[selectedValue.length - 1]] : []);
        } else {
            setSelectedPOS(selectedValue); // Allow multiple selections
        }
    };


    function renderCommunityText() {
        return (
            <>
                {(
                    <div className="form-group mb-3 col-mg-6">
                        <InputKendoRct
                            value={communityText}
                            onChange={(handleTextChange) => {
                                setCommunityText(handleTextChange.value)
                            }}
                            name="custAuthId"
                            label="Specific Place"
                        />
                    </div>
                )}
            </>
        );
    }

    return (
        <Dialog
            onClose={onClose}
            title={"Edit Place of  Service"}
            className="small-dailog medium-modal width_90 blue_theme" width={"50%"} height={"50%"}
        >
            <div className="edit-client-popup">
                <div className="popup-modal slibling-data">
                    <div className="form-group mb-3 col-mg-6">
                        <h6>Select Place of Service</h6>
                        <MultiSelect
                            disabled={templateSetting?.posTypeId === posTypeEnum.notRequired}
                            filterable={true}
                            data={filterBy(placeOfServiceList, filter.placeOfServiceList)}
                            onFilterChange={(event) => {
                                onFilterChange(event, "placeOfServiceList");
                            }}
                            textField="name"
                            name="selectedPOS"
                            ariaDescribedBy="selectedPOS"
                            value={selectedPOS}
                            onChange={(event) => handlePlaceOfServiceSelection(event.value)}
                            itemRender={
                                templateSetting?.posTypeId === posTypeEnum.singleSelection
                                    ? undefined
                                    : renderMultiSelectableItem
                            }
                        />
                        {templateSetting?.posTypeId === posTypeEnum.notRequired &&
                            <p className="error-message">Your template settings do not allow you to update Place of Service.</p>
                        }
                        {showCommunityText && renderCommunityText()}
                    </div>
                </div>
                <div className="preview-btn-bottom">
                    <div className="border-bottom-line"></div>
                    <div className="d-flex my-3">
                        <div className="right-sde">
                            <button
                                className="submitButon"
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </div>
                        <div className="right-sde-grey">
                            <button
                                className="cancelBtn"
                                onClick={onClose}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default PlaceOfServiceModel