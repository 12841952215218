import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { ClientService } from "../../../../../../services/clientService";
import NotificationManager from "react-notifications/lib/NotificationManager";
import APP_ROUTES from "src/helper/app-routes";
import RecentIssue from "./recent-issues";
import { renderErrors } from "src/helper/error-message-helper";
import ClientQuestionnaireService from "./functionToSaveQuestionnaireFromPortal";

const MoodDisorderQuestionnaire = ({  questionId, response,isPrint, isFromCP, queId, token }) => {
  const MoodDisorderQuestions = [
    {
      id: 1,
      questionId: "MDQ_SQ1",
      name: " ...you felt so good or so hyper that other people thought you were not your  normal self or you were so hyper that you got into trouble?",
      value: null,
    },
    {
      id: 2,
      questionId: "MDQ_SQ2",
      name: " ...you were so irritable that you shouted at people or started fights or arguments?",
      value: null,
    },
    {
      id: 3,
      questionId: "MDQ_Q3",
      name: "...you felt much more self-confident than usual?",
      value: null,
    },
    {
      id: 4,
      questionId: "MDQ_Q4",
      name: "...you got much less sleep than usual and found you didn\'t really miss it?",
      value: null,
    },
    {
      id: 5,
      questionId: "MDQ_Q5",
      name: "...you were much more talkative or spoke much faster than usual?",
      value: null,
    },
    {
      id: 6,
      questionId: "MDQ_Q6",
      name: "...thoughts raced through your head or you couldn\'t slow your mind down?",
      value: null,
    },
    {
      id: 7,
      questionId: "MDQ_Q7",
      name: "...you were so easily distracted by things around you that you had trouble concentrating or staying on track?",
      value: null,
    },
    {
      id: 8,
      questionId: "MDQ_Q8",
      name: "...you had much more energy than usual?",
      value: null,
    },
    {
      id: 9,
      questionId: "MDQ_Q9",
      name: "...you were much more active or did many more things than usual?",
      value: null,
    },
    {
      id: 10,
      questionId: "MDQ_Q10",
      name: "...you were much more social or outgoing than usual, for example, you telephoned friends in the middle of the night?",
      value: null,
    },
    {
      id: 11,
      questionId: "MDQ_Q11",
      name: "...you were much more interested in sex than usual?",
      value: null,
    },
    {
      id: 12,
      questionId: "MDQ_Q12",
      name: "...you did things that were unusual for you or that other people might have thought were excessive, foolish, or risky?",
      value: null,
    },
    {
      id: 13,
      questionId: "MDQ_Q13",
      name: "...spending money got you or your family into trouble?",
      value: null,
    },
  ];
  
  const MoodDisorder = [
    {
      id: 1,
      questionId: "MDQ_Q1",
      name: " Has there ever been a period of time when you were not your usual self and...",
      value: Array(MoodDisorderQuestions.length).fill(null),
      type: null,
      question: MoodDisorderQuestions,
    },
    {
      id: 2,
      questionId: "MDQ_Q2",
      name: "If you checked YES to more than one of the above, have several of these ever happened during the same period of time?",
      value: null,
      type: "boolean",
      isSelected: null,
      openId: 3,
    },
    {
      id: 3,
      questionId: "MDQ_Q3",
      name: "How much of a problem did any of these cause you - like being unable to work; having family, money or legal troubles; getting into arguments or fights?",
      value: 0,
      type: "dropdown",
      isOpen: 0,
      option: [
        {
          label: "No problem",
          value: 0,
        },
        {
          label: "Minor problem ",
          value: 1,
        },
        {
          label: "Moderate problem",
          value: 2,
        },
        {
          label: "Serious problem",
          value: 3,
        },
      ],
    },
    {
      id: 4,
      questionId: "MDQ_Q4",
      name: " Have any of your blood relatives (i.e. children, siblings, parents, grandparents, aunts, uncles) had manic-depressive illness or bipolar disorder?",
      value: null,
      type: "boolean",
    },
    {
      id: 5,
      questionId: "MDQ_Q5",
      name: " Has a health professional ever told you that you have manic-depressive illness or bipolar disorder?",
      value: null,
      type: "boolean",
    },
  ];
  const list = MoodDisorder;
  const { state } = useLocation();
  const navigate = useNavigate();
  const selectedClientId = useSelector((state) => state.selectedClientId);
  const [loading, setLoading] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [listItems, setListItems] = useState(!isPrint ? list : []);
  const [totalSum, setTotalSum] = useState(0);
  const [isQuestionnaireSubmit, setQuestionnaireSubmit] = useState(0);

  useEffect(() => {
    if(response[1]?.value === "1") {
      handleRadioChange(2, 1)
    }
  }, [])

  useEffect(() => {
    if (state?.field?.id || isPrint) {
      const updatedGAD = list.map((question) => {
        const matchingQuestion = response?.find(
          (item) => item.questionId === question.questionId
        );
      

        if (matchingQuestion) {
          if (matchingQuestion.questionId === list[0].questionId) {
            const value = JSON.parse(matchingQuestion.value);
            const ques = [...question.question];
            value?.forEach((v, idx) => {
              ques[idx] = { ...ques[idx], value: v };
            });
            return { ...question, question: ques, value };
          }
          return { ...question, value: parseInt(matchingQuestion.value) };
        }
        return question;
      });
      setListItems(updatedGAD);
    } else {
      setListItems(list);
    }
  }, [response]);

  const handleRadioChange = (itemId, value) => {
    let openStatus = 0;
    let openStatusID = 0;
    const updatedItems = listItems?.map((item) => {
      if (item.id === itemId) {
        if (!!item.openId) {
          openStatusID = item.openId;
          openStatus = value;
        }
        return { ...item, value: value };
      }
      if (item.id === openStatusID) {
        item.isOpen = openStatus;
        openStatusID = 0;
        openStatus = 0;
      }
      return item;
    });
    setListItems(updatedItems);
  };

  const handeNestedItem = (itemId, questionId, value) => {
    const updatedItems = listItems.map((item) => {
      if (item.questionId === itemId) {
        const question = item.question.map((ques, idx) => {
          if (ques.id === questionId) {
            item.value[idx] = value;
            return {
              ...ques,
              value,
            };
          }
          return ques;
        });
        return {
          ...item,
          question,
        };
      }
      return item;
    });
    setListItems(updatedItems);
  };

  const handleSubmit = async () => {
    await ClientService.insertClientModdQuestionnaire(
      selectedClientId,
      totalSum,
      listItems,
      questionId,
      queId,
      token
    )
      .then((result) => {
        let questionList = result.resultData;
        const updatedItems = listItems.map((item) => {
         
          return item;
        });
        setListItems(list);
        navigate(APP_ROUTES.QUESTIONNAIRE);
        NotificationManager.success("Add successfully");
      })
      .catch((error) => {
        setLoading(false);
        renderErrors(error.message);
      });
  };

  const arr = Object.entries(selectedValues).map(([key, value]) => {
    return { key: parseInt(key), value };
  });

  const handleDropdownChange = (itemId, value) => {
    // Update the listItems state with the new value
    const updatedListItems = listItems?.map((item) => {
      if (item.id === itemId) {
        return { ...item, value: parseInt(value) };
      }
      return item;
    });
    setListItems(updatedListItems);
  };

  const getDynamicContent = (item) => {
    
    switch (item.type) {
      case null:
        return <></>;

      case "dropdown":
        return (
          <>
            {isPrint? <label className="select_listing col-md-4">{

            item.value==0?'0 - No Problem':
            item.value==1?'1 - Minor Problem':
            item.value==2?'2 - Moderate Problem':
            '3 - Serious Problem'}
            </label>
            :(!!item.isOpen) ? (
              <div className="select_listing col-md-4">
                <select
                  value={item.value}
                  onChange={(e) =>
                    handleDropdownChange(item.id, e.target.value)
                  }
                >
                  <option value={0}>0 - No Problem</option>
                  <option value={1}>1 - Minor Problem</option>
                  <option value={2}>2 - Moderate Problem </option>
                  <option value={3}>3 - Serious Problem</option>
                </select>
              </div>
            ) : (
              ""
            )}
          </>
        );

      case "boolean":
        return (
          <>
            <div className="col-12 select-table-drops">
              <label className="cats_label">
                <input
                  type="radio"
                  name={`selection-${item.id}`}
                  value={1}
                  checked={item.value === 1}
                  onChange={() => handleRadioChange(item.id, 1)}
                  className="mr-2 mb-0"
                />
                Yes
              </label>
              <label className="cats_label">
                <input
                  type="radio"
                  name={`selection-${item.id}`}
                  value={0}
                  checked={item.value === 0}
                  onChange={() => handleRadioChange(item.id, 0)}
                  className="mr-2 mb-0"
                />
                No
              </label>
            </div>
          </>
        );

      default:
        break;
    }
  };

  const condition = 1;
  const conditionForNo = 2;
  const conditionForStringNo = "0";
  const conditionForIntNo = 0;
  const conditionForMdq_q1 = [0, 1];
  
  const foundItem = listItems?.find((item) => item.value === condition || item?.value === conditionForIntNo || item?.value === conditionForStringNo);
  
  const foundItemAnyYesOrNo = listItems?.find((item) => 
    item.value === condition || 
    (Array.isArray(item.value) && item.value.some(val => conditionForMdq_q1.includes(val))) || 
    item.value === conditionForNo
  );
  
  
  const handleSaveFromPortal = async () => {
    const formattedListItems = listItems.map((item, index) => 
      index === 0 ? { ...item, value: JSON.stringify(item.value) } : item
    );
    ClientQuestionnaireService.updatequestionnaireFromPortal(selectedClientId, totalSum, formattedListItems, queId, token)
  }

  const handleCompleteFromPortal = async () => {
    try {
      const formattedListItems = listItems.map((item, index) => 
        index === 0 ? { ...item, value: JSON.stringify(item.value) } : item
      );
      ClientQuestionnaireService.completeQuestionnaireFromPortal(false, queId, selectedClientId, totalSum, formattedListItems,  {isCompleted: true, inProgress: false, sentToClientPortal: null}, token)
    } catch (error) {
      console.error('Error completing client questionnaire:', error);
      NotificationManager.error('Failed to complete client questionnaire');
    }
  };
  

  return (
    <div className="child_main_table mt-3 col-12">
      <div>
      <div className="head-3">MDQ - Mood Disorder Questionnaire</div>
        <label className="pb-3 mt-2 border-bottom">
          The MDQ is the best at screening for bipolar I (depression and mania)
          disorder and is not as sensitive to bipolar || (depression and
          hypomania) or bipolar not otherwise specified (NOS) disorder.
        </label>
      </div>

      

      {
  isPrint ? (
    (foundItem || foundItemAnyYesOrNo || listItems[listItems.length - 1]?.isSelected) ? (
      <>
        {listItems?.map((item) => (
          <div key={item.id}>
            <div className="row mb-3 py-2 border-bottom">
              <div className="col-12">
                <label>
                  {item.id}. {item.name}
                </label>
              </div>

              {getDynamicContent(item)}
            </div>

            <ul className="pl-0">
              {item.question?.map((questionItem) => (
                <li style={{ listStyle: "none" }} key={questionItem.id}>
                  <div className="row mb-3 pb-2 border-bottom px-0">
                    <div className="col-12">
                      <label>{questionItem.name}</label>
                    </div>
                    <div className="col-12 select-table-drops">
                      <label className="cats_label">
                        <input
                          type="radio"
                          name={`selection-${questionItem.questionId}`}
                          value={1}
                          checked={questionItem.value === 1}
                          onChange={() =>
                            handeNestedItem(item.questionId, questionItem.id, 1)
                          }
                          className="mr-2 mb-0"
                        />
                        Yes
                      </label>
                      <label className="cats_label">
                        <input
                          type="radio"
                          name={`selection-${questionItem.questionId}`}
                          value={0}
                          checked={questionItem.value === 0}
                          onChange={() =>
                            handeNestedItem(item.questionId, questionItem.id, 0)
                          }
                          className="mr-2 mb-0"
                        />
                        No
                      </label>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </>
    ) : null ) : (
    listItems?.map((item) => (
      <div key={item.id}>
        <div className="row mb-3 py-2 border-bottom">
          <div className="col-12">
            <label>
              {item.id}. {item.name}
            </label>
          </div>

          {getDynamicContent(item)}
        </div>

        <ul className="pl-0">
          {item.question?.map((questionItem) => (
            <li style={{ listStyle: "none" }} key={questionItem.id}>
              <div className="row mb-3 pb-2 border-bottom px-0">
                <div className="col-12">
                  <label>{questionItem.name}</label>
                </div>
                <div className="col-12 select-table-drops">
                  <label className="cats_label">
                    <input
                      type="radio"
                      name={`selection-${questionItem.questionId}`}
                      value={1}
                      checked={questionItem.value === 1}
                      onChange={() =>
                        handeNestedItem(item.questionId, questionItem.id, 1)
                      }
                      className="mr-2 mb-0"
                    />
                    Yes
                  </label>
                  <label className="cats_label">
                    <input
                      type="radio"
                      name={`selection-${questionItem.questionId}`}
                      value={0}
                      checked={questionItem.value === 0}
                      onChange={() =>
                        handeNestedItem(item.questionId, questionItem.id, 0)
                      }
                      className="mr-2 mb-0"
                    />
                    No
                  </label>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    ))
  )
}

{isFromCP ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleCompleteFromPortal} className="submitButon">
            Save & Complete
          </button>
          <button onClick={handleSaveFromPortal} className="cancelBtn">
            Save progress
          </button>
          </div>
        ) : state == null && !isPrint ? (
          <div className="text-start mt-3 col-12">
            <button onClick={handleSubmit} className="submitButon">
              Submit
            </button>
          </div>
        ) : null}
      <label className=" mt-2">
        This instrument is designed for screening purposes only and not to be
        used as a diagnostic tool. Permission for use granted by RMA Hirschfeld,
        MD.
      </label>
    </div>
  );
};

export default MoodDisorderQuestionnaire;
