import { ComponentState, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DropDownKendoRct from "src/control-components/drop-down/drop-down";
import InputKendoRct from "src/control-components/input/input";
import { CapServiceGoals } from "src/dataModels/docListModel"
import { getCapNotesAssessments, getCapNotesIntervention } from "src/redux/actions";
import { NumericTextBox } from "@progress/kendo-react-inputs"

export interface CapNotesServiceGoalProps {
    isSavePressed: boolean,
    setCapNoteData: (data: CapServiceGoals[]) => void
    preselectedData: CapServiceGoals[]
    isViewMode?: boolean
    goalData?: any
}


export default function CapNotesServiceGoals(props: CapNotesServiceGoalProps) {
    const state: ComponentState = useSelector(states => {
        return states;
    });
    const { capNoteInterventions, capNoteAssessments } = state['DocumentReducer'];
    const [capNoteGoalData, setCapNoteGoalData] = useState<CapServiceGoals[]>([])
    const dispatch = useDispatch()
    const [isInitialDataset, setInitialDataSet] = useState(false)
    const [totalPrompts, setTotalPrompts] = useState(0)

    useEffect(() => {
        if (!capNoteInterventions || capNoteInterventions.length < 1) {
            dispatch(getCapNotesIntervention())
        }
        if (!capNoteAssessments || capNoteAssessments.length < 1) {
            dispatch(getCapNotesAssessments())
        }
    }, [])

    useEffect(() => {
        if (!props.isViewMode) {
            const list = props.goalData.map((x) => {
                return { goalId: x.id, goalName: x.goalName };
            });
            setCapNoteGoalData(list)
        }
    }, [props.goalData])

    const handleOldData = () => {
        if (props.preselectedData && props.preselectedData.length > 0 && capNoteGoalData.length > 0) {
            setCapNoteGoalData((prevItems) =>
                prevItems.map((item) => {
                    const dataItemInList = props.preselectedData.find((dataItem) => dataItem.goalId === item.goalId)
                    return dataItemInList ? dataItemInList : item
                }
                )
            );
        }
    }

    useEffect(() => {
        props.setCapNoteData(capNoteGoalData)
        if (capNoteGoalData && capNoteGoalData.length > 0) {
            if (!isInitialDataset) {
                handleOldData()
                setInitialDataSet(true)
            }
        }
        if (props.isViewMode) {
            const prompts = props.preselectedData.reduce((sum, item) => sum + Number(item?.noOfPrompts ?? 0), 0);
            setTotalPrompts(prompts)
        } else {
            const prompts = capNoteGoalData.reduce((sum, item) => sum + Number(item?.noOfPrompts ?? 0), 0);
            setTotalPrompts(prompts)
        }


    }, [capNoteGoalData])


    const updateData = (index, newValue, item) => {
        setCapNoteGoalData((prevItems) => {
            const updatedItems = [...prevItems];
            if (updatedItems[index]) {
                updatedItems[index] = { ...updatedItems[index], [item]: newValue };
            }
            return updatedItems;
        });
    };


    return <>{
        props.isViewMode ?
            <div>
                <div className="row mt-2"><div className=" col-lg-3 col-md-3 col-sm-6 col-12 position-relative">
                    <h6>{'Goal'}</h6></div>
                <div className=" col-lg-3 col-md-3 col-sm-6 col-12 icon-time position-relative">
                    <h6>{'Intervention'}</h6></div>
                <div className="mb-1 col-lg-3 col-md-3 col-sm-6 col-12 icon-time position-relative">
                    <h6>{'Assessment'}</h6></div>
                <div className="mb-1 col-lg-3 col-md-3 col-sm-6 col-12 icon-time position-relative">
                    <h6>{'# of Prompts'}</h6></div></div>  
                {props?.preselectedData?.map((goalItem: CapServiceGoals, index: number) => <>
                    <div className="row">
                        <div className="mb-3 col-lg-3 col-md-3 col-sm-6 col-12 position-relative">

                            <p className="col-12 mb-0 mt-0 px-0 printableHeader">{goalItem.goalName}</p>
                        </div>
                        <div className="mb-3 col-lg-3 col-md-3 col-sm-6 col-12 icon-time position-relative">

                            <p className="col-12 mb-0 mt-0 px-0 printableHeader">{goalItem.capInterventionName}</p>
                        </div>
                        <div className="mb-3 col-lg-3 col-md-3 col-sm-6 col-12 icon-time position-relative">

                            <p className="col-12 mb-0 mt-0 px-0 printableHeader">{goalItem.capAssessmentName}</p>
                        </div>
                        <div className="mb-3 col-lg-3 col-md-3 col-sm-6 col-12 icon-time position-relative">

                            <p className="col-12 mb-0 mt-0 px-0 printableHeader">{goalItem.noOfPrompts}</p>
                        </div>
                    </div>
                </>)}
                <div className="mb-3 icon-time position-relative d-flex justify-content-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 pr-0">
                        <h6>{'Total Prompts:'}</h6>
                        <p className="col-12 mb-0 mt-0 px-0 printableHeader">{totalPrompts}</p>
                    </div>
                    </div>
            </div>

            :
            <div>
                {capNoteGoalData?.map((goalItem: CapServiceGoals, index: number) => <>
                    <div className="row">
                        <div className="mb-3 col-lg-3 col-md-3 col-sm-6 col-12 position-relative">
                            {/* <h6>{'Goal'}</h6> */}
                            <p className="col-12 mb-0 mt-0 px-0 printableHeader">{goalItem.goalName}</p>
                        </div>
                        <div className="mb-3 col-lg-3 col-md-3 col-sm-6 col-12 icon-time position-relative">
                            <h6>{'Intervention'}</h6>
                            <DropDownKendoRct
                                onChange={(e) => {
                                    updateData(index, e?.value?.id ? e?.value?.id : undefined, 'capInterventionId')
                                    updateData(index, e?.value?.name ? e?.value?.name : undefined, 'capInterventionName')
                                }}
                                value={goalItem.capInterventionId && capNoteInterventions.find((item) => item.id === goalItem.capInterventionId)}
                                textField="name"
                                name=""
                                data={capNoteInterventions}
                                required={true}
                                dataItemKey="id"
                                validityStyles={props.isSavePressed}
                                error={(!goalItem.capInterventionId && props.isSavePressed) && 'Required*'}
                            />
                        </div>
                        <div className="mb-3 col-lg-3 col-md-3 col-sm-6 col-12 icon-time position-relative">
                            <h6>{'Assessment'}</h6>

                            <DropDownKendoRct
                                onChange={(e) => {
                                    updateData(index, e?.value?.id ? e?.value?.id : undefined, 'capAssessmentId')
                                    updateData(index, e?.value?.name ? e?.value?.name : undefined, 'capAssessmentName')
                                }}
                                value={goalItem.capAssessmentId && capNoteAssessments.find((item) => item.id === goalItem.capAssessmentId)}
                                textField="name"
                                name="assessment"
                                data={capNoteAssessments}
                                required={true}
                                dataItemKey="id"
                                validityStyles={props.isSavePressed}
                                error={(!goalItem.capAssessmentId && props.isSavePressed) && 'Required*'}
                            />
                        </div>
                        <div className="mb-3 col-lg-3 col-md-3 col-sm-6 col-12 icon-time position-relative marginTopFix">
                            <h6 className="mb-0">{'# of Prompts'}</h6>
                            <NumericTextBox
                                min={0} // Prevents negative values
                                defaultValue={0}
                                value={goalItem.noOfPrompts}
                                step={1}
                                format="n0"
                                name="noOfPrompts"
                                onChange={(e) => { updateData(index, e?.value ? e.value : undefined, 'noOfPrompts') }}
                            />

                        </div>
                    </div>

                </>)
                }
                <div className="mb-3 icon-time position-relative d-flex justify-content-end">
                    <div className="col-lg-3 col-md-3 col-sm-6 col-12 pr-0">
                        <h6>{'Total Prompts:'}</h6>
                        <InputKendoRct
                            validityStyles={false}
                            value={totalPrompts}
                            disabled={true}
                            onChange={() => { }}
                            name="noOfPrompts"
                            min={0}
                            // error={fields.middleName && errors.middleName}
                            type="number"
                        />
                    </div>
                </div>
            </div>

    }</>
}