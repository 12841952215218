import { UserAuthRequest, UserAuthResponse } from "../dataModels/authDataModels";
import { UserPermissions } from "../dataModels/userPermissionModels";
import { SettingsResponse } from "../dataModels/userSettingsModel";
import { API_ENDPOINTS } from "./api-endpoints";
import { customAxios } from "./useExPressApi";



export const getUser = {
  auth: (data: UserAuthRequest) => {
    return customAxios.post<any, UserAuthResponse>(`${API_ENDPOINTS.LOGIN}`, data);
  },

  getStaffSettings: (id: string) => {
    return customAxios.get<any, SettingsResponse>(`${API_ENDPOINTS.GET_STAFF_SETTING}${id}`);
  },

  getUserPermissions: () => {
    return customAxios.get<any, UserPermissions>(API_ENDPOINTS.GET_USER_PERMISSIONS);
  },
  getAuthTransaction: (id: number) => {
    return customAxios.get<any, SettingsResponse>(`${API_ENDPOINTS.GET_AUTH_TRANSACTION}${id}`);
  },

  updateAuthBatchEndDate: (data: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_AUTH_BATCH_END_DATE}`, data);
  },

  updateServiceBatchEndDate: (data: any) => {
    return customAxios.post<any>(`${API_ENDPOINTS.UPDATE_SERVICE_BATCH_END_DATE}`, data);
  },
}



