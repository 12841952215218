import {
  SAVE_BILLING_TRANS_ITEM_BY_CLAIMID,
  SAVE_CLAIM_SERVICE_LINE,
  SAVE_EDI_BY_BATCH_ID,
  SAVE_BILLING_STATUSES,
  SAVE_BILLING_STATUS_ID,
  SAVE_CMS_1500_BY_CLAIM_ID,
  SAVE_CMS_1500_FOR_BATCH,
  SAVE_837_CLAIM_BY_ID,
  REFRESH_BILLING_CLAIM,
  SAVE_FULL_REMITTANCE_BY_ID,
  SAVE_BILLING_SUB_REPORT_BY_SITE,
  SAVE_BILLING_SUB_REPORT_BY_PAYER,
  GET_BILLING_SUB_REPORT_FILTER,
  SAVE_BILLING_SUB_REPORT_LOADER_ACTION,
  SAVE_REMITTANCE_REPORT_BY_SITE,
  SAVE_REMITTANCE_REPORT_BY_PAYER,
  SAVE_BILLING_REMITTANCE_REPORT_ACTION
} from "../actions/types";
import { BillingSubReportBySiteDataModal, BillingSubReportByPayerDataModal, BillingSubReportRequestModal, BillingRemittanceBySiteResponseDataModel, BillingRemittanceByPayerResponseDataModel } from 'src/dataModels/billingResponses';

const initialState = {
  serviceLineList: null,
  billingStatusResp: [],
  billingStatusSelectedId: {},
  cms1500Resp: {},
  cms1500BatchResp: {},
  claim837Resp: [],
  refreshBillingClaimState: false,
  fullRemittanceByIdResp: [],
  billingSubReportBySiteResp: [] as BillingSubReportBySiteDataModal[],
  billingSubReportByPayerResp: [] as BillingSubReportByPayerDataModal[],
  billingSubReportFilter: {} as BillingSubReportRequestModal,
  billingSubReportLoaderAction: false as boolean,

  billingRemittanceReportBySiteResponse: [] as BillingRemittanceBySiteResponseDataModel[],
  billingRemittanceReportByPayerResponse: [] as BillingSubReportByPayerDataModal[],
  billingRemittanceReportLoaderAction: false as boolean,

};

export const BillingReducer = (state = initialState, action) => {
  const oldState = { ...state };
  const { type, payload } = action;
  switch (type) {
    case SAVE_CLAIM_SERVICE_LINE:
      return {
        ...oldState,
        serviceLineList: payload,
      };
    case SAVE_BILLING_TRANS_ITEM_BY_CLAIMID:
      return {
        ...oldState,
        billingTransClaim: payload,
      };
    case SAVE_EDI_BY_BATCH_ID:
      return {
        ...oldState,
        EDIResult: payload,
      };
    case SAVE_BILLING_STATUSES:
      return {
        ...oldState,
        billingStatusResp: payload,
      };
    case SAVE_BILLING_STATUS_ID:
      return {
        ...oldState,
        billingStatusSelectedId: payload,
      };
    case SAVE_CMS_1500_BY_CLAIM_ID:
      return {
        ...oldState,
        cms1500Resp: payload,
      };
    case SAVE_CMS_1500_FOR_BATCH:
      return {
        ...oldState,
        cms1500BatchResp: payload,
      };
    case SAVE_837_CLAIM_BY_ID:
      return {
        ...oldState,
        claim837Resp: payload,
      };
    case REFRESH_BILLING_CLAIM:
      return {
        ...oldState,
        refreshBillingClaimState: payload
      };
    case SAVE_FULL_REMITTANCE_BY_ID:
      return {
        ...oldState,
        fullRemittanceByIdResp: payload
      };
    case SAVE_BILLING_SUB_REPORT_BY_SITE:
      return {
        ...oldState,
        billingSubReportBySiteResp: payload
      };
    case SAVE_BILLING_SUB_REPORT_BY_PAYER:
      return {
        ...oldState,
        billingSubReportByPayerResp: payload
      };
    case GET_BILLING_SUB_REPORT_FILTER:
      return {
        ...oldState,
        billingSubReportFilter: payload
      };
    case SAVE_BILLING_SUB_REPORT_LOADER_ACTION:
      return {
        ...oldState,
        billingSubReportLoaderAction: payload
      };


    case SAVE_REMITTANCE_REPORT_BY_SITE:
      return {
        ...oldState,
        billingRemittanceReportBySiteResponse: payload
      };
    case SAVE_REMITTANCE_REPORT_BY_PAYER:
      return {
        ...oldState,
        billingRemittanceReportByPayerResponse: payload
      };
    case SAVE_BILLING_REMITTANCE_REPORT_ACTION:
      return {
        ...oldState,
        billingRemittanceReportLoaderAction: payload
      };

  }
  return oldState;
};
