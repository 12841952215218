import React, { useEffect, useState } from 'react';
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import GridLoader from "src/control-components/loader/loader";
import { ClientService } from "../../services/clientService";
import DropDownKendoRct from "../../control-components/drop-down/drop-down";
import { renderErrors } from "src/helper/error-message-helper";
import apiHelper from "src/helper/api-helper";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { DropDownObjectWithString } from 'src/dataModels/docListModel';
import { filterBy } from '@progress/kendo-data-query';
import { DropDownObjectWithNumber } from 'src/dataModels/docListModel';
import { API_ENDPOINTS } from 'src/services/api-endpoints';
import { customAxios } from 'src/services/useExPressApi';

const AssignSupervisor = ({ selectedDocumentId, selectedSupervisorId, handleAssignSupervisorClose, fetchByDocumentId, title }) => {
    const [staffDataList, setStaffDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [defaultSelectedvalue, setDefaultSelectedvalue] = useState<DropDownObjectWithNumber>();
    const [filter, setFilter] = useState<any>();

    useEffect(() => {
        getStaffList();

    }, []);

    const getStaffList = async () => {
        setLoading(true);
        await ClientService.getStaffDDLByClinicId().then((result: any) => {
            let list: any = result.resultData;
            setStaffDataList(list);
            setLoading(false);
            var selectedStaff: any = result?.resultData?.filter((item: any) => item.id == selectedSupervisorId);
            const defaultItem: DropDownObjectWithNumber = {
                name: selectedStaff[0]?.name,
                id: selectedSupervisorId
            };
            setDefaultSelectedvalue(defaultItem);
        }).catch((error) => {
            setLoading(false);
            renderErrors(error);
        });
    };
    const handleSubmit = () => {
        setLoading(true);
        var postData = {
            "supervisorStaffId": defaultSelectedvalue?.id,
            "documentIds": [selectedDocumentId]
        };
        customAxios.post(API_ENDPOINTS.ASSIGN_SIPERVISOR, postData).then((result) => {
            setLoading(false);
            NotificationManager.success("Supervisor assigned successfully");
            handleAssignSupervisorClose();
            fetchByDocumentId(selectedDocumentId);
        }).catch((err) => {
            renderErrors(err);
        }).finally(() => {
            setLoading(false);
        });

    }
    const onStaffDDLChange = (e) => {
        setDefaultSelectedvalue(e.target?.value);
    }

    return (
        <Dialog
            onClose={handleAssignSupervisorClose}
            title={title}
            className='label-dialog assignSupervisordialog blue_theme'
            width={"40%"}
            height={"40%"}
        >
            {loading && <GridLoader />}
            <div>

                <DropDownKendoRct
                    label="Staff:"
                    data={filterBy(staffDataList, (filter && filter.staffDataList) ? filter.staffDataLis : [])}
                    textField="name"
                    dataItemKey="id"
                    onChange={onStaffDDLChange}
                    suggest={true}
                    name="defaultSelectedvalue"
                    placeholder={"Staff"}
                    value={defaultSelectedvalue}
                />
            </div>   
            <DialogActionsBar >
           
                <div className='d-flex gapVertical text-end justify-content-end  py-3 buttonWidth mx-3'>
                <button className="submitButon" onClick={handleSubmit}>Submit</button>
                <button className="cancelBtn " onClick={handleAssignSupervisorClose}>Cancel</button>
                </div>
            </DialogActionsBar>
        </Dialog>
    )
};

export default AssignSupervisor

