import React, { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { pieChartModel } from "src/dataModels/docListModel";
import { ListView, ListViewHeader } from "@progress/kendo-react-listview";
import CustomSkeleton from "src/control-components/skeleton/skeleton";
import { userIsSuperAdmin } from "src/helper/permission-helper";
import CommonListFilterItem from "./common-list-filter-item";
import { TableRowsLoader } from "src/control-components/custom-skelton";

export const DocumentByStatus = () => {
  const state: ComponentState = useSelector((states) => {
    return states;
  });

  const [billingData, setBillingData] = useState<pieChartModel[]>([]);
  const staffLoginInfo = useSelector(
    (state: ComponentState) => state.getStaffReducer
  );
  const { documentBillingByStatus ,loadingObject} = state["DocumentReducer"];
  const userIsSA = userIsSuperAdmin(staffLoginInfo?.roleId);
  const isBillingManager = state.getRolePermission.isBillingManager;
  const showBillingAmounts = state.getRolePermission.showBillingAmounts;
  const [isShowItems, setShowItems] = useState(false);
  const canShowBillData = isBillingManager || userIsSA || showBillingAmounts;

  useEffect(() => {
    setBillingData([]);
    if (documentBillingByStatus && documentBillingByStatus?.resultData) {
      setShowItems(true);
      documentBillingByStatus?.resultData?.map((element, index) => {
        const totalBillValue = Number(
          element?.totalBilled ? element?.totalBilled : 0
        ).toFixed(2);
        const formattedTotalBill = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
        }).format(Number(totalBillValue));

        const label = `Docs:${element?.totDocs}`;
        var obj: pieChartModel = {
          id: element?.docStatusId,
          label: `${element?.documentStatus}`,
          value: element?.totDocs,
          // color: element?.color,
          info: label,
          totalBilled: canShowBillData ? formattedTotalBill : canShowBillData,
          count: element?.totDocs,
        };
        setBillingData((oldArray) => [...oldArray, obj]);
      });
    }
  }, [documentBillingByStatus]);

  const mostDocumentsBilling = billingData[0];
  const totalValue = billingData.reduce((sum, item) => sum + item.count, 0);

  const MyHeader = () => {
    return (
      <ListViewHeader
        style={{
          color: "#000000",
          fontSize: 30,
        }}
        className="px-3 py-2 "
      >
        <div className="row border-bottom align-middle">
          <div className="col-6">
            <h2 className="f-14">Status</h2>
          </div>
          <div className="col-6">
            <h2 className="f-14"># of Docs</h2>
          </div>
        </div>
      </ListViewHeader>
    );
  };
  const MyItemRender = (props) => {
    let item = props.dataItem;
    return (
      <div
        className="row py-2 border-bottom align-middle"
        style={{ margin: 0 }}
      >
        <div className="col-6">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <CommonListFilterItem item={item} type={"documentStatus"}/>
          )}
        </div>
        <div className="col-6">
          {!isShowItems ? (
            <CustomSkeleton shape="text" />
          ) : (
            <div >{item?.count}</div>
          )}
        </div>
      </div>
    );
  };

  return (

    <>{
      <div className="grid-table filter-grid dash-filter-grid my-3 caseload-custom gridheight casload boxHeight traningList topFixHeader">
       <h5> Document Status: ({totalValue})</h5>
        {(loadingObject?.includes(5) ) ? <TableRowsLoader rowsNum={12} /> :
          documentBillingByStatus?.resultData?.length != 0 ? (
            <ListView
            className="traningList"
            data={billingData}
            item={MyItemRender}
            style={{
              width: "100%",
              display: "block",
            }}
            header={MyHeader}
          />

          ) : (
            <p>No Record found</p>
          )
        }

      </div>
    }

    </>

   
  );
};

export default DocumentByStatus;
